<script>
    import { createEventDispatcher, onMount, tick } from 'svelte'
    import Button, { Icon, Label } from '@smui/button';

    import Grid from "./Grid.svelte";
    import pb from "../../pubsub";
    import Editable from './Editable.svelte';
    
    const pubsub = pb.setup('ScenarioBuilder')
    const dispatch = createEventDispatcher()

    /*
        Useful for building quick comparison scenarios
        - first column is topic
        - column 2 is basic scenario / control group
        - columns 3+ are extra scenarios
        - columns auto-format / change color
     */

    export let compact = false
    export let format = {
        'Row Name': {
            default: '20%',
            format: {
                unit: '%'
            }
        },
        'Hidden Row that Expands': {
            parent: 'Row Name',
            format: {
                unit: '$'
            }
        },
        'Auto-computing Row': {
            format: {
                unit: '$',
                fn: (val) => {}
            },
            formula: 'Row Name * 0.2'
        }
    }
    export let scenarios = [] // pre-loaded scenarios
    let rowIndex = {}
    let expandable = {}
    Object.keys(format).forEach((k, i) => {
        rowIndex[k] = i
        if (format[k].parent) {
            const parent = format[k].parent
            if (!expandable[parent]) {
                expandable[parent] = { expanded: false, children: [] }
            }
            expandable[parent].children.push(k)
        }
    })
    let columns = [{
        name: '',
        prop: 'name',
        size: 130,
        cellTemplate: (e, props) => {
            return e('div', {
                style: {
                    color: 'black'
                },
                class: {
                    'title': !format[props.model.name].parent,
                    'title-child': !!format[props.model.name].parent,
                    'expandable': !!expandable[props.model.name],
                    'expanded': expandable[props.model.name] && expandable[props.model.name].expanded
                },
                onmouseover: (e) => {
                    if (format[props.model.name].tooltip) {
                        pubsub.publish('tooltip:show', {
                            element: e.target, message: format[props.model.name].tooltip
                        })
                    }
                }
            }, props.model[props.prop])
        },
        basic: true // do not allow Grid to override this w/ custom cellTemplate
    }]

    function addCell(rowName, scenario, value) {
        let relative_to = null
        if (format[rowName].relative_to) {
            relative_to = (g) => {
                return g(rowIndex[format[rowName].relative_to], scenario)
            }
        }
        grid[rowIndex[rowName]][scenario] = {
            data: value || format[rowName].default || '',
            format: {
                color: format[rowName].color,
                parent: format[rowName].parent,
                format: format[rowName].format,
                relative_to,
                readonly: format[rowName].readonly,
                formula: format[rowName].formula ? (g) => {
                    // we need to pass a row object to scenario formula that wraps around grid object
                    const r = (field) => {
                        return g(rowIndex[field], scenario)
                    }
                    return format[rowName].formula(r, g)
                } : null
            }
        }
    }

    let grid = []
    Object.keys(format).forEach((rowName, i) => {
        // add row to grid
        grid.push({
            name: rowName,
            _hide: !!format[rowName].parent && !expandable[format[rowName].parent].expanded,
            _expandable: !!expandable[rowName],
            _index: i
        })
    })
    $: scenarioCount = 1
    // force re-render on format update (format update happens on address change)
    $: format && (grid = [...grid])

    let loadedScenarios = []
    $: if (scenarios !== loadedScenarios) {
        scenarioCount = 1
        if (scenarios.length) {
            console.log('PATH1', scenarios)
            // overwrite columns w/ loaded scenarios
            columns = [columns[0]]
            scenarios.forEach((s, i) => {
                loadScenario(s)
            })
            scenarioCount = scenarios.length
            console.log('loaded scenarios', scenarios)
        } else {
            console.log('PATH2')
            const name = 'Scenario 1'
            // delete all scenarios from grid
            const columnKeys = columns.slice(1).map(c => c.prop)
            grid.forEach(r => {
                columnKeys.forEach(k => {
                    delete r[k]
                })
            })
            columns = [columns[0]]
            columns.push({
                name,
                prop: 's1',
                cellProperties: ({prop, model, data, column}) => {
                    return {
                        property: { readonly: true }
                    }
                },
            })
            Object.keys(format).forEach((rowName, i) => {
                addCell(rowName, 's1')
            })
            console.log('GRID', grid, columns)
        }
        loadedScenarios = scenarios
    }

    function addScenario() {
        scenarioCount++
        const key = `s${scenarioCount}` // new scenario key/index
        const column = columns.find(c => c.prop === scenarioKey)
        const scenario = { ...column, prop: key, name: `Scenario ${scenarioCount}` }
        columns.push(scenario)

        Object.keys(format).forEach((rowName, i) => {
            grid[i][key] = { ...grid[i][scenarioKey] }
            if (grid[i][key].format) {
                grid[i][key].format = { ...grid[i][scenarioKey].format }

                if (grid[i][key].format.relative_to) {
                    grid[i][key].format.relative_to = (g) => {
                        return g(rowIndex[format[rowName].relative_to], key)
                    }
                }
                if (grid[i][key].format.formula) {
                    grid[i][key].format.formula = (g) => {
                        const r = (field) => {
                            return g(rowIndex[field], key)
                        }
                        return format[rowName].formula(r, g)
                    }
                }
            }
        })
        showScenarioMenu = false

        // convert scenario to object for dispatch event
        const newScenario = {}
        grid.forEach(r => {
            if (r[key].data !== "") {
                newScenario[r.name] = r[key].data
            }
        })
        updatesSinceSave.added.push(key)
        dispatch('add', {
            key,
            position: columns.length - 1,
            name: scenarioNames[key] || scenario.name,
            scenario: newScenario
        })
        columns = [...columns] // force re-render
        grid = [...grid] // force re-render
    }

    function loadScenario(data) {
        const key = `s${scenarioCount}`
        const scenario = { ...data.scenario, prop: key, name: data.name }
        columns.push(scenario)

        Object.keys(format).forEach((rowName, i) => {
            addCell(rowName, key, data.scenario[rowName])
        })
        scenarioCount++
    }

    function removeScenario() {
        const c = [...columns]
        console.log('REMOVE', scenarioKey, scenarioName, c)
        // remove scenario from columns
        columns = columns.filter(c => c.prop !== scenarioKey)
        // remove scenario from grid
        grid.forEach(r => {
            delete r[scenarioKey]
        })
        showScenarioMenu = false
        updatesSinceSave.removed.push(scenarioKey)
        dispatch('remove', {
            key: scenarioKey
        })
    }

    let scenarioName = null
    let scenarioKey = null
    let showScenarioMenu = false
    let scenarioMenuOffset = 0
    const scenarioNames = {}

    function onHeaderClick(e) {
        console.log(e.detail)
        scenarioName = e.detail.name
        scenarioKey = e.detail.prop

        // convert index to selector (e.g. s1 -> [data-rgcol="1"])
        const selector = `[data-rgcol="${columns.findIndex(c => c.prop === scenarioKey)}"]`
        const hCell = document.querySelector(selector)
        // pull offset from translateX
        scenarioMenuOffset = hCell.style.transform.match(/translateX\(([^)]+)\)/)[1]

        showScenarioMenu = true
    }
    function expand(e, a) {
        if (e.detail.prop === 'name') {
            ref.clearFocus() // don't select title cells
            if (expandable[e.detail.val]) {
                if (expandable[e.detail.val].expanded) {
                    // hide
                    expandable[e.detail.val].expanded = false
                    grid.forEach(r => {
                        if (expandable[e.detail.val].children.includes(r.name)) {
                            r._hide = true
                        }
                    })
                } else {
                    // expand
                    expandable[e.detail.val].expanded = true
                    grid.forEach(r => {
                        if (expandable[e.detail.val].children.includes(r.name)) {
                            r._hide = false
                        }
                    })
                }
                grid = [...grid] // force grid re-render
            }
        }

        const cellFormat = format[e.detail.model.name]
        if (cellFormat.readonly) {
            ref.clearFocus()
            const element = child.getCellElement(e.detail)

            // create a selector using data-rgcol=Object.keys(e.detail.model).findIndex(e.detail.prop) and data-rgrow=e.detail.rowIndex
            // const col = Object.keys(e.detail.model).findIndex((k) => k === e.detail.prop)
            // const row = e.detail.rowIndex
            // const element = document.querySelector(`div[data-rgcol="${col}"][data-rgrow="${row}"]`)

            pubsub.publish('tooltip:show', {
                element: element, message: "This cell is read-only."
            })
        }

        // react to expand/shrink controls
        // tick().then(() => {
        //     const revoGridContent = document.querySelector('.inner-content-table')
        //     gridHeight = revoGridContent.clientHeight
        // })
    }
    let ref;
    let child;

    // align revert/save buttons with grid
    let gridHeight = 0
    onMount(async () => {
        // delay loading tooltip logic until everything else has mounted
        await import('../../help')
    })

    function _onResize(revoGridContent) {
        // performs button alignment after content has been resized
        gridHeight = revoGridContent.clientHeight
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                gridHeight = entry.contentRect.height
            }
            observer.disconnect()
        })
        observer.observe(revoGridContent)
    }

    // moves buttons to align with grid
    async function onResize() {
        await tick()
        const revoGridContent = document.querySelector('.inner-content-table')
        if (revoGridContent) {
            _onResize(revoGridContent)
        } else {
            // wait until element is loaded
            document.addEventListener('DOMContentLoaded', () => {
                const revoGridContent = document.querySelector('.inner-content-table')
                if (revoGridContent) {
                    _onResize(revoGridContent)
                    document.removeEventListener('DOMContentLoaded', () => {})
                }
            })
        }
    }
    let menuRef
    const closeScenarioMenu = (e) => {
        if (!showScenarioMenu) return
        // const elementUnderMouse = document.elementFromPoint(e.clientX, e.clientY);
        // if (menuRef.contains(elementUnderMouse)) {
        //     // Mouse is still over the menu; do nothing
        //     return;
        // }

        scenarioNames[scenarioKey] = scenarioName
        const scenario = columns.find(c => c.prop === scenarioKey)
        scenario.name = scenarioName
        columns = [...columns] // force re-render
        showScenarioMenu = false

        // convert scenario to object for dispatch event
        const newScenario = {}
        grid.forEach(r => {
            if (r[scenarioKey].data !== "") {
                newScenario[r.name] = r[scenarioKey].data
            }
        })
        dispatch('update', {
            key: scenarioKey,
            position: columns.findIndex(c => c.prop === scenarioKey),
            name: scenarioName,
            scenario: newScenario
        })
    }
    
    // track changes (used by revert preview)
    const originalValues = {}
    function beforeEdit(e) {
        originalValues[`${e.detail.model.name}:${e.detail.prop}`] = e.detail.model[e.detail.prop]
    }
    const currentValues = {}
    function afterEdit(e) {
        // convert scenario to object for dispatch event
        const key = e.detail.prop // scenario/column id
        const rowName = e.detail.model.name // row name
        updatesSinceSave.updated.add(`${rowName}:${key}`)
        updatesSinceSave.updated = new Set([...updatesSinceSave.updated]) // force re-render
        window.uu = updatesSinceSave

        const newScenario = {}
        grid.forEach(r => {
            if (r[key].data !== "") {
                newScenario[r.name] = r[key].data
            }
        })
        dispatch('update', {
            key,
            position: columns.findIndex(c => c.prop === key),
            name: scenarioNames[key] || columns.find(c => c.prop === key).name,
            scenario: newScenario
        })
    }

    // save/revert buttons
    const updatesSinceSave = {
        added: [],
        updated: new Set(),
        removed: []
    }
    $: hasChanges = updatesSinceSave.added.length || updatesSinceSave.updated.size || updatesSinceSave.removed.length
    // show which cells have been updated
    let noColor = false // dim colors to make save hint more visible
    function showSaveHint(revert = false) {
        noColor = true
        const cls = revert ? 'to-revert' : 'to-save'
        const visibleRows = grid.filter(c => !c._hide)

        updatesSinceSave.updated.forEach((key) => {
            const [rowName, scenarioKey] = key.split(':')
            if (updatesSinceSave.removed.includes(scenarioKey)) return

            const visibleRowIndex = visibleRows.findIndex(r => r.name === rowName)
            const cell = child.getCellElementByRowCol(visibleRowIndex, columns.findIndex(c => c.prop === scenarioKey))
            if (revert) {
                currentValues[`${rowName}:${scenarioKey}`] = grid[rowIndex[rowName]][scenarioKey].data
                grid[rowIndex[rowName]][scenarioKey].data = originalValues[`${rowName}:${scenarioKey}`]
            }
            cell.classList.add(cls)
        })
        grid = [...grid] // force re-render
        updatesSinceSave.added.forEach((key) => {
            const colIndex = columns.findIndex(c => c.prop === key)
            const headerElement = document.querySelector(`.rgHeaderCell[data-rgcol="${colIndex}"] > .header-content`)
            headerElement.classList.add(cls)
            visibleRows.forEach((r, i) => {
                const cell = child.getCellElementByRowCol(i, columns.findIndex(c => c.prop === key))
                if (revert) {
                    currentValues[`${r.name}:${key}`] = grid[rowIndex[r.name]][key].data
                    grid[rowIndex[r.name]][key].data = originalValues[`${r.name}:${key}`] || ''
                }
                cell.classList.add(cls)
            })
        })

        // for removed scenarios, highlight the separator line
        updatesSinceSave.removed.forEach((key) => {
            const colIndex = parseInt(key.split('s')[1])
            visibleRows.forEach((r, i) => {
                const cell = child.getCellElementByRowCol(i, colIndex)
                cell.classList.add(`${cls}-removed`)
            })
        })
    }
    function hideSaveHint() {
        noColor = false
        const visibleRows = grid.filter(c => !c._hide)
        Object.keys(currentValues).forEach(key => {
            const [rowName, scenarioKey] = key.split(':')
            if (updatesSinceSave.removed.includes(scenarioKey)) return
            grid[rowIndex[rowName]][scenarioKey].data = currentValues[key]
        })

        document.querySelectorAll('.to-save').forEach((cell) => {
            cell.classList.remove('to-save')
        })
        document.querySelectorAll('.to-revert').forEach((cell) => {
            cell.classList.remove('to-revert')
        })
        document.querySelectorAll('.to-save-removed').forEach((cell) => {
            cell.classList.remove('to-save-removed')
        })
        document.querySelectorAll('.to-revert-removed').forEach((cell) => {
            cell.classList.remove('to-revert-removed')
        })
    }
    function onSave() {
        dispatch('save', {
            scenarios: columns.slice(1).map(c => {
                const scenario = {}
                grid.forEach(r => {
                    if (r[c.prop].data !== "") {
                        scenario[r.name] = r[c.prop].data
                    }
                })
                return {
                    name: c.name,
                    scenario
                }
            })
        })
    }
    function onRevert() {
        scenarios = [] // will be repopulated by parent
        updatesSinceSave.added = []
        updatesSinceSave.updated = new Set()
        updatesSinceSave.removed = []
        dispatch('cancel')
    }
</script>

<div id="main">
    <Grid
        {columns}
        {grid}
        {compact}
        {noColor}
        on:aftersourceset={onResize}
        on:headerclick={onHeaderClick}
        on:beforecellfocus={expand}
        on:beforeeditstart={beforeEdit}
        on:afteredit={afterEdit}
        bind:this={child}
        bind:ref={ref}
    />
    {#key gridHeight}
        <div class="buttons" style="top: {gridHeight + 2}px">
            <Button
                class="danger"
                on:mouseover={() => showSaveHint(true)}
                on:mouseout={hideSaveHint}
                on:click={onRevert} disabled={!hasChanges}
            >
                Revert
            </Button>
            <Button
                on:mouseover={() => showSaveHint(false)}
                on:mouseout={hideSaveHint}
                on:click={onSave} disabled={!hasChanges}
            >
                Save
            </Button>
        </div>
    {/key}
    <div bind:this={menuRef} id="scenario-menu" style="display: {showScenarioMenu ? 'block' : 'none'}; left: {scenarioMenuOffset}" on:mouseleave={closeScenarioMenu}>
        <Editable bind:value={scenarioName} />
        <div>
            <Button on:click={addScenario}>
                <Icon class="material-icons">content_copy</Icon>
                <Label>Copy</Label>
            </Button>
        </div>
        <div>
            <Button on:click={addScenario}>
                <Icon class="material-icons">access_time</Icon>
                <Label>Extrapolate</Label>
            </Button>
        </div>
        <div>
            <Button on:click={addScenario}>
                <Icon class="material-icons">compare_arrows</Icon>
                <Label>Compare</Label>
            </Button>
        </div>
        <div>
            <Button class="danger" on:click={removeScenario}>
                <Icon class="material-icons">delete</Icon>
                <Label>Delete</Label>
            </Button>
        </div>
    </div>
</div>

<style type="text/scss">
    #scenario-menu {
        display: none;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding: 12px 15px;
        font-weight: 600;
        font-size: 14px;
        font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        background: white;
        border: 1px solid #ccc;
    }

    #main {
        position: relative;
        height: 100%;

        :global(.title) {
            font-weight: 700;
            font-family: 'Lato', sans-serif;
            cursor: default;
        }

        :global(.title-child) {
            font-weight: 400;
            font-family: 'Lato', sans-serif;
        }

        :global(.title.expandable) {
            cursor: pointer;
        }

        :global(.title.expandable::after) {
            content: '▼';
            font-size: 9px;
        }

        :global(.title.expandable.expanded::after) {
            content: '▲';
            font-size: 9px;
        }

        .buttons {
            position: absolute;
            z-index: 1;
            width: 100%;
            right: 0;
            background: white;
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            :global(.mdc-button) {
                font-size: 14px;
                font-weight: 600;
            }

            :global(.danger:not(:disabled)) {
                color: #f44336;
            }
        }
    }
    :global(revogr-viewport-scroll .inner-content-table) {
        min-width: unset;
        background: white;
    }
    :global(.color revogr-header .rgHeaderCell .header-content) {
        color: var(--main-color);
    }
    :global(revogr-header .rgHeaderCell .header-content) {
        flex-grow: unset !important;
        cursor: pointer;

        &:hover {
            color: var(--secondary-color);
        }
    }
    :global(.rgHeaderCell) {
        width: unset !important;
    }
    :global(.header-content.to-save, .rgCell.to-save) {
        color: #4caf50 !important;
        text-shadow: 0 0 2px #4caf50;
        font-weight: 600;
    }
    :global(.header-content.to-revert, .rgCell.to-revert) {
        color: #f44336 !important;
        text-shadow: 0 0 2px #f44336;
        font-weight: 600;
    }
    :global(.to-save-removed) {
        border-left: 4px solid #f44336;
    }
    :global(.to-revert-removed) {
        border-left: 4px solid #4caf50;
    }
    :global(.rgCell:last) {
        width: unset !important;
    }
    :global(.bubble.button) {
        cursor: pointer;
        background-color: #4caf50;
        vertical-align: middle;
        justify-content: center;
        margin: 0;
        width: 80px !important;
    }
</style>