<script>
    import { TextField, Button, Snackbar } from 'svelte-materialify/src'
    import { navigate } from 'svelte-routing'

    let email;
    let error = false;
    let snackbarMessage = null;
    function requestEmail() {
        fetch(`/account/password/reset/${email.toLowerCase()}`, {
            method: 'POST'
        }).then(response => {
            if (response.ok) {
                snackbarMessage = 'Email sent'
                error = false
                navigate('/')
            } else if (response.status === 404) {
                snackbarMessage = 'This email is not in our database'
                email = ''
                error = true
            } else {
                response.json().then(msg => {
                    snackbarMessage = msg.error
                    error = true
                })
            }
        })
    }

    const emailRules = [
        (v) => !!v || 'Required',
        (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || 'Invalid e-mail.';
        },
    ]
    function isEmail(email) {
        for (let rule of emailRules) {
            if (rule(email) !== true) {
                return false
            }
        }
        return true
    }
    let badEmail
    $: badEmail = !isEmail(email)
</script>

<Snackbar class="flex-column {error ? 'snackbar-error' : ''}" bind:active={snackbarMessage} bottom center timeout={3000}>
    {snackbarMessage}
</Snackbar>

<div class="container">
    Enter the email you signed up with, a reset link will be mailed to you.
    <TextField rules={emailRules} bind:value={email}>Email</TextField>
    <Button bind:disabled={badEmail} class="primary-color" on:click={requestEmail}>Send Reset Link</Button>
</div>

<style type="text/scss">
    @import 'material-theme';

    .container {
        max-width: 600px;
        margin: auto;
        padding: 100px 0;

        :global(.s-text-field) {
            padding: 10px 0;
        }
    }
</style>