/* @flow */
const pubSub = require('./pubsub');
import { capitalize } from '../common/utils'

// generates a unique ID
export function newId() {
    return Math.random().toString(36).substr(2, 9);
}

// finds a readable foreground color for a given background color
export const contrastColor = (color) => {
    //if only first half of color is defined, repeat it
    if(color.length < 5) {
        color += color.slice(1);
    }
    
    // Convert hex color to RGB components
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Calculate luminance
    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    // Return a high-contrast color based on luminance threshold
    return luminance > 0.5 ? '#333' : '#fff';
}

const alias = {
    income: 'Income & Jobs',
    business: 'Business',
    crime: 'Police & Crime',
    environment: 'Environment',
    taxes: 'Tax Data',
    flood: 'Flood Risk',
    fire: 'Wildfire Risk',
    heat: 'Severe Heat',
    wind: 'Wind Risk'
}

const acronymList = [
    /*
        match: substring that should be contained in string to trigger replacement
        regex: what actually gets replaced
        final: final text to replace the regex with
        short: short version of final text to replace with for very long strings
     */
    { match: 'ppsf', regex: 'ppsf', final: 'Price / Sq.Ft.', short: 'PPSF'},
    { match: '_sfr', regex: '_sfr$', final: ' (Single-Family)', short: ' (SFR)' },
    { match: 'single_family', regex: '^single_family', final: ' Single-Family', short: 'SFR' },
    { match: '_mfr', regex: '_mfr$', final: ' (Multi-Family)', short: ' (MFR)' },
    { match: 'multi_family', regex: 'multi_family', final: ' Multi-Family', short: 'MFR' },
    { match: '_th', regex: '_th$', final: ' (Townhome)', short: ' (TH)' },
    { match: '_condo', regex: '_condo$', final: ' (Condo)', short: ' (CND)' },
    { match: '_building', regex: '_building$', final: ' Building', short: ' Bldg' },
    { match: 'household_', regex: '^household_', final: 'Household ', short: 'HH ' },
    { match: 'qualified_', regex: 'qualified_', final: 'Qualified ', short: 'Qfd. ' },
    { match: '_to_', regex: '_to_', final: ' to ', short: ' / ' },
    { match: '_count', regex: '_count$', final: '', short: '' },
    { match: '_rv', regex: '_rv$', final: ' RV', short: ' RV' },
    { match: '_by_type', regex: '_by_type$', final: ' By Type', short: '' }, // makes business categories shorter
]

// last-minute tweaks if the naming feels "funny", by this point the name is already capitalized
// and mostly in format the user would see it in
function fixNaming(field: string): string {
    // if name feels like it needs a dash, add it
    // if (field.match(/\d Bedroom\b/)) {
    //     field = field.replace(' Bedroom', '-Bedroom')
    // }

    // regex-test based on pattern
    if (field.length > 14 && field.match(/^(Median|Mean|Average|Total) [A-Z][a-z]+$/)) {
        // drop prefix when overly verbose
        field = field.replace('Median ', '')
    }

    return field
}

// change field name to human-friendly format
export const prettyName  = (field: string, charLimit: number = 14): string => {
    /*if (['income', 'rent', 'home_value', 'age'].indexOf(field) !== -1) {
        field = 'Median_' + field;
    } else if (field === 'house_age') {
        field = 'Avg_house_year';
    } else if (field === 'commute_time') {
        field = `Avg_${field}`
    }*/
    if (alias[field]) {
        field = alias[field]
    } else {
        for (let acronym of acronymList) {
            if (field.includes(acronym.match)) {
                const final = field.length - acronym.match.length < charLimit ? acronym.final : acronym.short
                field = field.replace(new RegExp(acronym.regex), final)
            }
        }
    }
    field = field.replace(/:/g, ' - ')
    // if name ends up wrapped in parentheses, remove them
    field = field.trim()
    if (field[0] === '(' && field[field.length - 1] === ')') {
        field = field.slice(1, -1)
    }
    return fixNaming(capitalize(field));
}

// takes original categories in slug format and generates shorter/prettier names
export const prettyCategories = (categories: Array<string>): Array<string> => {
    // strip prefix/suffix if it appears in every category (ignore no_data category at index 0)
    let commonPrefix = categories[0] // start with first category, and shrink prefix
    let commonSuffix = categories[0] // same for suffix
    let lastPrefixUnderscore = -1
    let lastSuffixUnderscore = -1
    // find longest common prefix and suffix (only break on _ character)
    for (let i = 1; i < categories.length; i++) {
        const category = categories[i]
        let j = 0
        let lastUnderscore = -1
        while (j < commonPrefix.length && j < category.length && commonPrefix[j] === category[j]) {
            if (commonPrefix[j] === '_') {
                lastUnderscore = j
            }
            j++
        }
        commonPrefix = commonPrefix.slice(0, j)
        lastPrefixUnderscore = lastUnderscore // recompute underscore as prefix shrinks
        // j = 0
        // lastUnderscore = -1
        // while (j < commonSuffix.length && j < category.length && commonSuffix[commonSuffix.length - j - 1] === category[category.length - j - 1]) {
        //     if (commonSuffix[commonSuffix.length - j - 1] === '_') {
        //         lastUnderscore = j
        //     }
        //     j++
        // }
        // commonSuffix = commonSuffix.slice(0, j)
        // lastSuffixUnderscore = lastUnderscore // recompute underscore as suffix shrinks
    }
    // only break on _ character
    if (commonPrefix && lastPrefixUnderscore !== -1) {
        commonPrefix = commonPrefix.slice(0, lastPrefixUnderscore)
    } else {
        commonPrefix = '' // reset to empty string
    }
    // if (commonSuffix && lastSuffixUnderscore !== -1) {
    //     commonSuffix = commonSuffix.slice(-lastSuffixUnderscore)
    // } else {
    //     commonSuffix = '' // reset to empty string
    // }

    // remove common prefix/suffix
    const newCategories = []
    for (let category of categories) {
        category = category.slice(commonPrefix.length)
        // category = category.slice(-commonSuffix.length)
        newCategories.push(prettyName(category))
    }
    // console.log('newCategories', commonPrefix, '|', commonSuffix, categories, newCategories)
    return newCategories
}

export function fetchAvatar(avatar, size) {
    return `https://www.gravatar.com/avatar/${avatar}?d=identicon&s=${size}`
}

// TODO: remove this crap, and convert all old dialog boxes to use svelte
// correctly sets value in material design lite field
export const setMdlVal = (field: HTMLInputElement, value: string) => {
    // hidden field only (MDL dropdown)
    if (field.type === "hidden") {
        // dropdown, generate fake click event
        ((document.querySelector(`li[data-val="${value}"]`) : any): HTMLElement).click();
    } else {
        // regular text field
        field.value = value;
        ((field.parentElement: any): HTMLElement).classList.add('is-dirty');
    }
}

// deep equality
export function deepEqual(obj1: Object, obj2: Object): boolean {
    if (obj1 == obj2) { // technically the wrong comparison, but we often compare string and number coords
        return true;
    } else if (isObject(obj1) && isObject(obj2)) {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) { return false; }
        for (var prop in obj1) {
            if (!deepEqual(obj1[prop], obj2[prop])) {
                return false;
            }
        }
        return true;
    }
    return false;
}
function isObject(obj) {
    if (typeof obj === "object" && obj != null) {
        return true;
    } else {
        return false;
    }
}

// executes function after the delay has been elapsed, repeated calls prior to delay reset the delay, if "immediate" flag is passed, execute at the leading edge
export function debounce(func:Function, wait:number = 100): Function {
    let timeout;
  
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
    
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

// promise that can be resolved from outside by calling resolve on the object
export function externalPromise() {
	var res, rej;

	var promise = new Promise((resolve, reject) => {
		res = resolve;
		rej = reject;
	});

	promise.resolve = res;
	promise.reject = rej;

	return promise;
}

export const memoize = (callback) => {
    let memo = {}
    console.log('MEMOIZED')
    return (...args) => {
        console.log('ARGS' , args)
        if (memo[args]) {
            return memo[args]
        } else { 
            memo[args] = callback(...args) 
            return memo[args] 
        } 
    }
}

// granule ID mapping logic used by our maps
export const granularity = {
    county: {
        plural: 'counties',
        usId: (d) => parseInt(d.properties.GEOID),
        stateId: (d) => parseInt(d.properties.GEOID),
        // normalizeId: (n) => parseInt(n),
        name: (d) => `${d.properties.NAME} County (${parseInt(d.properties.GEOID)})`,
        usMapPaths: {}
    },
    zip: {
        plural: 'zips',
        usId: (d) => d.properties.z,
        stateId: (d) => d.properties.ZCTA5CE10,
        normalizeId: (n) => `${n}`.padStart(5, "0"),
        name: (d, name) => `${name || '???'} (${d.properties.ZCTA5CE10.padStart(5, "0")})`,
        usMapPaths: {}
    },
    tract: {
        plural: 'tracts',
        stateId: (d) => d.properties.GEOID,
        // normalizeId: (n) => parseInt(String(n).slice(0, -2)),
        stateFilter: (d) => d.properties.ALAND !== 0,
        name: (d, name) => {
            const idStr = d.properties.GEOID.slice(5, -2) + '.' + d.properties.GEOID.slice(-2)
            const pad = d.properties.NAME.indexOf('.') === -1 ? 4 : 7
            return `${name || '???'} (${d.properties.STATEFP}-${d.properties.COUNTYFP}-${d.properties.NAME.padStart(pad, '0')})`
        }
    }
}

// this is useful to differentiate SSR from client rendering
export function isBrowser() {
    return (typeof window !== 'undefined' && window.document);
}

// a fetch alternative for our API that works server-side (without needing to make an extra request) and client-side
export function apiFetch(url, options) {
    if (isBrowser()) {
        return fetch(url, options)
    } else {
        // on the server-side, we don't support relative links (see https://github.com/vercel/next.js/issues/48344)
        const fullUrl = process.env.NODE_ENV === 'production' ?
            `https://localhost:${process.env.PORT}` : `http://localhost:${process.env.PORT}`
        return fetch(`${fullUrl}${url}`, options)
    }
}