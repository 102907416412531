<!-- <svelte:head>
    {#await itemInfo}
        <title>{selectedItem ? selectedItem : name} - Investomation</title>
    {:then info}
        {#if info}
            <title>{meta ? meta(info).title : '?'} - Investomation</title>
            <meta name="description" content="{meta(info).description}" />
            <meta name="keywords" content="blog, real estate, real estate research, property management, real estate analytics, investing, finance, wealth" />
            <meta property="og:title" content="{meta(info).title}" />
            <meta property="og:description" content="{meta(info).description}" />
            <meta property="og:type" content="{meta(info).type}" />
            <meta property="og:url" content="{meta(info).url}" />
            <meta property="og:image" content="{meta(info).image}" />
        {/if}
    {/await}
</svelte:head> -->

<script>
    /*
    Aggregator is used to process lists of data following the same format, automatically
    generating list views, individual views, and edit views based on parameters passed.
    Common use cases:
    - blog posts
    - markers
    - user profiles
    - events
    - zipcodes / areas / businesses
    */

    import { ProgressCircular } from 'svelte-materialify/src';

    export let name;
    export let relatedName;
    export let endpoint;
    export let singleEndpoint;
    export let arrayName = 'items';
    export let selectedItem;
    export let meta;
    export let sortFn;
    // export let singleItemSize = 2;

    // this retardation only exists here because server-side version of fetch() is too dumb to use root-relative URLs
    function getBaseUrl() {
        if (typeof window === 'undefined') {
            // Node.js environment (server-side), use an environment variable or default
            return process.env.BASE_URL || 'http://localhost:3002'; // Adjust accordingly
        } else {
            // Browser environment (client-side), use relative URL
            return '';
        }
    }
    const baseUrl = getBaseUrl()

    // TODO: consider adding cache
    async function getList(e) {
        let list = await fetch(`${baseUrl}${e}`).then(r => r.json()).then(list => list[arrayName])
        if (sortFn) {
            list = list.sort(sortFn)
        }
        return list
    }
    $: items = getList(endpoint)

    async function getSingleItem(id) {
        if (!id) return;

        return fetch(`${baseUrl}${singleEndpoint.replace(':id', id)}`).then(r => r.json())
    }
    let itemInfo
    $: itemInfo = getSingleItem(selectedItem)
</script>
<div class="container">
    {#if selectedItem}
        <!-- render individual item -->
        {#await itemInfo}
            <div class="center">
                <ProgressCircular  size={70} width={7} indeterminate color="primary" />
            </div>
        {:then info}
            <slot name="opened-item" class="opened-item" prop={info}></slot>
            <div class="related-items">
                <h3>{relatedName}</h3>
                {#await items}
                    Loading...
                {:then list}
                    <!-- related content -->
                    {#if Array.isArray(list)}
                        {#each [...list].filter((i) => i.id !== selectedItem).reverse() as item}
                            <slot name="single-item" prop={item}></slot>
                        {/each}
                    {:else}
                        <!-- Technically we shouldn't jump to this conclusion yet, but this currently only occurs for users -->
                        Login to see
                    {/if}
                {/await}
            </div>
        {/await}
    {:else}
        <!-- render list -->
        <h1>{name}</h1>
        {#if $$slots.filter}
            <slot name="filter"></slot>
        {/if}
        {#await items}
            <div class="center">
                <ProgressCircular  size={70} width={7} indeterminate color="primary" />
            </div>
        {:then list}
            <!-- items finished loading -->
            <div class="item-list">
                {#if list.length}
                    {#each [...list].reverse() as item}
                        <slot name="single-item" prop={item}></slot>
                    {/each}
                {:else}
                    <!-- list is empty -->
                    <div class="empty">Nothing here yet.</div>
                {/if}
            </div>
        {/await}
    {/if}
</div>

<style type="text/scss">
    @import 'material-theme';

    @media only screen and (max-width: 600px) {
        :global(.opened-item) {
            width: 100% !important;
            margin: 0px !important;
            min-width: none;
        }
        h1 {
            font-size: 1.5rem !important;
            line-height: 1.5rem !important;
        }
        :global(.filter) {
            display: none;
        }
    }

    h1 {
        text-transform: uppercase;
        padding-top: 10px;
        padding-left: 16px;
    }

    :global([slot="single-item"]) {
        :global(a) {
            text-decoration: none;
            color: black;
        }

        &:hover {
            background-color: $secondary-bg-color;

            :global(.s-card) {
                background-color: $secondary-bg-color;
            }
        }
    }

    :global(body) {
        overflow: auto;
    }

    :global(.opened-item) {
        :global(img) {
            max-width: 100%;
        }
    }

    .container {
        margin: 10px;
        clear: both;
    }

    .empty {
        margin: 16px;
    }

    .center {
        transform: translate(50%, 50%);
        height: 300px;
    }

    .related-items {
        float: right;
        width: 320px;

        h3 {
            padding: 16px;
            padding-left: 3px;
        }
    }
</style>