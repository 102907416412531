export const isWhole = (unit: string): bool => {
    const wholeUnits = [
        '$', // might as well round these, we don't care about cents
        'years',
        'index',
        'people',
        'employees',
        'businesses',
        'households',
        'companies',
        'units',
        'rooms',
        'returns',
        'score',
        'people/mi\u00b2',
    ]
    if (wholeUnits.includes(unit)) {
        return true;
    }
    return false
}

// capitalize/prettify string
// export const capitalize = (s: string) => {
//     if (typeof s !== 'string') return '';
//     let output = s.replace(/([_ ]\w)/g, m => ' ' + m[1].toUpperCase());
//     // output = output.replace(/\b\w/g, m => m[1].toUpperCase())
//     return output.charAt(0).toUpperCase() + output.slice(1);
// }

export function capitalize(input: string): string {
    return input
      .replace(/_/g, ' ')
      .replace(/([^\w\s]|_)+/g, match => match.charAt(0).toUpperCase() + match.slice(1))
      .replace(/\b\w/g, match => match.toUpperCase());
}