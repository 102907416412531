<script>
    import { tick } from 'svelte';
    import { createEventDispatcher } from 'svelte';
    import { TextField, Button } from 'svelte-materialify/src';
    
    const dispatch = createEventDispatcher();
    let name = globalThis.user ? (globalThis.user.nickname || globalThis.user.username) : '';
    let email = globalThis.user ? globalThis.user.email : '';
    let subscribed = false;
    export let surveyName;
    export let buttonName = 'Subscribe';
    export let omitName = false;

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
    let error = ''
    
    const subscribe = async () => {
        if (!isValidEmail(email)) {
            if (error) {
                error = '';
                await tick() // force re-animation of error message
            }
            error = 'Please enter a valid email address.';
            return;
        }
        await fetch('/api/mailing_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, email, survey: surveyName })
        })
        subscribed = true;
        dispatch('subscribe', { email });
        // console.log(`Subscribed with email: ${email}`);
    };
</script>

<main>
    {#if subscribed}
        <slot name="thankyou">
            <h1>Thank you for subscribing!</h1>
        </slot>
    {:else}
        <slot name="description"></slot>
        <form on:submit|preventDefault={subscribe}>
            {#if !omitName}
                <TextField label="Name" bind:value={name} >Name</TextField>
                <TextField label="Email" type="email" bind:value={email} >E-Mail</TextField>
                <Button class="primary-color full-width" type="submit" raised>{buttonName}</Button>
            {:else}
                <div class="inline">
                    <TextField cla label="Email" type="email" bind:value={email} >E-Mail</TextField>
                    <Button class="primary-color button" type="submit" raised>{buttonName}</Button>
                </div>
            {/if}
            {#if error}
                <p class="error">{error}</p>
            {/if}
        </form>
    {/if}
</main>

<style type="text/scss">
    h1 {
        text-transform: uppercase;
    }
    
    main {
        width: 600px;
        max-width: 100%;
        margin: auto;
        clear: both;

        .inline {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            :global(.button) {
                margin-left: 10px;
            }
        }

        :global(.full-width) {
            width: 100%;
        }

        :global(.error) {
            color: red;
            animation: horizontal-shake 0.25s linear;
        }
    }

    @keyframes horizontal-shake {
        0% { transform: translateX(0) }
        25% { transform: translateX(5px) }
        50% { transform: translateX(-5px) }
        75% { transform: translateX(5px) }
        100% { transform: translateX(0) }
    }

    form {
        padding-top: 30px;
    }
</style>