<script>
    import {
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        CardActions,
        Button,
    } from 'svelte-materialify/src';
    import Modal from './Modal.svelte';
    import Subscribe from './Subscribe.svelte';

    export let name;
    export let image;
    let showToc = false;

    let showModal = false;
    function enrollDialog() {
        showModal = true;
    }
</script>

<Card class="course {image}">
    <CardTitle>
        {name}
    </CardTitle>
    <CardSubtitle class="course-description">
        <slot name="description"></slot>
    </CardSubtitle>
    {#if showToc}
        <CardText>
            <slot name="toc"></slot>
        </CardText>
    {/if}
    <CardActions class="course-actions">
        <Button on:click="{() => showToc = !showToc}">{showToc ? 'Hide' : 'See'} Course Content</Button>
        <!-- <Button>Individual Lectures</Button> -->
        <Button on:click={enrollDialog} class="primary-color">Enroll</Button>
    </CardActions>
</Card>

<Modal bind:showModal={showModal}>
    <Subscribe surveyName={name} omitName buttonName="Notify me">
        <div slot="thankyou">
            <h1>Thank you for subscribing!</h1>
            <p>We'll let you know when this course is ready. You can close this modal by clicking outside of it.</p>
        </div>
        <div slot="description">
            <h1>{name}</h1>
            We're currently working on this course. If you'd like to be notified when it's ready, please
            enter your email below.
        </div>
    </Subscribe>
</Modal>

<style type="text/scss">
    :global(.course) {
        z-index: 1;

        :global(.s-card-subtitle) {
            color: #333;
        }

        :global(.s-card-text) {
            color: #333;
        }

        :global(.course-actions) {
            justify-content: space-between;
        }
    }

    :global(.course::before) {
        // background-image: url(/static/{image}.jpg);
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
        content: "";
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.25;
        z-index: -1;
    }

    :global(.s-card.course > .s-card-subtitle.course-description) {
        margin-top: 0px;
    }

    :global(.course .course-description span) {
        font-size: 1rem;
    }
</style>