<script>
    import { Router, Link } from 'svelte-routing'

    export let to = '';
    let klass = ''
    export { klass as class }

    /*history.pushState = new Proxy(history.pushState, {
        apply (target, thisArg, argumentsList) {
            console.log('tag updated', to)
            gtag('config', 'G-K9SGV9RJF1', { page_path: to })

            Reflect.apply(target, thisArg, argumentsList)
        }
    })*/
    function update() {
        window.gtag('config', 'G-K9SGV9RJF1', { page_path: to })
    }
</script>

<Router>
    <Link class={klass} bind:to on:click={update} on:click >
        <slot />
    </Link>
</Router>