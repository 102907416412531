## Users
Users correspond to other investors using the platform. Use data typically contains a short bio, investor's
primary location of operation, their specialties/skills as well as other locations/markets they operate in.

These endpoints fetch data related to current user or other visible users.

`GET /api/user`  
Fetch list of all visible users.

`GET /api/user/:id`  
Fetch information for a specific user.

`PUT /api/user/:id`  
Update user information (your changes will be rejected unless you are logged in as the same user).

`GET /api/user/:id/comment`  
Fetch all blog post comments by this user.

`GET /api/user/:id/marker`  
Fetch all markers that belong to this user.

## Markers
Markers can be thought of as personal vaults of data tied to a location. Each marker is owned by a user
and by default not visible to other users (this setting can be changed by the owner). Markers are typically
used to group a collection of research data related to a property under one umbrella, including research data,
important events (such as bill due date or an open-house showing), documents, notes, credit card data, and even
analytics gathered by our platform.

Marker API is used to interact with markers (tagged geolocations) owned by the user.
Markers can have descriptions, analysis, and events attached to them.

`GET /api/marker`  
Fetch list of all markers that belong to the logged-in user.

`GET /api/marker/:id`  
Fetch information for a specific marker.

`PUT /api/marker`  
Create a new marker that belongs to the logged-in user.

`PUT /api/marker/:id`  
Update marker information (your changes will be rejected unless you are logged in as the user who owns this marker).

`DELETE /api/marker/:id`  
Delete the marker (your request will be rejected unless you are logged in as the user who owns this marker).

`GET /api/marker/:id/event`  
Fetch all events that belong to a given marker.

## Events
Events are typically tied to a marker, and generate a text/email notification at user's request.

Events can be accessed independently of the markers they were attached to. This is useful
for accessing them via a calendar.

`GET /api/event`  
Fetch list of all events attached to markers that belong to the logged-in user.

`GET /api/event/:id`  
Fetch information for a specific event.

`PUT /api/event`  
Create a new event that belongs to the logged-in user (you will need to specify the marker to attach the event to).

`PUT /api/event/:id`  
Update event information (your changes will be rejected unless you are logged in as the user who owns this event).

`DELETE /api/event/:id`  
Delete the event (your request will be rejected unless you are logged in as the user who owns this event).
