import {
    mdiBank,
    mdiCurrencyUsd,
    mdiFactory,
    mdiFire,
    mdiHammerWrench,
    mdiHome,
    mdiHumanMaleFemaleChild,
    mdiImageFilterHdr,
    mdiPercentOutline,
    mdiPoliceBadge,
    mdiSchool,
    mdiTrainCar,
    mdiWalletTravel,
    mdiWater,
    mdiWeb,
    mdiWeatherWindy,
    mdiWhiteBalanceSunny
} from '@mdi/js'

// converts timestamp to human-readable date
export const toDate = (d) => new Date(d).toLocaleDateString(undefined, { timeZone: 'UTC' })

// returns correct icon to use based on requested metric
export const metricIcons = {
    income: mdiCurrencyUsd,
    population: mdiHumanMaleFemaleChild,
    migration: mdiWeb,
    employment: mdiWalletTravel,
    housing: mdiHome,
    construction: mdiHammerWrench,
    sales: mdiHome,
    business: mdiFactory,
    industries: mdiFactory,
    transportation: mdiTrainCar,
    education: mdiSchool,
    government: mdiBank,
    taxes: mdiPercentOutline,
    crime: mdiPoliceBadge,
    environment: mdiImageFilterHdr,
    flood: mdiWater,
    fire: mdiFire,
    heat: mdiWhiteBalanceSunny,
    wind: mdiWeatherWindy
}