/* @flow */

/*
    Format for all messages:
        sender:category:eventName

        sender: file or class name of the sending module (must run pubsub.setup() to register as sender)
            the customary solution I picked is upper-case for svelte classes, lower-case for old components
        category: category/group this message falls under
        eventName: unique (to this category) message name
    
    NOTE: same category:eventName can be sent by multiple senders, if you want to subscribe to all of them, use wildcard (*) for sender param

    Design guidelines when adding new events:
        - try not to subscribe to and publish same event from a single module, you'll probably cause a feedback loop
            - there is nothing in the logic banning this but this is akin to using event-based gotos
        
        

    Events currently used:
    - setup:*                   anything that's part of setup procedure
        - region                { state, city? } has been parsed from URL and region initialization finished
        - searchbox
    - dialog:*                  logic for manipulating new dialog system based on svelte
        - render                request a new dialog of given type ({ type, params })
        - hide                  hide current dialog
    - form:*                    a special version of dialog for quickly generating forms
        - submit                form has been submitted
        - cancel                form has been cancelled
    - load:*                    anything to do with loading data
        - cities                list of city + data has been loaded (should we move this to setup?)
        - markers               list of markers + data has been loaded
    - location:*                related to location on the map / dashboard
        - load                  location has been loaded (into the tooltip on old system, into dashboard on new) (valid location: state, city, zip, marker)
                                params: { type, query }
        - cancel                cancel location location selection (selectable locations (handled by citymap) are: zip/granule, marker)
    - marker:*                  related to markers on the map
        - mode                  change marker mode (move, marker, route)
        - preview               zoom to marker location, but don't select it, and remember the original location
        - open                  open marker info panel
        - update                change marker name, icon, color, etc.
    - metrics:*                 logic related to metrics selection
        - layout                set of metrics that exist within the API
        - selection             user has selected a new metric (did not yet compute ranks)
    - mainSearch:*              user-requested search events via mainSearch
        - geocode
        - marker
        - city
        - state
        - acceptInput           fires when user accepts a result suggestion
        - addCategory           add new category fo results to search field
    - layer:*                   show/hide a map layer
        - cities                show/hide cities layer
        - map                   change map layer
        - heatmap               change heatmap layer bounds
        - showHeatmap           show/hide heatmap layer
        - history               change map to a certain point in history (satellite imagery)
        - cityHighlight         highlight a city on the map
        - resetHighlight        reset city highlight
    - notify:*                  any messages to be rendered/hidden in notifcation panel
        - startLoad             show loading notification
        - stopLoad              hide loading notification
        - info                  style + show notification like an info panel
        - error                 style + show notification like an error panel
    - categoryFilter:*          communication related to category filter dialog
        - apply
    - measure:*                 events related to map/route measurements
        - start                 start measurement mode
        - startMarker           start measurement marker
        - radiusMetric          start proximity analysis mode
        - finish
        - analysis              trigger proximity analysis
    - metricFilter:*            communication related to metric filter dialog
        - apply
        - reset
    - ranks:*                   logic related to generating heatmap/ranks
        - delta                 activate/deactivate delta metrics
        - country               country ranks have been loaded from the backend
        - state                 state ranks have been loaded from the backend
        - appliedCountry        finished applying country-level ranks to svg
        - appliedRegion         finished applying region-level ranks to svg (region is technically applied through state)
    - tooltip:*                 control tooltip
        - hide
        - show                  show tooltip next to given element w/ message ({ element, message })
        - zip                   render tooltip for a given zipcode
    - store:*                   logic for handling updates for svelte stores
        - sync                  sync vanillaJs globals with svelte stores
        - addRegion             add a region to selected list of regions (will not insert duplicates)
    - zoom:*                    zoom-in/out logic
        - cleanup               clean-up logic related to changing zoom levels (does not remove citymap layer itself, use reset for that instead)
        - reset                 user requested to reset zoom to country/state level (mainly means cleanup of citymap layer)
        - state                 user requested zoom to state level
        - city                  user requested zoom to city level
        - enhance               enhanced version of state/city geoJson data is now available
        - map                   citymap zoom
*/

const isDev = process.env.NODE_ENV === "development"

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined'
let globalInstance = isBrowser ? window : global

// global event queue
globalInstance._pubsubId = globalInstance._pubsubId || 0
globalInstance._pubsub = globalInstance._pubsub || {}

// individual instance of pubsub config
class PubSub {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    _toComponents(event: string): [string, string, string] {
        let parts = event.split(':')
        if (parts.length === 2) {
            return [this.name, parts[0], parts[1]]
        }
        if (parts[0] !== this.name || this.name === 'default') {
            throw new Error("Can't send events on behalf of another module or without proper name initialization")
        }
        return [parts[0], parts[1], parts[2]]
    }

    // publish the event to all observers, makes sure to dedupe event so the same observer doesn't hear it multiple times
    publish(event: string, data: any) {
        globalInstance._pubsubId++
        const eventComponents = this._toComponents(event)
        const fullEvent = eventComponents.join(':')

        isDev && console.log(`emit ${fullEvent}`, data);

        let foundSubscriber = false
        let foundChannel = false
        const notified = [] // we'll use callback function ids as differentiators for deduping
        const notify = (subscriberCallback)=> {
            if (!notified.includes(subscriberCallback)) {
                notified.push(subscriberCallback)
                subscriberCallback(data, globalInstance._pubsubId, fullEvent)
                foundSubscriber = true
            }
        }

        // iterate through exact and wildcard subscribers to notify all
        [eventComponents[0], '*'].forEach(sender => {
            [eventComponents[1], '*'].forEach(category => {
                [eventComponents[2], '*'].forEach(eventName => {
                    const fullWildcardEvent = `${sender}:${category}:${eventName}`
                    if (globalInstance._pubsub[fullWildcardEvent]) { // someone subscribed to this topic or channel established
                        foundChannel = true
                        Object.keys(globalInstance._pubsub[fullWildcardEvent].subscribers).forEach(k => {
                            let subscriberCallback = globalInstance._pubsub[fullWildcardEvent].subscribers[k]
                            notify(subscriberCallback)  // deduping handled inside notify
                        });

                        // update last reported state for this topic, allow partial state updates
                        let last = globalInstance._pubsub[fullWildcardEvent].last
                        if (last) {
                            if (Array.isArray(last)) {
                                // for arrays, we want to overwrite the whole array
                                globalInstance._pubsub[fullWildcardEvent].last = data
                            } else if (typeof last === 'object') {
                                // for objects, we want to merge the new data into the old
                                globalInstance._pubsub[fullWildcardEvent].last = {
                                    ...last,
                                    ...data
                                }
                            } else {
                                // for primitives, we also overwrite the whole value
                                globalInstance._pubsub[fullWildcardEvent].last = data
                            }
                        } else {
                            // first write
                            globalInstance._pubsub[fullWildcardEvent].last = data
                        }
                        globalInstance._pubsub[fullWildcardEvent].lastId = globalInstance._pubsubId
                    }
                })
            })
        })
        if (isDev) {
            !foundChannel && console.error(`No subscribers for "${fullEvent}" event, and no channel established`)
            !foundSubscriber && console.warn(`No subscribers for "${fullEvent}" event, but channel established`)
        }
    }

    // subscribes to an event in sender:category:eventName format, either one can be a wildcard (*), you can also use shorthand `category:eventName` that will
    // auto-subscribe to all senders of this event
    subscribe(event: string | string[], callback: (params?: Object, id?: number, fullName: string) => void): number | number[] {
        let events = Array.isArray(event) ? event : [event]
        let ids = events.map(e => {
            let fullName = this.establish(e)
            // increment key and return to subscriber as subscriber ID
            let index = Object.keys(globalInstance._pubsub[fullName].subscribers).length
            globalInstance._pubsub[fullName].subscribers[index] = callback
            return index  
        })
        // inform subscriber of last known state
        events.forEach(e => {
            this.last(e, callback)
        })
        return ids.length === 1 ? ids[0] : ids
    }

    // similar to subscribe, except does not listen, simply creates a channel so messages don't get dropped on the floor
    establish(event: string): string {
        let parts = event.split(':')
        let fullName = (parts.length === 2 ? ['*', ...parts] : parts).join(':')
        if (!globalInstance._pubsub[fullName]) {
            globalInstance._pubsub[fullName] = {
                subscribers: {},
                last: null, // storing history is usually overkill, but last value is great for components subscribing after first publish
                lastId: null
            };
        }
        isDev && console.log(`Established channel for ${fullName} (by ${this.name})`)
        return fullName
    }

    // NOTE: unsubscribe must be issued per event per ID, it cannot be aggregated like subscribe, also 
    unsubscribe(event: string, subscriberId: number) {
        delete globalInstance._pubsub[event].subscribers[subscriberId]
    }

    // retrieves last instance of event published to this topic
    // NOTE: if no one was listening yet, the event will be dropped on the floor, not logged, use establish() to create a channel
    // technically you no longer need to call this, as subscribe() will automatically call your callback with last known state
    last(event: string, callback: (params?: Object, id?: number, fullName: string) => void) {
        let parts = event.split(':')
        let fullName = (parts.length === 2 ? ['*', ...parts] : parts).join(':')
        const topic = globalInstance._pubsub[fullName]

        if (!topic) {
            return
        }

        if (topic.last) {
            callback(
                topic.last,
                topic.lastId,
                fullName
            )
        }
    }
}

// default pubSub can be used for subscribing, but not publishing
const defaultPubsub = new PubSub('default')

module.exports = {
    // required call by all publishers before first publish
    setup(namespace: string, options: Object): PubSub {
        return new PubSub(namespace)
    },
    publish(event: string, data: any) {
        throw new Error("can't publish using default pubsub, call `setup` first")
    },
    subscribe(event: string | string[], callback: Function) {
       return defaultPubsub.subscribe(event, callback)
    },
    unsubscribe(event: string, subscriberId: number) {
        return defaultPubsub.unsubscribe(event, subscriberId)
    },
    last(event: string) {
        return defaultPubsub.last(event)
    }
};
