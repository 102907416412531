<script>
    import { Router, Route } from 'svelte-routing'
    import { MaterialApp } from 'svelte-materialify/src'
    import Header from "./components/Header.svelte";
    import Landing from './pages/Landing.svelte';
    import About from './pages/About.svelte';
    import Blog from './pages/Blog.svelte';
    import BlogPost from './pages/blog/Single.svelte'
    import { fetchBlog } from './pages/blog/index'
    import Products from './pages/Products.svelte';
    import Education from './pages/Education.svelte';
    import API from './API/index.svelte';
    import Footer from "./components/Footer.svelte";
    import Profile from './pages/Profile.svelte';
    import Settings from './pages/Settings.svelte';
    import Marker from './pages/Marker.svelte';
    import ForgotPassword from './pages/ForgotPassword.svelte'; 
    import MapRedirect from './MapRedirect.svelte';
    import NotFound from './pages/NotFound.svelte';
    import Support from './pages/Support.svelte';
    import Partner from './pages/Partner.svelte';
    import Invest from './pages/Invest.svelte';
    import SignUp from './pages/SignUp.svelte';
    import SignIn from './pages/SignIn.svelte';
    import Matrix from './pages/Matrix.svelte';
    import NapkinAnalysis from './pages/NapkinAnalysis.svelte';
    import Upgrade from './pages/Upgrade.svelte';
    import ThankYou from './pages/ThankYou.svelte';
    import Newsletter from './Newsletter.svelte';
    import ConfirmEmail from './pages/ConfirmEmail.svelte';

    export let url = ""
    export let user = null

    // used to sync client to prefetched SSR content
    export let data = globalThis.prefetchedData
    $: data
    delete globalThis.prefetchedData

    async function onBlogChange(event) {
        console.log("onBlogChange", event)
        if (event.detail === null) {
            data = null
            return
        }
        data = await fetchBlog(event.detail)
    }
</script>

<Router url="{url}">
    <div id="tooltip"></div>
    <MaterialApp>
        <Header url={url} />
        <div class="body">
            <Route path='/'>
                {#if globalThis.user}
                    <MapRedirect />
                {:else}
                    <Landing />
                {/if}
            </Route>
    
            <Route path='about' component="{About}" />
    
            <Route path='blog'>
                <Blog on:openBlog={onBlogChange} />
            </Route>
            <Route path='blog/tag/:slug' let:params>
                <Blog selectedTag={params.slug} on:openBlog={onBlogChange} />
            </Route>
            <Route path='blog/:slug' let:params>
                <!-- <Blog selectedItem={params.id} preloadedData={preloadedData} /> -->
                {#if data}
                    <BlogPost data={data} on:openBlog={onBlogChange} />
                {:else}
                    {#await fetchBlog(params.slug)}
                        <p>loading...</p>
                    {:then d}
                        <BlogPost data={d} on:openBlog={onBlogChange} />
                    {:catch error}
                        <p>error: {error.message}</p>
                    {/await}
                {/if}
            </Route>

            <Route path='products' component="{Products}" />
            <Route path='products/category/:id' let:params>
                <Products selectedTag={params.id} />
            </Route>
            <Route path='products/:id' let:params>
                <Products selectedItem={params.id} />
            </Route>

            <Route path='education' component="{Education}" />
            <Route path='education/api' component="{API}" />
    
            <Route path='marker' component="{Marker}" />
            <Route path='marker/:id' let:params>
                <Marker selectedItem={params.id} />
            </Route>
    
            <Route path='/account/confirmation/:userId/:token'>
                <ConfirmEmail data={data} />
            </Route>
            <Route path='/account/login'>
                <SignIn data={data} />
            </Route>
            <Route path='/account/forgot-password'>
                <ForgotPassword />
            </Route>
            <Route path='/account/settings' component="{Settings}" />
            <Route path='/thankyou/:reason' let:params>
                <ThankYou reason={params.reason} />
            </Route>
            <Route path='profile' component="{Profile}" />
            <Route path='profile/:id' let:params>
                <Profile selectedItem={params.id} />
            </Route>

            <Route path='/tools/napkin' component="{NapkinAnalysis}" />
            <Route path='/tools/matrix' component="{Matrix}" />
            <Route path='/invest' component="{Invest}" />
            <Route path='/support' component="{Support}" />
            <Route path='/partner' component="{Partner}" />

            <Route path='/signup' component="{SignUp}" />
            <Route path='/upgrade'>
                <Upgrade data={data} />
            </Route>
            <Route path='/newsletter' component="{Newsletter}" />

            <Route path='*' component="{NotFound}" />
        </div>

        <Footer />
    </MaterialApp>
</Router>

<style>
    .body {
        clear: both;
    }
    #tooltip {
        color: #ccc;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        display: none;
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        z-index: 11;
        padding: 5px;
        max-width: 300px;
    }
    :global(.s-navigation-drawer) {
        display: none; /* hide garbage materialify mobile slideout on desktops */
    }

    @media all and (max-width: 1024px) {
        /* mobile devices */
        :global(.s-navigation-drawer) {
            display: flex;
        }
    }
</style>
