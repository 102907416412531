<script>
    import { mdiFaceAgent } from '@mdi/js';
    import {
        TextField,
        Textarea,
        Select,
        Button,
        Icon,
        Snackbar
    } from 'svelte-materialify/src';
    import FormField from '@smui/form-field'
    import { navigate } from 'svelte-routing';
    import GreenWave from '../components/landing/GreenWave.svelte';

    let username
    let email
    let password
    let confirm
    let newsletter = true
    let tier = 'professional'

    const userRules = [
        (v) => !!v || 'Required',
        (v) => {
            const pattern = /^[A-Za-z0-9]{1,}$/;
            return pattern.test(v) || 'Username can only be letters and numbers.';
        }
    ]
    const emailRules = [
        (v) => !!v || 'Required',
        (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || 'Invalid e-mail.';
        },
    ]
    const pwRules = [
        (v) => !!v || 'Required',
        (v) => {
            const pattern = /^.{8,}$/;
            return pattern.test(v) || 'Must be at least 8 characters.';
        },
        (v) => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
            return pattern.test(v) || 'At least one lowercase letter, one uppercase letter, and one number.';
        }
    ]
    const confirmRules = [
        (v) => {
            return password === v || 'Passwords must match.'
        }
    ]

    function register() {
        fetch('/account/signup', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, email, password, newsletter })
        }).then(response => {
            if (response.ok) {
                navigate('/thankyou/signup')
            } else if (response.status === 400) {
                errorMessage = 'One of the fields did not pass server-side validation.'
            } else if (response.status === 401) {
                response.json().then(msg => {
                    errorMessage = msg.message
                })
            } else {
                response.json().then(msg => {
                    errorMessage = 'There was a problem signing up.'
                    showError = true
                })
            }
        })
    }

    let showSupport = false
    let errorMessage = null

    function fieldsOk(fields) {
        for (let [field, rules] of fields) {
            for (let rule of rules) {
                if (rule(field) !== true) {
                    return false
                }
            }
        }
        return true
    }
    let badFields
    $: badFields = !fieldsOk([
        [username, userRules],
        [email, emailRules],
        [password, pwRules],
        [confirm, confirmRules]
    ])

    
</script>

<div class="container">

    <h1>Registration</h1>

    <p>
        Just fill out the fields below.
    </p>

    <TextField rules={userRules} bind:value={username} >Username</TextField>
    <TextField rules={emailRules} bind:value={email} >Email</TextField>
    <TextField rules={pwRules} bind:value={password} type="password">Password</TextField>
    <TextField rules={confirmRules} bind:value={confirm} type="password">Confirm Password</TextField>
    
    <div class="submit-btn">
        <Button disabled={badFields} class="primary-color" on:click={register}>Sign Up</Button>
        {#if badFields}
            <span id="bad-fields">Fill out all fields to register.</span>
        {/if}
    </div>
</div>
<div class="bottom">
    <GreenWave bottomWave={false} />
</div>

<Snackbar class="flex-column snackbar-error" bind:active={errorMessage} bottom center timeout={10000}>
    {errorMessage}
    {#if showSupport}
        <Button text on:click={() => navigate('/support')}>
            <Icon path={mdiFaceAgent} />
            contact support
        </Button>
    {/if}
</Snackbar>

<style type="text/scss">
    @import 'material-theme';

    h1 {
        text-transform: uppercase;
    }
    
    .container {
        width: 600px;
        margin: auto;
        clear: both;

        strong {
            color: $primary-color;
        }

        #tier-group {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        :global(.submit-btn) {
            display: block;
            margin: 20px 0;
        }

        :global(.submit-btn > button.disabled) {
            background-color: #ccc !important;
        }
    }

    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    :global(.profile) {
        margin: 10px;
        width: 250px;
        height: 340px;
        float: left;
    }

    #bad-fields {
        color: $error-color;
        padding: 0 16px;
    }
</style>
