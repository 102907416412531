<svelte:head>
	<script src="/static/marked.js" on:load={onMarkedLoad}></script>
</svelte:head>

<script>
    import { Link } from '../components/routing'
    import Aggregator from '../components/Aggregator.svelte'
    import { externalPromise, fetchAvatar } from '../../utils'
    import {
        Chip,
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        Divider,
        CardActions,
        Button,
        Icon
    } from 'svelte-materialify/src';
    import Tooltip, { Wrapper } from '@smui/tooltip';
    import { mdiMapMarkerRadius } from '@mdi/js';
    import Editable from '../components/Editable.svelte';
    import { navigate } from 'svelte-routing';

    export let selectedItem
    async function logOut() {
        const response = await fetch('/account/signout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        globalThis.location && globalThis.location.assign('/')
    }
    let markedLoaded = externalPromise()
    function onMarkedLoad() {
        markedLoaded.resolve()
    }
    function getMeta(item) {
        return {
            title: item.nickname,
            description: item.bio,
            image: fetchAvatar(item.avatar, 240),
            url: `https://investomation.com/profile/${item.id}`,
            type: 'profile'
        }
    }
    function updateUser(field) {
        return (event) => {
            const data = { [field]: event.detail.value }
            return fetch(`/api/user/${globalThis.user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(r => {
                if (r.status !== 200) {
                    throw new Error(r.statusText)
                }
                return r.json()
            }).then(r => {
                if (r.error) {
                    throw new Error(r.error)
                }
                globalThis.user[field] = event.detail.value
            }).catch(e => {
                event.detail.onError(e)
                // throw e
            })
        }
    }
</script>

<Aggregator
    name="User Profiles"
    relatedName="Other Users"
    endpoint="{'/api/user'}"
    singleEndpoint="/api/user/:id"
    selectedItem={selectedItem}
    meta={getMeta}
>
    <div let:prop={item} slot="single-item" class="single-item">
        <Link to="/profile/{item.id}">
            <Card tile>
                <div class="preview-image">
                    <img src="{fetchAvatar(item.avatar, 150)}" alt="{item.avatar}" />
                </div>
                <CardTitle>{item.nickname}</CardTitle>
                <Divider />
                <CardActions>
                    <Button text>
                        <Icon path={mdiMapMarkerRadius} />
                        {item.location || 'N/A'}
                    </Button>
                </CardActions>
            </Card>
        </Link>
    </div>
    <div slot="opened-item" class="user-profile opened-item" let:prop={user}>
        {#if globalThis.user && globalThis.user.id === user.id}
            <Wrapper>
                <a href="https://en.gravatar.com" target="_blank" alt="Update your Gravatar">
                    <img class="profile-image" src="{globalThis.fetchGravatar(user.avatar) + '&s=240'}" alt="{user.avatar}" />
                </a>
                <Tooltip>
                    Replace your Gravatar
                </Tooltip>
            </Wrapper>
        {:else}
            <img class="profile-image" src="{globalThis.fetchGravatar(user.avatar) + '&s=240'}" alt="{user.avatar}" />
        {/if}
        <div class="user-profile-info">
            <h2>
                {#if globalThis.user && globalThis.user.id === user.id}
                        <Editable
                            on:submit={updateUser("nickname")}
                            value={user.nickname}
                        />
                {:else}
                    {user.nickname}
                {/if}
            </h2>
            <div class="user-location">
                <Icon path={mdiMapMarkerRadius} />
                {#if globalThis.user && globalThis.user.id === user.id}
                    <Editable
                        on:submit={updateUser("location")}
                        value={user.location || 'Add Location'}
                    />
                {:else}
                    {user.location || 'Unknown Location'}
                {/if}
            </div>
            {#await markedLoaded}
                Loading...
            {:then}
                <div id="bio-container">
                    {#if globalThis.user && globalThis.user.id === user.id}
                        <Editable
                            on:submit={updateUser("bio")}
                            value={user.bio}
                            textarea
                        >
                            <div class="editable" slot="value" let:content={content}>
                                {@html content ? globalThis.marked(content) : 'Click here to write a bio'}
                            </div>
                        </Editable>
                    {:else}
                        {@html user.bio ? globalThis.marked(user.bio) : 'No Bio'}
                    {/if}
                </div>
            {/await}
            {#if globalThis.user && globalThis.user.id === user.id}
                <div class="user-controls">
                    <h3>Personal Preferences</h3>
                    <Button outlined on:click={() => navigate('/account/settings')}>Settings</Button>
                    <Button outlined on:click={logOut}>Log Out</Button>
                </div>
            {:else}
                <div class="user-controls">
                    <h3>Actions</h3>
                    <Button outlined>Contact User</Button>
                    <Button outlined>Public Markers</Button>
                </div>
            {/if}
        </div>
    </div>
</Aggregator>

<style type="text/scss">
    .single-item {
        width: 150px;
        display: inline-block;
        margin: 5px;

        :global(.s-card-title) {
            padding: 0 10px;
            font-size: 0.8rem;
            font-weight: 700;
            line-height: 1.6rem;
        }
    }

    .user-profile {
        float: left;
        width: 70%;
        margin: 16px;

        .profile-image {
            width: 240px;
            height: 240px;
        }

        :global(.user-profile-info) {
            margin-left: 16px;
            width: calc(100% - 280px);
            display: inline-block;
            vertical-align: top;
        }

        :global(#bio-container) {
            padding-top: 10px;
        }

        :global(.user-controls) {
            padding-top: 20px;
        }

        :global(h2) {
            line-height: 3rem;
        }

        :global(.user-location) {
            color: #777;
        }
    }

    .editable {
        display: inline-block;
    }
</style>