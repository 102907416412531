<script>
    import { navigate as nav, Link } from './routing'
    import {
        Avatar,
        Button,
        TextField,
        Icon,
        Menu,
        List,
        ListItem,
        Snackbar,
        Dialog,
        NavigationDrawer,
        Overlay,
        Divider
    } from 'svelte-materialify/src'
    import {
        mdiAccount,
        mdiAccountCog,
        mdiMapMarkerRadius,
        mdiCalendar,
        mdiAccountMultiple,
        mdiFileDocumentEdit,
        mdiMagnify,
        mdiMenu,
        mdiMap,
        mdiSchool,
        mdiLifebuoy,
        mdiChevronLeft,
        mdiCashMultiple,
        mdiFileChart,
        mdiChartLine,
        mdiBookOpenPageVariant,
        mdiNewspaperVariantMultiple,
        mdiHelpRhombus,
        mdiAccountTie,
        mdiHandshake,
        mdiFrequentlyAskedQuestions,
        mdiArrowUpBold
    } from '@mdi/js'
    import Search from './search/Search.svelte'
    import { fetchAvatar } from '../../utils'

    let snackbarMessage = null

    let notImplemented = () => {
        snackbarMessage = "We're still working on this page."
    }

    export let hardUrl // force actual page navigation/reload
    let loginDialog;
    let username;
    let password;
    async function logIn() {
        const response = await fetch('/account/signin', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        })
        console.log(response)
        loginDialog = false
        if (response.ok) {
            // reload the page to fetch logged-in data
            globalThis.location && globalThis.location.assign(globalThis.location.href)
        } else {
            let error = await response.json()
            snackbarMessage = error.error
        }
    }
    function notOnMobile() {
        snackbarMessage = "Not available on mobile devices"
    }
    function signUp() {
        loginDialog = false
        navigate('/signup')
    }
    function goToMatrix() {
        navigate('/tools/matrix')
    }

    // mobile menus
    let showMenu = false
    let secondaryMenu = null
    let navMobile = (url) => {
        showMenu = false
        secondaryMenu = false
        navigate(url)
    }

    function navigate(url) {
        if (hardUrl) {
            globalThis.location.href = url
        } else {
            nav(url)
        }
    }
</script>

<Snackbar class="flex-column" bind:active={snackbarMessage} bottom center timeout={3000}>
    {snackbarMessage}
</Snackbar>

<NavigationDrawer class="mobile-menu primary-color" right absolute active={showMenu}>
    <List>
        {#if !secondaryMenu}
            {#if globalThis.user}
                <ListItem on:click={() => navigate(`/profile/${globalThis.user.id}`)}>
                    <span slot="prepend" class="ml-n2">
                        <Avatar size={40}><img src="{fetchAvatar(globalThis.user.avatar, 100)}" alt="profile" /></Avatar>
                    </span>
                    {globalThis.user.nickname}
                </ListItem>
            {:else}
                <ListItem on:click={() => {
                    loginDialog = true
                    showMenu = false
                }}>
                    <span slot="prepend">
                        <Icon path={mdiAccount} />
                    </span>
                    Log In
                </ListItem>
            {/if}
            <Divider />
            <ListItem on:click={() => secondaryMenu = 'products'}>
                <span slot="prepend">
                    <Icon path={mdiMap} />
                </span>
                Products
            </ListItem>
            <ListItem on:click={() => secondaryMenu = 'education'}>
                <span slot="prepend">
                    <Icon path={mdiSchool} />
                </span>
                Education
            </ListItem>
            <ListItem on:click={() => secondaryMenu = 'support'}>
                <span slot="prepend">
                    <Icon path={mdiLifebuoy} />
                </span>
                Support
            </ListItem>
            {#if globalThis.user}
                <Divider />
                <ListItem on:click={() => navMobile(`/profile/${globalThis.user.id}`)}>
                    <span slot="prepend">
                        <Icon path={mdiAccountCog} />
                    </span>
                    My Profile
                </ListItem>
                <ListItem on:click={() => navMobile(`/marker`)}>
                    <span slot="prepend">
                        <Icon path={mdiMapMarkerRadius} />
                    </span>
                    My Markers
                </ListItem>
                <ListItem on:click={notImplemented}>
                    <span slot="prepend">
                        <Icon path={mdiCalendar} />
                    </span>
                    My Events
                </ListItem>
                <ListItem on:click={notImplemented}>
                    <span slot="prepend">
                        <Icon path={mdiFileDocumentEdit} />
                    </span>
                    My Contracts
                </ListItem>
                <ListItem on:click={() => navMobile(`/profile`)}>
                    <span slot="prepend">
                        <Icon path={mdiAccountMultiple} />
                    </span>
                    My Contacts
                </ListItem>
            {/if}
        {:else}
            <ListItem on:click={() => secondaryMenu = null}>
                <span slot="prepend">
                    <Icon path={mdiChevronLeft} />
                </span>
                Back
            </ListItem>
            <Divider />
            {#if secondaryMenu === 'products'}
                <ListItem on:click={() => navMobile(`/invest`)}>
                    <span slot="prepend">
                        <Icon path={mdiCashMultiple} />
                    </span>
                    Invest With Us
                </ListItem>
                <ListItem on:click={() => navMobile(`/tools/napkin`)}>
                    <span slot="prepend">
                        <Icon path={mdiFileChart} />
                    </span>
                    Napkin Analysis
                </ListItem>
                <ListItem on:click={() => navMobile(`/tools/matrix`)}>
                    <span slot="prepend">
                        <Icon path={mdiChartLine} />
                    </span>
                    Region Analysis
                </ListItem>
                <ListItem on:click={notOnMobile}>
                    <span slot="prepend">
                        <Icon path={mdiMap} />
                    </span>
                    Heat Map
                </ListItem>
                <ListItem on:click={() => navMobile(`/products`)}>
                    <span slot="prepend">
                        <Icon path={mdiCashMultiple} />
                    </span>
                    Order Report
                </ListItem>
            {:else if secondaryMenu === 'education'}
                <ListItem on:click={() => navMobile(`/blog`)}>
                    <span slot="prepend">
                        <Icon path={mdiNewspaperVariantMultiple} />
                    </span>
                    Blog + YouTube
                </ListItem>
                <ListItem on:click={() => navMobile(`/education`)}>
                    <span slot="prepend">
                        <Icon path={mdiBookOpenPageVariant} />
                    </span>
                    Courses
                </ListItem>
                <ListItem on:click={notImplemented}>
                    <span slot="prepend">
                        <Icon path={mdiHelpRhombus} />
                    </span>
                    Investomation Guide
                </ListItem>
            {:else if secondaryMenu === 'support'}
                <ListItem on:click={() => navMobile(`/about`)}>
                    <span slot="prepend">
                        <Icon path={mdiAccountTie} />
                    </span>
                    About Us
                </ListItem>
                <ListItem on:click={() => navMobile(`/support`)}>
                    <span slot="prepend">
                        <Icon path={mdiLifebuoy} />
                    </span>
                    Technical Support
                </ListItem>
                <ListItem on:click={() => navMobile(`/partner`)}>
                    <span slot="prepend">
                        <Icon path={mdiHandshake} />
                    </span>
                    Partnership/Press
                </ListItem>
                <ListItem on:click={notImplemented}>
                    <span slot="prepend">
                        <Icon path={mdiFrequentlyAskedQuestions} />
                    </span>
                    FAQ
                </ListItem>
            {/if}
        {/if}
    </List>
</NavigationDrawer>
<Overlay index={2} active={showMenu} on:click={() => {
    showMenu = false
    secondaryMenu = null
}} absolute />

<header id="header">
    <a id="home" href={globalThis.user ? '/map' : '/'} class="float-left">
        <div id="logo">
        </div>
    </a>
    <div id="main-menu" class="float-left">
        <Menu hover>
            <div slot="activator">
                <Button text>Products</Button>
            </div>
            <List>
                <ListItem on:click={() => navigate('/products')}>Property Reports</ListItem>
                <ListItem on:click={goToMatrix}>Region Analysis</ListItem>
                <ListItem on:click={() => navigate('/tools/napkin')}>Napkin Analysis</ListItem>
                <ListItem on:click={() => globalThis.location.assign('/map')}>Heat Map</ListItem>
            </List>
        </Menu>
        <Menu hover>
            <div slot="activator">
                <Button text>Services</Button>
            </div>
            <List>
                <ListItem on:click={() => navigate(globalThis.user ? '/upgrade' : '/signup')}>Membership</ListItem>
                <ListItem on:click={() => navigate('/education/api')}>API</ListItem>
                <ListItem on:click={() => navigate('/invest')}>Invest with Us</ListItem>
            </List>
        </Menu>
        <Menu hover>
            <div slot="activator">
                <Button text>Education</Button>
            </div>
            <List>
                <ListItem on:click={() => navigate('/blog')}>Blog</ListItem>
                <ListItem on:click={() => navigate('/education')}>Courses</ListItem>
            </List>
        </Menu>
        <Menu hover>
            <div slot="activator">
                <Button text>Support</Button>
            </div>
            <List>
                <ListItem on:click={() => navigate('/about')}>About Us</ListItem>
                <ListItem on:click={() => navigate('/support')}>Technical Support</ListItem>
                <ListItem on:click={() => navigate('/partner')}>Partnership/Press</ListItem>
                <ListItem on:click={notImplemented}>FAQ</ListItem>
            </List>
        </Menu>
    </div>
</header>
<div id="search-area">
    <Search id="mainSearch" class="float-left mr-1 main-search" outlined dense filled >
        <div class="search-suggestion-title" slot="search-suggestion-title">
            <span class="query">Location</span>
            <span class="type">Category</span>
            <span class="population">Population</span>
        </div>
        <div class="search-suggestion" slot="search-suggestion" let:row>
            <span class="query">{row.element.query}</span>
            <span class="type">{row.element.type}</span>
            <span class="population">{row.element.pop || ''}</span>
        </div>
    </Search>
    <Icon class="mobile-search-btn" size="32px" path={mdiMagnify} />
    <Button class="mobile-menu-btn" icon on:click={() => showMenu = true}>
        <Icon size="32px" path={mdiMenu} />
    </Button>
    {#if globalThis.user}
        <Menu hover right class="regular-menu">
            <div slot="activator">
                <!-- <Button class="profile-button" text>{globalThis.user.username}</Button> -->
                <div class="profile-button">
                    <img src="{fetchAvatar(globalThis.user.avatar, 100)}" alt="profile" />
                </div>
            </div>
            <List>
                <ListItem on:click={() => navigate(`/profile/${globalThis.user.id}`)}>
                    <span slot="prepend" class="hl">
                        <Icon path={mdiAccountCog} />
                    </span>
                    <span class="hl">{globalThis.user.nickname}</span>
                </ListItem>
                <ListItem on:click={() => navigate(`/marker`)}>
                    <span slot="prepend">
                        <Icon path={mdiMapMarkerRadius} />
                    </span>
                    My Markers
                </ListItem>
                <ListItem on:click={notImplemented}>
                    <span slot="prepend">
                        <Icon path={mdiCalendar} />
                    </span>
                    My Events
                </ListItem>
                <ListItem on:click={notImplemented}>
                    <span slot="prepend">
                        <Icon path={mdiFileDocumentEdit} />
                    </span>
                    My Contracts
                </ListItem>
                <ListItem on:click={() => navigate(`/profile`)}>
                    <span slot="prepend">
                        <Icon path={mdiAccountMultiple} />
                    </span>
                    My Contacts
                </ListItem>
                {#if globalThis.user.tier !== 'business'}
                    <ListItem class="primary-text" on:click={() => navigate(`/upgrade`)}>
                        <span slot="prepend">
                            <Icon class="primary-text" path={mdiArrowUpBold} />
                        </span>
                        Upgrade Plan
                    </ListItem>
                {/if}
            </List>
        </Menu>
    {:else}
        <Button class="primary-color float-left pl-2 pr-2 login-btn" on:click={() => {loginDialog = true}}>
            <Icon path={mdiAccount} />
            Log In
        </Button>
    {/if}
</div>

<Dialog class="pa-4 text-center login-box" bind:active={loginDialog}>
    <TextField bind:value={username}>Username</TextField>
    <TextField type="password" bind:value={password}>Password</TextField>
    <Link class="forgot-password" to="/account/forgot-password" on:click={() => {loginDialog = false}}>Forgot password?</Link>
    <Button class="primary-color" on:click={logIn}>Log In</Button>
    <Button class="primary-text" text on:click={signUp}>Sign Up</Button>
</Dialog>

<style type="text/scss">
    @import 'material-theme';

    @media only screen and (max-width: 700px) {
        #main-menu {
            display: none !important;
        }
        #search-area {
            :global(.profile-button), :global(.login-btn) {
                display: none !important;
            }
            :global(.mobile-menu-btn) {
                display: block !important;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        :global(.main-search) {
            display: none;
        }
        #search-area {
            :global(.mobile-search-btn) {
                display: block !important;
            }
        }
    }

    @media only screen and (min-width: 701px) and (max-width: 1000px) {
        #logo {
            width: 100px !important;
            height: 12px !important;
        }

        #main-menu {
            position: absolute;
            z-index: 6;
            background: rgba(255, 255, 255, 0.1);
        }
    }

    .hl {
        font-weight: 700;
        color: $primary-color;
    }

    #logo {
        grid-area: logo;
        background-image: url(/static/logo_long.svg);
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 50px;
        width: 200px;
        font-size: 1rem;
        margin: 8px 28px;
    }
    #main-menu {
        display: table;
        height: 50px;
        
        > :global(div) {
            display: table-cell;
            vertical-align: middle;
        }
    }
    #search-area {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 10px;
        z-index: 7; /* needs to be above leaflet map (which is currently at 5, and 6 for tooltip) */

        :global(div[slot="clear-icon"]) {
            position: absolute;
            right: 32px;
            top: 5px;
        }

        :global(.main-search .s-input) {
            padding-top: 0 !important;
            // width: min(250px, 20vw);
        }
        :global(.regular-menu) {
            overflow: hidden;

            :global(.s-list-item) {
                min-height: 32px;
            }

            :global(.s-list-item .s-icon) {
                margin-top: 12px;
                margin-bottom: 12px;
            }

            :global(.s-list-item__content) {
                padding: 8px 0;
            }
            
            :global(i) {
                margin-right: 16px;
            }
        }
        :global(.mobile-menu) {
            position: fixed;
            display: none;
        }
        :global(.mobile-search-btn), :global(.mobile-menu-btn) {
            display: none;
            float: left;
            padding: 4px;
        }
    }

    .query {
        width: 500px;
        display: inline-block;
    }

    .type {
        width: 100px;
        display: inline-block;
    }

    .population {
        width: 150px;
        display: inline-block;
        text-align: right;
    }

    .search-suggestion-title {
        color: $primary-color;
        font-weight: 700;
    }

    .search-suggestion {
        :global(.type) {
            color: #ccc;
        }
    }

    :global(button span) {
        font-family: $body-font-family;
    }
    :global(.s-menu div) {
        font-family: $body-font-family;
    }
    
    .profile-button {
        @include profile-img;
        border-radius: 25%;
        cursor: pointer;

        :global(img) {
            width: 36px;
        }
    }

    :global(.login-box) {
        width: 250px !important;

        :global(.s-input) {
            padding-top: 5px;
        }

        :global(.forgot-password) {
            display: block;
            font-size: 0.7rem;
            padding: 8px;
        }
    }
</style>