<svelte:head>
    <script src="/static/marked.js" on:load={onMarkedLoad}></script>
    <script src="https://unpkg.com/leaflet@1.4.0/dist/leaflet.js" on:load={onLeafletLoad} integrity="sha512-QVftwZFqvtRNi0ZyCtsznlKSWOStnDORoefr1enyq5mVL4tmKB3S/EnC3rRJcxCPavG10IcrVGSmPh6Qw5lwrg==" crossorigin=""></script>
    <link rel="stylesheet" href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css" integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA==" crossorigin=""/>
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
</svelte:head>

<script>
    import { navigate, Link } from '../components/routing'
    import { externalPromise } from '../../utils'
    import Aggregator from '../components/Aggregator.svelte'
    import {
        Checkbox,
        Chip,
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        Divider,
        CardActions,
        Button,
        Icon,
        Tooltip
    } from 'svelte-materialify/src';
    import { mdiAccount, mdiRefresh, mdiMapMarkerRadius } from '@mdi/js';
    import { toDate } from '../utils'
    import Editable from '../components/Editable.svelte';

    const makeIcon = (color, icon) => {
        const contrastColor = (color) => {
            //if only first haflf of color is defined, repeat it
            if(color.length < 5) {
                color += color.slice(1);
            }
            return (color.replace('#','0x')) > (0xffffff/2).toString() ? '#333' : '#fff';
        }
        const foregroundColor = contrastColor(color);

        const markerStyle = `
            background-color: ${color};
            width: 2em;
            height: 2em;
            display: block;
            left: -1em;
            top: -1em;
            position: relative;
            border-radius: 2em 2em 0;
            transform: translate(0px, 5px) rotate(45deg);
            border: 1px solid ${foregroundColor};
            font-size: 20px !important;
        `;

        const iconStyle = `
            color: ${foregroundColor};
            width: 2em;
            height: 2em;
            text-align: center;
            line-height: 1;
            font-size: 1.5em;
            transform: rotate(-45deg);
        `;

        return L.divIcon({
            className: "custom-pin",
            iconAnchor: [0, 24],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `
                <span class="marker-pin" style="${markerStyle}">
                <i class="material-icons" style="${iconStyle}">${icon}</i>
                </span>
            `
        })
    }

    export let selectedItem
    const accessToken = 'pk.eyJ1IjoibWVsaGVvciIsImEiOiJjanVycDQ5a2UxZG1zNDRvMXdrb21wcGh3In0.I7JKZ6C6Nb2JR7jNM4j0kg';

    // loading logic for the map
    const loadMap = ({ id, latlng, color, icon }) => {
        // give the leaflet library a few ms to load before calling it
        leafletLoaded.then(() => {
            let latLng = latlng.split(',').map(c => parseFloat(c))
            let map = L.map(id, {
                zoomControl: false
            }).setView(latLng, 13)

            // disable any map interaction
            map.dragging.disable();
            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.keyboard.disable();
            if (map.tap) map.tap.disable();

            // load correct tile
            L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                attribution: '',
                id: 'mapbox/streets-v11',
                accessToken: accessToken
            }).addTo(map)

            // add marker
            L.marker(latLng, {
                icon: makeIcon(color, icon)
            }).addTo(map)
        })
        return '' // hide 'undefined' during marker load
    }

    let markedLoaded = externalPromise()
    function onMarkedLoad() {
        markedLoaded.resolve()
    }
    let leafletLoaded = externalPromise()
    function onLeafletLoad() {
        leafletLoaded.resolve()
    }

    function addDocument() {
    }

    function addEvent() {

    }

    /*pubsub.publish('search:add.category', {
        name: 'Marker',
        keyword: 'marker',
        searchFn
    })*/
    function getMeta(item) {
        return {
            title: item.name,
            description: item.summary,
            image: item.icon,
            url: `https://investomation.com/marker/${item.id}`,
            type: 'article'
        }
    }

    function updateMarker(field, marker) {
        return (event) => {
            const data = { [field]: event.detail.value }
            fetch(`/api/marker/${selectedItem}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(r => {
                if (r.status !== 200) {
                    throw new Error(r.statusText)
                }
                return r.json()
            }).then(r => {
                if (r.error) {
                    throw new Error(r.error)
                }
                marker[field] = event.detail.value
            }).catch(e => {
                event.detail.onError(e.message)
                // throw e
            })
        }
    }
</script>

<Aggregator
    name="Markers"
    relatedName="Related Markers"
    endpoint="{'/api/marker'}"
    singleEndpoint="/api/marker/:id"
    singleItemSize=1
    selectedItem={selectedItem}
    meta={getMeta}
>
    <div let:prop={item} slot="single-item" class="single-item">
        <Link to="/marker/{item.id}">
            <Card tile>
                <div id="{item.id}" class="preview-image" style="background-color: {item.color}">
                    {loadMap(item)}
                </div>
                <CardTitle>{item.name}</CardTitle>
                <Divider />
                <CardActions>
                    <Button text>
                        <Icon path={mdiMapMarkerRadius} />
                        {item.zip || 'N/A'}
                    </Button>
                </CardActions>
            </Card>
        </Link>
    </div>
    <div slot="opened-item" class="marker-container opened-item" let:prop={marker}>
        <div id="{marker.id}" class="preview-image" style="background-color: {marker.color}">
            {loadMap(marker)}
        </div>
        <div class="marker-content-info-top">
            <h2>
                {#if globalThis.user && globalThis.user.id === marker.user.id}
                    <Editable
                        on:submit={updateMarker('name', marker)}
                        value={marker.name}
                    />
                {:else}
                    {marker.name}
                {/if}
            </h2>
            <div class="marker-location">
                <Icon path={mdiMapMarkerRadius} />
                {marker.zip || 'Unspecified'}
            </div>
            {#if globalThis.user && globalThis.user.id !== marker.user.id}
                <div class="marker-owner">
                    <Icon path={mdiAccount} />
                    Owned by <Link to="/profile/{marker.user.id}">{marker.user.nickname}</Link>
                </div>
            {/if}
            <div class="marker-content-info">
                {#if globalThis.user && globalThis.user.id === marker.user.id}
                    <div class="marker-sharing">
                        <Checkbox
                            checked={marker.public}
                            on:change={() => {
                                marker.public = !marker.public
                                updateItem(marker)
                            }}
                        >Public</Checkbox>
                    </div>
                    <div class="marker-user-content marker-section">
                        <Editable
                            on:submit={updateMarker('content', marker)}
                            value={marker.content}
                            textarea
                        >
                            <div class="editable" slot="value" let:content={content}>
                                {#await markedLoaded}
                                    Loading...
                                {:then}
                                    {@html content ? globalThis.marked(content) : 'Add marker content (private by default)'}
                                {/await}
                            </div>
                        </Editable>
                    </div>
                    <div class="marker-summary marker-section">
                        <Editable
                            on:submit={updateMarker('summary', marker)}
                            value={marker.summary}
                            textarea
                        >
                            <div class="editable" slot="value" let:content={content}>
                                {#await markedLoaded}
                                    Loading...
                                {:then}
                                    {@html content ? globalThis.marked(content) : 'Write a public summary'}
                                {/await}
                            </div>
                        </Editable>
                    </div>
                {:else}
                    {#await markedLoaded}
                        Loading...
                    {:then}
                        {#if marker.public}
                            {@html globalThis.marked(marker.content)}
                        {:else if marker.summary}
                            {@html globalThis.marked(marker.summary)}
                        {:else}
                            This marker is private
                        {/if}
                    {/await}
                {/if}
            </div>
            <div class="documents">
                <h3>Forms & Documents</h3>
                {#if globalThis.user && globalThis.user.id === marker.user.id}
                    No documents
                    <div class="add-document marker-section">
                        <Button outlined on:click={addDocument}>Add Document</Button>
                    </div>
                {:else if marker.public}
                    No documents
                {:else}
                    No public documents available
                {/if}
            </div>
            <div class='events'>
                <h3>Events</h3>
                {#if globalThis.user && globalThis.user.id === marker.user.id}
                    {#if marker.event.length}
                        {#each marker.event as event}
                            <Tooltip bottom class="event-tooltip">
                                <Chip on:click={() => {
                                    navigate(`/event/${event.id}`)
                                }}>
                                    <span>{event.name}</span>
                                    {#if event.interval !== 'no'}
                                        <Icon path={mdiRefresh} />
                                    {/if}
                                </Chip>
                                <span slot="tip">
                                    <div>{event.interval === 'no' ? 'Date:' : 'First occurence:'} {toDate(event.startDate)}</div>
                                    {#if event.interval !== 'no'}
                                        <div>Repeats {event.interval === 'annual' ? 'annualy' : event.interval}</div>
                                    {/if}
                                    <div>
                                        {#if event.reminder === 'silent'}
                                            No reminder
                                        {:else}
                                            Reminder via {event.reminder}
                                        {/if}
                                    </div>
                                </span>
                            </Tooltip>
                        {/each}
                    {:else}
                        No Events Scheduled
                    {/if}
                    <div class="add-event marker-section">
                        <Button outlined on:click={addEvent}>Add Event</Button>
                    </div>
                {:else if marker.public}
                    {#each marker.event as event}
                        <Tooltip bottom class="event-tooltip">
                            <Chip on:click={() => {
                                navigate(`/event/${event.id}`)
                            }}>
                                <span>{event.name}</span>
                                {#if event.interval !== 'no'}
                                    <Icon path={mdiRefresh} />
                                {/if}
                            </Chip>
                            <span slot="tip">
                                <div>{event.interval === 'no' ? 'Date:' : 'First occurence:'} {toDate(event.startDate)}</div>
                                {#if event.interval !== 'no'}
                                    <div>Repeats {event.interval === 'annual' ? 'annualy' : event.interval}</div>
                                {/if}
                            </span>
                        </Tooltip>
                    {/each}
                {:else}
                    No public events
                {/if}
            </div>
        </div>
    </div>
</Aggregator>

<style type="text/scss">
    @import 'material-theme';

    .preview-image {
        width: 150px;
        height: 150px;
        z-index: 0; /* fixes leaflet bug that causes map to render on top of menus */
        border: 1px solid $secondary-bg-color; /* subtle border */

        :global(.marker-pin) {
            cursor: default;
        }
    }

    .single-item {
        width: 150px;
        display: inline-block;
        margin: 5px;
        white-space: nowrap;
        overflow: hidden;

        :global(.s-card-title) {
            padding: 0 10px;
            font-size: 0.8rem;
            font-weight: 700;
            line-height: 1.6rem;
        }
    }

    .marker-container {
        float: left;
        width: 60%;
        min-width: 600px;
        margin: 16px;

        :global(.preview-image) {
            float: left;
        }

        :global(.marker-content-info-top) {
            margin-left: 16px;
            width: calc(100% - 280px);
            display: inline-block;
            vertical-align: top;
            float: left;

            :global(.events) {
                :global(.s-chip) {
                    margin: 5px;
                    cursor: pointer;

                    &:hover {
                        background: $primary-color;
                    }
                }
            }
        }

        :global(.marker-content-info) {
            clear: both;
            padding-top: 20px;
        }

        :global(h2) {
            line-height: 3rem;
        }

        :global(.marker-location) {
            color: #777;
        }

        :global(.marker-section) {
            padding: 10px 0;
        }
    }

    :global(.s-tooltip) {
        div {
            text-align: left;
        }
    }

    .editable {
        display: inline-block;
    }
</style>