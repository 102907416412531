<script>
    import { createEventDispatcher } from 'svelte';
    import {
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        CardActions,
        DataTable,
        DataTableHead,
        DataTableRow,
        DataTableCell,
        DataTableBody,
        ListItem,
        Tooltip
    } from 'svelte-materialify/src';

    export let selected = '';
    const memberships = {
        'Explorer': {
            cost: 'Free',
            subtitle: 'For those who want to learn how to escape the rat race.',
            feature1: 'Real Estate Guides',
            feature1_tooltip: 'Learn how to invest in real estate through BRRR, refinancing, tax strategies, 1031 exchanges, and more',
            feature2: 'Basic Analytics',
            feature2_tooltip: 'See trends at a macro level, across all of US',
            feature3: 'Points of Interest',
            feature3_tooltip: 'Mark important locations, store docs, links, and events for your properties',
            feature4: 'Lease Agreements',
            feature4_tooltip: 'Download standard lease agreements for your properties',
            feature5: 'Market Insights',
            feature5_tooltip: 'Monthly market trends and insights, delivered to your inbox',
            feature6: 'Current Trends',
            feature6_tooltip: 'See how regions are evolving across US'
        },
        'Trailblazer': {
            cost: '$50/mo',
            msrp: '$100/mo',
            subtitle: 'For those ready to take action and earn passive income.',
            feature1: 'All Explorer features',
            feature2: 'Street-Level Analytics',
            feature2_tooltip: 'See trends at a micro level, block by block',
            feature3: 'Advanced Guides',
            feature3_tooltip: 'Advanced guides on upzoning, easements, infinite banking, land investing, and more',
            feature4: 'State-Specific Leases',
            feature4_tooltip: 'Download lease agreements specific to the state your properties are in',
            feature5: 'Personalized Insights',
            feature5_tooltip: 'See how market trends are affecting your own properties',
            feature6: 'Developing Trends',
            feature6_tooltip: 'See how regions are expected to evolve in the next few years using our predictive models'
        },
        'Architect': {
            cost: '$500/mo',
            msrp: '$2000/mo',
            subtitle: 'For those ready to scale their cash-flow.',
            feature1: 'All Trailblazer features',
            feature2: 'Property Management',
            feature2_tooltip: 'Let us manage your properties for you with our staff, anywhere in the US (first 2 free, then $100/mo per property)',
            feature3: 'Leverage Our Network',
            feature3_tooltip: 'Leverage our network of professionals to find, buy, and manage your rental properties',
            feature4: 'Off-Market Deals',
            feature4_tooltip: 'Access to deals not available on MLS, Zillow, or other public sites',
            feature5: 'Tax Planning & Prep',
            feature5_tooltip: 'Let us handle your taxes for you, and provide tax prep advice on properties we manage',
            feature6: 'TrendFinder™ AI',
            feature6_tooltip: 'AI that scans correlations in trends to calibrate its predictive models (more accurate trend detection)'
        }
    }

    const dispatch = createEventDispatcher();
    function emitSelect(title) {
        selected = title;
        dispatch('select', title);
    }
</script>

<h2>Choose a plan that's right for you</h2>
<div class="tiers">
    {#each Object.entries(memberships) as [title, benefits]}
        <div class="tier" on:click={() => emitSelect(title)} >
            <Card class="tier-card {selected === title ? 'selected' : ''}">
                <CardTitle>
                    {title}
                </CardTitle>
                <CardSubtitle>
                    {benefits.subtitle}
                </CardSubtitle>
                <CardText>
                    <ListItem>
                        <Tooltip top>
                            {benefits.feature1}
                            <span slot="tip">{benefits.feature1_tooltip || benefits.feature1}</span>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Tooltip top>
                            {benefits.feature2}
                            <span slot="tip">{benefits.feature2_tooltip || benefits.feature2}</span>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Tooltip top>
                            {benefits.feature3}
                            <span slot="tip">{benefits.feature3_tooltip || benefits.feature3}</span>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Tooltip top>
                            {benefits.feature4}
                            <span slot="tip">{benefits.feature4_tooltip || benefits.feature4}</span>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Tooltip top>
                            {benefits.feature5}
                            <span slot="tip">{benefits.feature5_tooltip || benefits.feature5}</span>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Tooltip top>
                            {benefits.feature6}
                            <span slot="tip">{benefits.feature6_tooltip || benefits.feature6}</span>
                        </Tooltip>
                    </ListItem>
                </CardText>
                <CardActions class="price">
                    <span class="original-price">{benefits.msrp || ''}</span>
                    <span class="price">{benefits.cost}</span>
                </CardActions>
            </Card>
        </div>
    {/each}
</div>

<style type="text/scss">
    h2 {
        text-align: center;
        margin: 2rem 0;
    }

    .tiers {
        display: flex;
        justify-content: space-between;
        margin: 0 -1rem;
        max-width: 800px;
        margin: 0 auto;

        :global(.tier) {
            width: 350px;
            background: #fcfcfc;
            margin: 0 10px;
            cursor: pointer;
            border-radius: 5px;
        }

        :global(.tier-card) {
            height: 100%;
        }

        :global(.tier-card.selected) {
            background: rgba(139, 195, 74, 0.8);
        }

        :global(.price) {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.5rem;
            font-weight: bold;
            color: var(--primary-color);
        }

        :global(.original-price) {
            text-decoration: line-through;
            font-size: 1rem;
            color: #666;
        }
    }

    @media (max-width: 800px) {
        .tiers {
            flex-direction: column;
            align-items: center;

            :global(.tier) {
                margin: 10px 0;
            }
        }
    }
</style>