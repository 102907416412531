<script>
    import { createEventDispatcher } from 'svelte'
    import { navigate, Link } from '../../components/routing'
    import {
        Button,
        Card,
        CardActions,
        CardSubtitle,
        CardText,
        CardTitle,
        Divider,
        Icon,
        Textarea
    } from 'svelte-materialify/src'
    import { mdiCalendar, mdiClose, mdiChevronLeft, mdiLock } from '@mdi/js';
    import { fetchAvatar, isBrowser } from '../../../utils'
    import Paywall from '../../components/Paywall.svelte';
    import Modal from '../../components/Modal.svelte';
    import Subscribe from '../../components/Subscribe.svelte';

    export let data = { post: {}, comments: [] }
    const dispatch = createEventDispatcher()
    function selectBlog(slug) {
        // animate scroll to top
        window.scrollTo({ top: 0, behavior: 'smooth' })
        dispatch('openBlog', slug)
    }

    const toDate = (d) => new Date(d).toLocaleDateString(undefined, { timeZone: 'UTC' })
    let commentText;
    let related = []
    if (isBrowser()) {
        (async () => {
            const post = await fetch(`/api/blog/${data.post.slug}`).then(r => r.json())
            for (let tag of post.blogtag) {
                let posts = await fetch(`/api/blog_tag/${tag.slug}`).then(r => r.json())
                related = related.concat(posts.blog)
            }
            let seen = {}
            related = related.filter(i => {
                if (i.id === data.post.id) {
                    return false
                }
                if (seen[i.id]) {
                    return false
                }
                seen[i.id] = true
                return true
            })
            related = related.sort((a, b) => b.created - a.created) // newest first
        })()
    }
    let host = 'https://investomation.com'

    function shorten(text) {
        // take the first 150 characters (rounded up to nearest word)
        return text.split(' ').slice(0, 150 / 4).join(' ') + '...'
    }

    let y = 0
    let showModal = false
    if (typeof localStorage !== 'undefined') { 
        showModal = !globalThis.user && !localStorage.getItem('subscribed')
    }
    function isSubscribed() {
        if (typeof localStorage !== 'undefined') { 
            return globalThis.user && globalThis.user.subscribed
        }
        return globalThis.user
    }

    // on subscribe event, store state to local storage to prevent showing modal again
    function onSubscribe() {
        showModal = false
        localStorage.setItem('subscribed', true)
    }
    function onDismiss() {
        showModal = false
        console.log('dismissed')
    }
    console.log('dd', data.post)

    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function our(str) {
        if (str === 'free') {
            return 'Our'
        }
        return 'Our ' + capitalize(str)
    }
</script>

<!-- In your Svelte component -->
<svelte:head>
    <!-- Set the page title -->
    <title>{data.post.title} | Investomation</title>

    <!-- Set the meta description -->
    <meta name="description" content="{shorten(data.post.summary)}" />

    <!-- Set the canonical URL -->
    <!-- <link rel="canonical" href="https://www.investomation.com/blog/{data.post.slug}" /> -->

    <!-- Set the meta keywords -->
    <meta name="keywords" content="real estate, research and analytics, real estate comps, turnkey real estate, passive investing" />

    <!-- Open Graph meta tags -->
    <meta property="og:title" content="{data.post.title} | Investomation" />
    <meta property="og:description" content="{shorten(data.post.summary)}" />
    <meta property="og:image" content="{host + data.post.media}" />
    <meta property="og:type" content="article" />
    <meta property="og:url" content="https://www.investomation.com/blog/{data.post.slug}" />
    <meta property="og:site_name" content="Investomation" />
    <meta property="og:locale" content="en_US" />
    <meta property="article:published_time" content="{data.post.created}" />

    <!-- Twitter meta tags -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@investomation" />
    <meta name="twitter:creator" content="@investomation" />
    <meta name="twitter:title" content="{data.post.title} | Investomation" />
    <meta name="twitter:description" content="{shorten(data.post.summary)}" />
    <meta name="twitter:image" content="{host + data.post.media}" />
    <meta name="twitter:image:alt" content="{data.post.title}" />
    <meta name="twitter:url" content="https://www.investomation.com/blog/{data.post.slug}" />

</svelte:head>

<svelte:window bind:scrollY={y} />

<!-- if we read more than 50% of the post, ask to subscribe -->
<Modal showModal={y > 500 && showModal} on:close={onDismiss} >
    <Subscribe surveyName="newsletter-blog" omitName buttonName="Subscribe" on:subscribe={onSubscribe} >
        <div slot="thankyou">
            <h1>Thank you for subscribing!</h1>
            <p>We'll let you know when we publish new content. You can close this modal by clicking outside of it.</p>
        </div>
        <div slot="description">
            <h1>Subscribe to our Newsletter</h1>
            It looks like you're enjoying this blog post. Would you like to subscribe to our newsletter
            for more content like this and free market analytics?
        </div>
    </Subscribe>
</Modal>

<div id="blog-post">
    <div class="blog-content">
        <Link class="blog_back" to="/blog" on:click={() => selectBlog(null)}><Icon path={mdiChevronLeft} /> back to main blog page</Link>
        {@html data.post.content}
    </div>

    {#if data.post.paywall_tier && !data.post.paywall_content}
        <div class="paywall-container">
            {#if !globalThis.user}
                <Icon path={mdiLock} size="32px" />
                <span>This article has additional content available to registered users.</span>
                <Button class="primary-color" on:click={() => navigate('/signup')}>Sign Up</Button>
            {:else}
                <Icon path={mdiLock} size="32px" />
                <span>This article has additional content available to our {data.post.paywall_tier} users.</span>
                <Button class="primary-color" on:click={() => navigate('/upgrade')}>Upgrade</Button>
            {/if}
        </div>
    {:else if data.post.paywall_content}
        <div class="paywall-content">
            <h2>Exclusive Content for {our(data.post.paywall_tier)} Users</h2>
            {@html data.post.paywall_content}
        </div>
    {/if}

    <Divider />
    {#if data.post.blogtag}
        <!-- This part will only be rendered on the client side, not SSR -->
        {#each data.post.blogtag as tag}
            <Button class="blog-tag" text on:click={() => {
                navigate(`/blog/tag/${tag.slug}`)
            }}>#{tag.name}</Button>
        {/each}
    {/if}

    <div class="blog-post-timestamp">
        <Icon path={mdiCalendar} />
        {toDate(data.post.created)}
    </div>

    {#if data.post.user && data.post.user.nickname}
        <div class="blog-author">
            <div class="blog-author-image">
                <Link to="/profile/{data.post.user.id}">
                    <img src="{fetchAvatar(data.post.user.avatar, 120)}" alt="author" />
                </Link>
            </div>
            <div class="blog-author-bio">
                <h4>{data.post.user.nickname}</h4>
                <p>{data.post.user.bio}</p>
            </div>
        </div>
    {/if}

    {#if !globalThis.user && !isSubscribed()}
        <div class="subscribe">
            <Subscribe surveyName="newsletter-blog" omitName on:subscribe={onSubscribe}>
                <div slot="thankyou">
                    <h2>Thank you for subscribing!</h2>
                    <p>We'll let you know when we publish new content.</p>
                </div>
                <div slot="description">
                    <h2>Subscribe to our Newsletter</h2>
                    Enjoyed this post? Subscribe to our newsletter for more content like this and free real estate market analytics.
                </div>
            </Subscribe>
        </div>
    {/if}
    
    {#if data.comments && data.comments.length}
        <h2 class="comments-title">Comments</h2>
        {#each data.comments as comment}
            <div class="blog-comment">
                <div class="blog-comment-poster-image">
                    <Link to="/profile/{comment.user.id}">
                        <img src="{fetchAvatar(comment.user.avatar, 120)}" alt="" />
                    </Link>
                </div>
                <div class="blog-comment-container">
                    <Divider />
                    <div class="blog-comment-poster">
                        <Link to="/profile/{comment.user.id}">
                            <h4>{comment.user.nickname}</h4>
                        </Link>
                        <span>on {toDate(comment.created)}</span>
                    </div>
                    <div class="blog-comment-text">{comment.content}</div>
                </div>
            </div>
        {/each}
    {:else}
        <h2 class="comments-title">Be the first to comment...</h2>
    {/if}

    {#if globalThis.user}
        <Textarea class="comment-field" placeholder="Your thoughts..." bind:value={commentText} />
        <Button class="submit-button primary-color" on:click={() => {
            fetch(`/api/blog/${data.post.id}/comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: globalThis.user.id,
                    blog_id: data.post.id,
                    content: commentText
                })
            }).then(() => {
                // refresh the page
                let comment = {
                    user: globalThis.user,
                    content: commentText,
                    created: Date.now()
                }
                commentText = null;
                // return list.push(comment)
            })
        }}>Post Comment</Button>
    {:else}
        <Button class="submit-button">Login to comment</Button>
    {/if}
</div>

{#if related.length}
    <div id="related-posts">
        <h2>Related Posts</h2>
        {#each related as item}
            <div class="single-item">
                <Paywall tier={item.tier} verticalOffset="65%">
                    <Card flat tile slot="teaser" class="teaser">
                        <div class="preview-image">
                            <img src="{item.media}" alt="{item.media}" />
                        </div>
                        <CardTitle>{item.title}</CardTitle>
                    </Card>
                    <Link to="/blog/{item.slug}" slot="main" on:click={() => selectBlog(item.slug)}>
                        <Card flat tile>
                            <div class="preview-image">
                                <img src="{item.media}" alt="{item.media}" />
                                <div class="preview-date">
                                    <Icon path={mdiCalendar} size="18px" />
                                    {toDate(item.created)}
                                </div>
                                <CardTitle class="card-title">{item.title}</CardTitle>
                            </div>
                            <CardText>{item.summary}</CardText>
                        </Card>
                    </Link>
                </Paywall>
            </div>
        {/each}
    </div>
{/if}

<style type="text/scss">
    @import 'material-theme';

    @media only screen and (max-width: 600px) {
        #blog-post {
            padding: 0 16px;
            min-width: 100%;
            margin: 0 !important;

            :global(.blog-author-image) {
                display: none !important;
            }
        }
 
        .single-item {
            min-width: 100%;
        }
    }

    .youtube {
        text-decoration: none;
        color: #4caf50;
        padding-left: 30px;
        padding-top: 20px;
        margin-bottom: -10px;
        display: block;
    }

    .filter {
        padding: 16px;

        :global(.filter-name) {
            padding-right: 24px;
            color: #999;
        }

        :global(.s-chip) {
            margin: 5px;
            text-transform: capitalize;
            cursor: pointer;

            &:hover {
                background: $primary-color;
            }
        }
    }

    .single-item {
        width: 300px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ddd;
        float: left;

        :global(a) {
            text-decoration: none;
            color: black;
        }

        &:hover {
            background-color: $secondary-bg-color;
            cursor: pointer;

            :global(.s-card) {
                background-color: $secondary-bg-color;
            }
        }

        & :global(.teaser) {
            filter: blur(2px);
        }

        & :global(.author) {
            font-weight: 700;
            &:hover {
                color: $primary-color;
            }
        }

        & :global(.s-card-subtitle) {
            padding: 0 16px;
            font-size: 0.7rem;
        }

        & :global(.s-card-title) {
            text-transform: uppercase;
            font-size: 1rem;
            padding: 0 16px;
            font-weight: 700;
            word-break: break-word;
            line-height: 1;
        }

        & :global(.s-card-text) {
            height: 150px;
            overflow: hidden;
            font-size: 0.8rem;
        }

        & :global(.s-btn) {
            font-size: 0.7rem;

            & :global(span) {
                font-weight: 700;
                text-transform: capitalize !important;
            }

            &:before {
                display: none;
            }
            &:hover {
                color: $primary-color;
                background: transparent;
            }
        }

        & :global(.s-divider) {
            margin: 0 16px;
            margin-top: 8px;
        }
    }
    .preview-image {
        margin: 16px;
        margin-bottom: 0;
        cursor: pointer;
        border: 1px solid $secondary-bg-color; /* subtle border for images w/ white bg */
        height: 170px;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        & :global(.card-title) {
            position: absolute;
            top: 0;
            background: rgba(255, 255, 255, 0.7);
            padding: 5px;
        }

        & .preview-date {
            position: absolute;
            bottom: 0;
            right: 0;
            background: rgba(255,255,255,0.7);
            padding: 0 5px;
            font-size: 0.8rem;
        }
    }

    #blog-post {
        float: left;
        width: 70%;
        max-width: 800px;
        margin: 16px 64px;

        :global(.blog_back) {
            text-decoration: none;
            color: #777;
            text-transform: uppercase;
        }

        :global(ol) {
            padding-bottom: 16px;

            :global(li::marker) {
                font-weight: 700;
                color: #4caf50;
            }
        }

        :global(a) {
            color: #4caf50;

            &:hover {
                color: #6ccf70;
            }
        }

        :global(.blog-content) {
            :global(h1) {
                text-transform: uppercase;
                padding-bottom: 16px;
                font-weight: 700;
                color: #666;
            }

            :global(img) {
                width: 100%;
                padding-bottom: 10px;
            }

            // shrinks first image right under the title to 300px (putting some text above the fold)
            :global(h1 + p img) {
                max-height: 300px;
                object-fit: cover;
                transition: max-height 0.5s ease-in-out;

                &:hover {
                    max-height: 600px;
                    transition: max-height 0.5s ease-in-out;
                }
            }

            // zoom on hover for images located in screenshots directory
            :global(img[src^="/images/screenshots"]) {
            // :global(img:not(h1 + p img)) {
                max-width: 100%;
                transition: transform 0.5s ease-in-out;
                position: relative;
                z-index: 2;

                &:hover {
                    transform: scale(1.25);
                    transition: transform 0.5s ease-in-out;
                }
            }

            :global(blockquote) {
                border-left: 5px solid #4caf50;
                background: #f6f6f6;
                padding: 10px;
                margin-left: 0;
                font-style: italic;
                font-size: 1rem;
            }

            :global(blockquote p:last-child) {
                margin-bottom: 0;
            }

            :global(pre code) {
                background: #f6f6f6;
                padding: 10px;
                margin-bottom: 10px;
                display: block;
                overflow: auto;
                font-size: 0.8rem;
                color: #333;
            }

            :global(code) {
                background: aliceblue;
                padding: 5px;
                font-size: 0.8rem;
                color: green;
            }

            :global(ul) {
                margin-bottom: 16px;
            }
        }

        .paywall-container {
            justify-content: space-between;
            margin: 10px;

            span {
                align-self: center;
            }
        }

        .paywall-container, .paywall-content {
            background: #f5f5f5;
            border: 1px solid #ccc;
            border-radius: 3px;
            padding: 10px;
        }

        :global(.blog-tag) {
            font-size: 0.7rem;

            & :global(span) {
                font-weight: 700;
                text-transform: capitalize !important;
            }

            &:before {
                display: none;
            }
            &:hover {
                color: $primary-color;
                background: transparent;
            }
        }

        :global(.blog-post-timestamp) {
            float: right;
            padding: 5px;
        }

        :global(.blog-author) {
            background-color: $secondary-bg-color;
            border-radius: 10px;
            display: flex;
            margin: 20px 0;

            :global(a) {
                text-decoration: none;
                color: black;
            }

            :global(.blog-author-image) {
                @include profile-img;
                margin: 20px;
                height: 120px;
            }

            :global(.blog-author-bio) {
                width: calc(100% - 120px);
                padding: 20px;

                :global(h4) {
                    font-family: $body-font-family;
                    font-size: 1.15rem;
                    font-weight: 700;
                }

                :global(p) {
                    font-style: italic;
                    font-size: 0.9rem;
                }
            }
        }

        .subscribe {
            padding-top: 0px;
            padding-bottom: 20px;
        }

        :global(.comments-title) {
            text-transform: uppercase;
            font-size: 1.2rem !important;
        }

        :global(.blog-comment) {
            :global(.blog-comment-poster-image) {
                @include profile-img;
                vertical-align: top;
                margin: 10px;
                margin-top: 30px;
                margin-left: 0;
            }

            :global(.blog-comment-container) {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 130px);
                padding: 5px;

                :global(.blog-comment-poster) {
                    :global(a) {
                        text-decoration: none;
                    }

                    :global(h4) {
                        font-size: 1rem;
                        line-height: 2rem;
                        font-weight: 700;
                        display: inline;
                        color: black;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    :global(span) {
                        font-size: 0.8rem;
                    }
                }

                :global(.blog-comment-text) {
                    font-size: 0.9rem;
                    white-space: break-spaces;
                }
            }

        }

        :global(.comment-field) {
            margin-top: 20px;
        }

        :global(.submit-button) {
            margin-top: 16px;
            margin-bottom: 100px;
        }
    }
</style>