import App from './App.svelte';

const app = new App({
	// target: document.body,
	target: document.getElementById('app'),
	hydrate: true,
	props: {
		user: '<$= user $>',
	}
});

export default app