<svelte:head>
    <script src="https://js.stripe.com/v3/" on:load={onStripeLoad}></script>
</svelte:head>

<script>
    import { Button, Icon, Tooltip } from "svelte-materialify/src";
    import FormField from '@smui/form-field';
    import Pricing from "../components/landing/Pricing.svelte";
    import GreenWave from '../components/landing/GreenWave.svelte';

    let stripe;
    export let data;
    let sk
    if (data) {
        sk = data.sk
    } else {
        fetch('/sk').then(r => r.json()).then(j => {
            sk = j.sk
        })
    }

    let proPlan;
    let businessPlan;
    let tier = global.user.tier || 'free';
    let tierMap = {
        free: 'Explorer',
        professional: 'Trailblazer',
        business: 'Architect'
    }
    async function onStripeLoad() {
        stripe = Stripe(sk);
        console.log('stripe loaded', stripe, apiKey)
        fetch('/purchase_ids').then(r => r.json()).then(j => {
            proPlan = j.proPlan
            businessPlan = j.businessPlan
        })
    }

    function freeSubscription() {
        // downgrade
        fetch(`/tier`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tier: 'free' })
        }).then(reload);
    }
    
    function proSubscription() {
        if (globalThis.user.tier !== 'free') {
            // we already have user payment info on file
            fetch(`/tier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tier: 'professional' })
            }).then(reload);
        } else {
            stripe.redirectToCheckout({
                customerEmail: globalThis.user.email,
                items: [{ plan: proPlan, quantity: 1 }],
                successUrl: location.origin + '/thankyou/upgrade',
                cancelUrl: location.origin + '/upgrade'
            }).then(reload);
        }
    }

    function businessSubscription() {
        if (globalThis.user.tier !== 'free') {
            // we already have user payment info on file
            fetch(`/tier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tier: 'business' })
            }).then(reload);
        } else {
            stripe.redirectToCheckout({
                customerEmail: globalThis.user.email,
                items: [{ plan: businessPlan, quantity: 1 }],
                successUrl: location.origin + '/thankyou/upgrade',
                cancelUrl: location.origin + '/upgrade'
            }).then(reload);
        }
    }

    function reload() {
        globalThis.location.href = '/upgrade'
    }

    function selectTier(e) {
        if (e.detail === 'Explorer') {
            tier = 'free'
        } else if (e.detail === 'Trailblazer') {
            tier = 'professional'
        } else {
            tier = 'business'
        }
    }

    function changePlan() {
        if (tier === 'free') {
            freeSubscription()
        } else if (tier === 'professional') {
            proSubscription()
        } else {
            businessSubscription()
        }
    }
</script>

<div class="container">
    <Pricing selected={tierMap[tier]} on:select={selectTier} />
    <div class="submit-btn">
        <p>
            If you want enterprise support, block-level granularity, training of our models on your own metrics/data, or a whitelabel integration with your own platform, contact us at <a href="mailto:info@investomation.com">info@investomation.com</a>.
        </p>
        {#if tier === global.user.tier}
            <p>
                You are already subscribed to the <strong>{tierMap[tier]}</strong> plan.
            </p>
        {:else}
            <Button text on:click={changePlan} class="primary-color" >
                Update Plan
            </Button>
        {/if}
    </div>
    <div class="bottom">
        <GreenWave bottomWave={false} />
    </div>
</div>

<style type="text/scss">
    @import 'material-theme';

    .container {
        :global(.tier) {
            position: relative;
            z-index: 1;
        }
    }

    p {
        max-width: 80ch;
    }

    .left-col {
        text-align: left;
    }


    .highlight {
        color: $primary-color;
        font-weight: 700;
        text-transform: capitalize;
    }

    .radio-group {
        max-width: 800px;
        margin: auto;
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 0px;
        position: relative;
        z-index: 1;
    }

    .submit-btn {
        p {
            margin: 10px;
            font-size: 0.8rem;
            max-width: 800px;
        }

        padding-bottom: 20px;
        text-align: center;
        position: relative;
        z-index: 1;
        width: 800px;
        margin: auto;

        :global(.disabled) {
            background-color: #ccc !important;
            pointer-events: none;
        }
    }

    .bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
</style>
