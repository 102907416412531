<script>
    import {
        Icon,
        Chip,
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        Divider,
        CardActions,
        Button,
        ProgressCircular,
    } from 'svelte-materialify/src';
    import { metricIcons } from '../utils';
    import PersonalAPI from './personal.svx'
    import PredictorAPI from './demographics.svx'
    import Toc from 'svelte-toc'

    function pretty(string) {
        // capitalize
        string = string.charAt(0).toUpperCase() + string.slice(1)
        return string
    }

    function sentence(string) {
        string = string.charAt(0).toUpperCase() + string.slice(1)
        return string.slice(-1) === '.' ? string : string + '.'
    }

    async function fetchPredictorEndpoints() {
        return fetch("/api/predict/metrics").then(r => r.json())
    }
    let predictorMetrics = fetchPredictorEndpoints()
    let reloaded = {}
    function trackme(node) {
        console.log('reload')
        reloaded = {}
    }
</script>


{#key reloaded}
    <div class="menu">
        <Toc title="Navigation" keepActiveTocItemInView={false} activeTopOffset={0} />
    </div>
{/key}

<div class="container">
    <h1 class="toc-exclude">Application Programming Interface (API)</h1>
    All API endpoints are accessible via <code>https://investomation.com/api/</code>.<br>
    <main>
        <h1>Personal Data</h1>
        <Card outlined class="info-card">
            <CardText>
                <PersonalAPI/>
            </CardText>
        </Card>
        {#await predictorMetrics}
            <div class="center">
                <ProgressCircular  size={70} width={7} indeterminate color="primary" />
            </div>
        {:then metrics}
            <h1>Demographics Analysis</h1>
            <Card outlined class="info-card">
                <CardText>
                    <PredictorAPI/>
                    {#each Object.entries(metrics) as [section, data]}
                        <div class="subsection">
                            <h3 use:trackme>
                                <Icon path={metricIcons[section]} />
                                {pretty(section)}
                            </h3>
                            <div>
                                {sentence(data.info)}
                                There are {Object.keys(data.children).length} metrics in total under this category:
                            </div>
                        </div>
                        {#each Object.entries(data.children) as [metric, endpointData]}
                            {#if endpointData.children}
                                <div class="subsection">
                                    <h3 use:trackme>
                                        <Icon path={metricIcons[metric]} />
                                        {pretty(metric)}
                                    </h3>
                                    <div>
                                        {sentence(endpointData.info)}
                                        There are {Object.keys(endpointData.children).length} metrics in total under this category:
                                    </div>
                                </div>
                                {#each Object.entries(endpointData.children) as [nestedMetric, nestedEndpointData]}
                                    <div class="endpoint">
                                        <Chip label>{section}:{metric}:{nestedMetric}</Chip>
                                        <div>
                                            Type: <code>{nestedEndpointData.type}</code>
                                        </div>
                                        <div class="endpoint-desc">
                                            {#if nestedEndpointData.categories}
                                                {sentence(nestedEndpointData.info + ` (categories: ${nestedEndpointData.categories.join(', ')})`)}
                                            {:else}
                                                {sentence(nestedEndpointData.info + ` (units: ${nestedEndpointData.unit})`)}
                                            {/if}
                                            <br>
                                            Example Usage: <code>/api/predict/2022/info/zip/90210?</code><strong><code>{section}:{metric}:{nestedMetric}=1</code></strong>
                                        </div>
                                    </div>
                                {/each}
                            {:else}
                                <div class="endpoint">
                                    <Chip label>{section}:{metric}</Chip>
                                    <div>
                                        Type: <code>{endpointData.type}</code>
                                    </div>
                                    <div class="endpoint-desc">
                                        {#if endpointData.categories}
                                            {sentence(endpointData.info + ` (categories: ${endpointData.categories.join(', ')})`)}
                                        {:else}
                                            {sentence(endpointData.info + ` (units: ${endpointData.unit})`)}
                                        {/if}
                                        <br>
                                        Example Usage: <code>/api/predict/2022/info/zip/90210?</code><strong><code>{section}:{metric}=1</code></strong>
                                    </div>
                                </div>
                            {/if}
                        {/each}
                    {/each}
                </CardText>
            </Card>
        {/await}
    </main>
</div>


<style type="text/scss">
    .container {
        padding: 20px;
        /* display: flex; */

        :global(main .info-card) {
            width: calc(100vw - 400px);
        }
    }

    @media only screen and (max-width: 1000px) {
        .container {
            padding: 8px;
        }

        :global(main .info-card) {
            width: 100% !important;
        }
    }

    main > h1 {
        color: #666;
        padding-top: 20px;
    }

    .menu {
        position: fixed;
        margin-right: 20px;
        /* margin: 20px; */
        /* top: 100px; */
        right: 0px;
        z-index: 5;
    }

    .padding {
        padding: 10px;
    }

    .endpoint {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .endpoint-desc {
        padding-top: 5px;
    }

    :global(h3 > i) {
        padding-bottom: 5px;
    }

    :global(aside.toc > nav) {
        overflow-x: hidden;
    }

    :global(aside.toc > nav > ul > li:hover) {
        color: #4caf50;
    }

    :global(aside.toc > nav > ul > li.active) {
        color: #000;
        background: #4caf50;
        padding: 5px;
    }

    :global(code) {
        font-family: 'Courier New', Courier, monospace;
        background-color: transparent;
        padding: 0;
        font-size: 0.9rem;
        font-weight: 400;
        color: #4caf50;
    }

    :global(strong code) {
        background: #4caf50;
        color: white;
        font-weight: 900;
        padding: 0 2px;
    }

    .hl {
        color: #4caf50;
    }
</style>
