<div class="footer">
    Investomation © 2024, <a href="/terms">Terms & Conditions</a>
</div>

<style>
    .footer {
        position: fixed;
        padding: 5px;
        bottom: 0px;
        font-size: 0.7rem;
        font-family: "Lato", "Helvetica","Arial",sans-serif;
    }
</style>