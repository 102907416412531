<div class="error-page">
    <h1>This page does not exist</h1>
    <p>This is not a valid page, but we logged this error and will look into it later.</p>
    <img src="/static/coming_soon.gif" alt="Something broke">
</div>

<style>
    .error-page {
        padding: 10px;
        padding-top: 50px;
        max-width: 600px;
        margin: auto;
    }

    h1 {
        text-transform: uppercase;
    }

    img {
        max-width: 100%;
    }
</style>