<script>
    // import Textfield from '@smui/textfield';
    // import Button, { Label } from '@smui/button';
    import Icon from '@smui/textfield/icon';
    import HelperText from '@smui/textfield/helper-text';
    import { TextField, Button } from 'svelte-materialify/src';
    // import { RevoGrid } from "@revolist/svelte-datagrid";
    import CashflowAnalysis from '../components/CashflowAnalysis.svelte';
    import { fipsToStateCode } from '../../../common/constants';
	// import { defineCustomElements } from "@revolist/revogrid/loader";

    /*
        Idea:
        - user enters data
        - should allow excel-like formula input into user fields
        - can run different scenarios -> duplicate (differences get highlighted)
        - graph generated at the bottom
        - expands to more itemized/advanced calculator
        - saves to marker
        - has to work well on cell phone

        fields:
        - address / auto-fill / save
        
        - purchase price
        - downpayment
        - purchase fees (scale by price)
        - interest (auto-fills)
        - advanced: change loan type (should give brief overview of each)

        - rehab budget
        - advanced: itemize budget

        future improvements:
        - gross income itemization
          - pop-up grid
        - better loan terms itemization
        - automatic rent estimate (and other location-based recommendations)
        - automatic analytics by location/address
        - better extrapolation
          - scenario comparison (already exists)
          - annual extrapolation
     */

     let address = ''
</script>

<div class="container">
    <h1>Napkin Analysis</h1>
    <CashflowAnalysis address={address} />
</div>
<div class="hint">
    If you put in the address or state below, the calculator will adjust property tax and insurance estimates to your location.
</div>
<div class="save-controls">
    <TextField bind:value={address}>Address</TextField>
    {#if globalThis.user}
        <Button class="primary-color">Save</Button>
    {:else}
        <div class="login-to-save">
            Login to save your analysis or load existing one.
        </div>
    {/if}
</div>

<style type="text/scss">
    .container {
        height: 70vh;
        width: 100%;
        margin: 32px 64px;

        h1 {
            text-transform: uppercase;
        }

        :global(.long-text) {
            width: 500px;
        }
    }

    .hint {
        padding: 30px;
        color: #666;
        font-size: 0.9rem;
    }

    .save-controls {
        position: fixed;
        bottom: 0;
        display: flex;
        padding: 20px;
        width: 100%;
        max-width: 1000px;

        &> :global(*) {
            margin: 10px;
        }

        .login-to-save {
            margin: auto;
            margin-bottom: 30px;
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            margin: 0px;

            h1 {
                padding: 0 20px;
            }
        }
    }
</style>