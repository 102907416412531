<script>
    import { createEventDispatcher } from "svelte";
    import { fade } from 'svelte/transition'
    export let showCloseButton = false;
    export let showModal;
  
    const dispatch = createEventDispatcher();
    function handleClose() {
      // emit a custom "close" event when the user clicks the X button or the background pane
      const event = new CustomEvent("close");
      dispatchEvent(event);
      showModal = false
      dispatch("close");
    }
</script>

<style>
    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        max-width: 90%;
        padding: 20px;
        z-index: 1000;
    }

    .modal-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1.2rem;
        color: gray;
        cursor: pointer;
    }
</style>

{#if showModal && $$slots.default}
    <div
        class="modal-background"
        on:click={handleClose}
        transition:fade={{ delay: 250, duration: 300 }}
    >
        <div class="modal">
        {#if showCloseButton}
            <span class="close-button" on:click={handleClose}>×</span>
        {/if}
        <div on:click={e => e.stopPropagation()}>
            <slot />
        </div>
        </div>
    </div>
{/if}
