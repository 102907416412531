<script>
    import {
        TextField,
        Textarea,
        Select,
        Button,
    } from 'svelte-materialify/src';

    let items = [
        { name: 'Bug', value: 'Bug' },
        { name: 'Question', value: 'Question' },
        { name: 'Feature Request', value: 'Feature Request' },
        { name: 'Other', value: 'Other' },
    ]
</script>

<div class="container">

    <h1>Support</h1>

    <p>
        What seems to be the problem?
    </p>

    <form action="/support" method="post">
        <TextField>Subject</TextField>
        <Select {items}>Category</Select>
        <Textarea>Details</Textarea>
        <Button type="submit">Submit</Button>
    </form>
    <div class="padded">
        If you prefer to just send us an email, you can do so at <a href="mailto:support@invesotmation.com">support@investomation.com</a>.
    </div>
</div>

<style>
    h1 {
        text-transform: uppercase;
    }

    .container {
        width: 600px;
        margin: auto;
        clear: both;
    }

    :global(.profile) {
        margin: 10px;
        width: 250px;
        height: 340px;
        float: left;
    }

    .padded {
        margin-top: 40px;
        font-size: 0.9rem;
    }
</style>