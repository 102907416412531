// UI elements

// default rank scheme, red = bad, green = good
export const ranks = [
    "#a50026",
    "#d73027",
    "#f46d43",
    "#fdae61",
    "#fee08b",
    "#d9ef8b",
    "#a6d96a",
    "#66bd63",
    "#1a9850",
    "#006837"
];

// 12 categories (used by ranked categories w/ more than 10 groups), red = bad, green = good
export const ranks2 = [
    "#a50026",
    "#b73027",
    "#d76037",
    "#f48d43",
    "#fdae61",
    "#fee08b",
    "#ffffbf",
    "#d9ef8b",
    "#a6d96a",
    "#66bd63",
    "#1a9850",
    "#007847",
    "#006837"
];

// gradient between 2 categories, blue = first, red = second
export const categoricalGradient = [
    "#1E2EDE",
    "#323ED2",
    "#4040E5",
    "#4954E5",
    "#6272EB",
    "#8092EB",
    "#A5ABF2",
    "#B5BBF2",
    "#C2C5F8",
    "#D2D5F8",
    "#F8D1D1",
    "#F8C1C1",
    "#F2B3B3",
    "#F2A3A3",
    "#EB9090",
    "#EB7676",
    "#E55959",
    "#E54040",
    "#D23232",
    "#DE1A1A",
];
// export const categoricalGradient = [
//     "#FFFFFF","#D2D5F8","#A5ABF2","#7882EB","#4B58E5","#1E2EDE",
//     "#F8D1D1","#F8D1F8","#A58BC2","#7872DB","#4B48D5","#332CC8",
//     "#F2A3A3","#F2A3B3","#A56BB2","#8862CB","#5B38C5","#492AB2",
//     "#EB7676","#D96C80","#B54B82","#8252AB","#6B35A5","#5E279D",
//     "#E54848","#D93C50","#B33E66","#90318B","#792891","#732587",
//     "#DE1A1A","#C91C30","#B31E46","#9E215B","#892371","#732573",
// ];

// more than 2 categories
export const categories = [
    '#ffffff', // no data
    '#4169E1',
    '#DE1A1A',
    '#32DD32',
    '#FFD700',
    '#40E0D0',
    '#FF8C00',
    '#66a11e',
    '#8071fa',
    '#f056cd',
    '#CFF27E',
    '#8f9492',
    '#e7298a',
    '#6495ED',
    '#F2D398',
    '#3CB371',
    '#FF6347',
    '#fafa17',
    '#DAA520',
    '#4c6c9e',
    '#B118C8',
    '#A1E5AB',
    '#6A041D',
    '#2F4F4F',
    '#eb3d77',
    '#9ce8f7',
    '#8570b3',
    '#869a47',
    '#b82800',
    '#eeb1f6',
    '#842e38',
    '#bbcaf7',
    '#CA807D',
    '#aaa3db',
    '#f5eeb7',
    '#6051da',
    '#333333',
    '#00747D',
    '#d8030e',
    '#53FF45',
]

export const Key = {
    ENTER: 13,
    ESC: 27,
    UP: 38,
    DOWN: 40,
}

export const fipsToStateCode = {
    "01": "AL",
    "02": "AK",
    "04": "AZ",
    "05": "AR",
    "06": "CA",
    "08": "CO",
    "09": "CT",
    "10": "DE",
    "11": "DC",
    "12": "FL",
    "13": "GA",
    "15": "HI",
    "16": "ID",
    "17": "IL",
    "18": "IN",
    "19": "IA",
    "20": "KS",
    "21": "KY",
    "22": "LA",
    "23": "ME",
    "24": "MD",
    "25": "MA",
    "26": "MI",
    "27": "MN",
    "28": "MS",
    "29": "MO",
    "30": "MT",
    "31": "NE",
    "32": "NV",
    "33": "NH",
    "34": "NJ",
    "35": "NM",
    "36": "NY",
    "37": "NC",
    "38": "ND",
    "39": "OH",
    "40": "OK",
    "41": "OR",
    "42": "PA",
    "44": "RI",
    "45": "SC",
    "46": "SD",
    "47": "TN",
    "48": "TX",
    "49": "UT",
    "50": "VT",
    "51": "VA",
    "53": "WA",
    "54": "WV",
    "55": "WI",
    "56": "WY",
    "60": "AS",
    "66": "GU",
    "69": "MP",
    "72": "PR",
    "78": "VI"
}

export const metricIcons = {
    'income': 'attach_money',
    'population': 'family_restroom',
    'migration': 'language',
    'employment': 'card_travel',
    'housing': 'home',
    'rent': 'night_shelter',
    'construction': 'construction',
    'sales': 'real_estate_agent',
    'business': 'factory',
    'industries': 'precision_manufacturing',
    'transportation': 'commute',
    'education': 'school',
    'government': 'account_balance',
    'taxes': 'percent',
    'crime': 'local_police',
    'environment': 'landscape',
    'land': 'landscape',
    'fire': 'local_fire_department',
    'flood': 'water_drop',
    'heat': 'sunny',
    'wind': 'air',
}

// this can be used for both granules and regions
export const regionsBySize = {
    'tract': 2,
    'zip': 3,
    'county': 4,
    'msa': 5,
    'csa': 6,
    'state': 7,
    'country': 8,
}