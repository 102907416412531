<svelte:head>
	<script src="/static/marked.js" on:load={onMarkedLoad}></script>
</svelte:head>

<script>
    import { createEventDispatcher } from 'svelte'
    import { navigate, Link } from '../components/routing'
    import Aggregator from '../components/Aggregator.svelte'
    import { externalPromise, fetchAvatar, apiFetch } from '../../utils'
    import {
        Chip,
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        Divider,
        CardActions,
        Button,
    } from 'svelte-materialify/src';
    import Icon from 'svelte-materialify/src/components/Icon';
    import { mdiCalendar, mdiClose, mdiChevronLeft } from '@mdi/js';
    import Textarea from 'svelte-materialify/src/components/Textarea';
    import Paywall from '../components/Paywall.svelte';

    let tags
    apiFetch("/api/blog_tag").then(r => r.json()).then(list => {
        tags = list.items
    })

    export let preloadedData = null

    export let selectedItem;
    if (preloadedData && preloadedData.selectedItem) {
        selectedItem = preloadedData.selectedItem
    }

    export let selectedTag;
    $: selectedTag

    let commentText;
    async function getComments(id) {
        if (!id) return [];

        return fetch(`/api/blog/${id}/comment`).then((r) => r.json()).then(list => list.items)
    }
    $: blogComments = getComments(selectedItem)

    const toDate = (d) => new Date(d).toLocaleDateString(undefined, { timeZone: 'UTC' })

    let markedLoaded = externalPromise()
    function onMarkedLoad() {
        markedLoaded.resolve()
    }

    function getMeta(item) {
        return {
            title: item.title,
            description: item.summary,
            image: item.media,
            url: `https://www.investomation.com/blog/${item.slug}`,
            type: 'article'
        }
    }

    const dispatch = createEventDispatcher()
    function selectBlog(slug) {
        dispatch('openBlog', slug)
    }
</script>

<a class="youtube" href="https://www.youtube.com/@investomation" target="_blank">
    See our YouTube channel for more educational content
</a>
<Aggregator
    name="Software + Real Estate Blog"
    relatedName="Related Posts"
    endpoint="{selectedTag ? `/api/blog_tag/${selectedTag}` : '/api/blog'}"
    singleEndpoint="/api/blog/:id"
    arrayName="{selectedTag ? 'blog' : 'items'}"
    selectedItem={selectedItem}
    meta={getMeta}
    preloadedData={preloadedData}
    sortFn={(a, b) => new Date(a.created) - new Date(b.created)}
>
    <div slot="filter" class="filter">
        {#if tags}
            <span class="filter-name">Tags</span>
            {#each tags as tag}
                <Chip class="{selectedTag === tag.slug ? 'primary-color' : ''}" on:click={() => {
                    navigate(`/blog/tag/${tag.slug}`)
                }}>{tag.name}</Chip>
            {/each}
            {#if selectedTag}
                <Button icon  on:click={() => {
                    navigate('/blog')
                }}>
                    <Icon path={mdiClose} />
                </Button>
            {/if}
        {/if}
    </div>
    <div let:prop={item} slot="single-item" class="single-item">
        <Paywall tier={item.tier} verticalOffset="65%">
            <Card flat tile slot="teaser" class="teaser">
                <div class="preview-image">
                    <img src="{item.media}" alt="{item.media}" />
                </div>
                {#if !selectedTag}
                    <!-- TODO: fix, we don't fetch these with tag filter yet -->
                    <CardSubtitle>
                        <span class="author" on:click={() => {
                            if (item.user) {
                                navigate(`/profile/${item.user.id}`)
                            }
                        }}>{item.user ? item.user.nickname : 'Unknown Author'}</span> on {toDate(item.created)}
                    </CardSubtitle>
                {/if}
                <CardTitle>{item.title}</CardTitle>
            </Card>
            <Link to="/blog/{item.slug}" slot="main" on:click={() => selectBlog(item.slug)} >
                <Card flat tile>
                    <div class="preview-image">
                        <img src="{item.media}" loading="lazy" alt="{item.media}" />
                    </div>
                    {#if !selectedTag}
                        <!-- TODO: fix, we don't fetch these with tag filter yet -->
                        <CardSubtitle>
                            <span class="author" on:click={() => {
                                if (item.user) {
                                    navigate(`/profile/${item.user.id}`)
                                }
                            }}>{item.user ? item.user.nickname : 'Unknown Author'}</span> on {toDate(item.created)}
                        </CardSubtitle>
                    {:else}
                        <CardSubtitle>
                            {toDate(item.created)}
                        </CardSubtitle>                    
                    {/if}
                    <CardTitle>{item.title}</CardTitle>
                    <CardText>{item.summary}</CardText>
                    <Divider />
                    <CardActions>
                        {#if selectedTag && tags}
                            <Button text>#{tags.find((t) => selectedTag === t.slug).name}</Button>
                        {:else}
                            {#each item.blogtag as tag}
                                <Button text on:click={(e) => {
                                    navigate(`/blog/tag/${tag.slug}`)
                                }}>#{tag.name}</Button>
                            {/each}
                        {/if}
                    </CardActions>
                </Card>
            </Link>
        </Paywall>
    </div>

    <div slot="opened-item" class="blog-post opened-item" let:prop={postInfo}>
        <div class="blog-content">
            <Link class="blog_back" to="/blog"><Icon path={mdiChevronLeft} /> back to main blog page</Link>
            {@html postInfo.content}
        </div>

        <Divider />
        {#each (postInfo.blogtag || []) as tag}
            <Button class="blog-tag" text on:click={() => {
                navigate(`/blog/tag/${tag.slug}`)
            }}>#{tag.name}</Button>
        {/each}
        <div class="blog-post-timestamp">
            <Icon path={mdiCalendar} />
            {toDate(postInfo.created)}
        </div>

        {#if postInfo.user && postInfo.user.nickname}
            <div class="blog-author">
                <div class="blog-author-image">
                    <Link to="/profile/{postInfo.user.id}">
                        <img src="{fetchAvatar(postInfo.user.avatar, 120)}" alt="author" />
                    </Link>
                </div>
                <div class="blog-author-bio">
                    <h4>{postInfo.user.nickname}</h4>
                    <p>{postInfo.user.bio}</p>
                </div>
            </div>
        {/if}

        {#await blogComments}
            <div>Loading comments...</div>
        {:then comments}
            {#if comments && comments.length}
                <h2 class="comments-title">Comments</h2>
                {#each comments as comment}
                    <div class="blog-comment">
                        <div class="blog-comment-poster-image">
                            <Link to="/profile/{comment.user.id}">
                                <img src="{fetchAvatar(comment.user.avatar, 120)}" alt="" />
                            </Link>
                        </div>
                        <div class="blog-comment-container">
                            <Divider />
                            <div class="blog-comment-poster">
                                <Link to="/profile/{comment.user.id}">
                                    <h4>{comment.user.nickname}</h4>
                                </Link>
                                <span>on {toDate(comment.created)}</span>
                            </div>
                            <div class="blog-comment-text">{comment.content}</div>
                        </div>
                    </div>
                {/each}
            {:else}
                <h2 class="comments-title">Be the first to comment...</h2>
            {/if}
        {/await}
        {#if globalThis.user}
            <Textarea class="comment-field" placeholder="Your thoughts..." bind:value={commentText} />
            <Button class="submit-button primary-color" on:click={() => {
                fetch(`/api/blog/${postInfo.id}/comment`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user: globalThis.user.id,
                        blog_id: postInfo.id,
                        content: commentText
                    })
                }).then(() => {
                    // refresh the page
                    blogComments.then(list => {
                        let comment = {
                            user: globalThis.user,
                            content: commentText,
                            created: Date.now()
                        }
                        commentText = null;
                        return list.push(comment)
                    })
                })
            }}>Post Comment</Button>
        {:else}
            <Button class="submit-button">Login to comment</Button>
        {/if}
    </div>
</Aggregator>

<style type="text/scss">
    @import 'material-theme';

    .youtube {
        text-decoration: none;
        color: #4caf50;
        padding-left: 30px;
        padding-top: 20px;
        margin-bottom: -10px;
        display: block;
    }

    .filter {
        padding: 16px;

        :global(.filter-name) {
            padding-right: 24px;
            color: #999;
        }

        :global(.s-chip) {
            margin: 5px;
            text-transform: capitalize;
            cursor: pointer;

            &:hover {
                background: $primary-color;
            }
        }
    }

    .single-item {
        width: 300px;
        height: 450px;
        float: left;

        & :global(.teaser) {
            filter: blur(2px);
        }

        & :global(.author) {
            font-weight: 700;
            &:hover {
                color: $primary-color;
            }
        }

        & :global(.s-card-subtitle) {
            padding: 0 16px;
            font-size: 0.7rem;
        }

        & :global(.s-card-title) {
            text-transform: uppercase;
            font-size: 1rem;
            padding: 0 16px;
            font-weight: 700;
            word-break: break-word;
            line-height: 1;
        }

        & :global(.s-card-text) {
            height: 150px;
            overflow: hidden;
            font-size: 0.8rem;
        }

        & :global(.s-card-actions) {
            overflow: hidden;
        }

        & :global(.s-btn) {
            font-size: 0.7rem;

            & :global(span) {
                font-weight: 700;
                text-transform: capitalize !important;
            }

            &:before {
                display: none;
            }
            &:hover {
                color: $primary-color;
                background: transparent;
            }
        }

        & :global(.s-divider) {
            margin: 0 16px;
            margin-top: 8px;
        }
    }
    .preview-image {
        margin: 16px;
        margin-bottom: 0;
        cursor: pointer;
        border: 1px solid $secondary-bg-color; /* subtle border for images w/ white bg */
        height: 170px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media only screen and (max-width: 600px) {
        .single-item {
            min-width: 100%;
        }
    }

    .blog-post {
        float: left;
        width: 70%;
        max-width: 800px;
        margin: 16px 64px;

        :global(.blog_back) {
            text-decoration: none;
            color: #777;
            text-transform: uppercase;
        }

        :global(.blog-content) {
            :global(h1) {
                text-transform: uppercase;
                padding-bottom: 16px;
            }

            :global(blockquote) {
                border-left: 5px solid #4caf50;
                background: #f6f6f6;
                padding: 10px;
                padding-bottom: 0px;
                margin-left: 0;
                font-style: italic;
                font-size: 1rem;
            }
        }

        :global(.blog-tag) {
            font-size: 0.7rem;

            & :global(span) {
                font-weight: 700;
                text-transform: capitalize !important;
            }

            &:before {
                display: none;
            }
            &:hover {
                color: $primary-color;
                background: transparent;
            }
        }

        :global(.blog-post-timestamp) {
            float: right;
            padding: 5px;
        }

        :global(.blog-author) {
            background-color: $secondary-bg-color;
            border-radius: 10px;
            display: flex;
            margin: 20px 0;

            :global(a) {
                text-decoration: none;
                color: black;
            }

            :global(.blog-author-image) {
                @include profile-img;
                margin: 20px;
                height: 120px;
            }

            :global(.blog-author-bio) {
                width: calc(100% - 120px);
                padding: 20px;

                :global(h4) {
                    font-family: $body-font-family;
                    font-size: 1.15rem;
                    font-weight: 700;
                }

                :global(p) {
                    font-style: italic;
                    font-size: 0.9rem;
                }
            }
        }

        :global(.comments-title) {
            text-transform: uppercase;
        }

        :global(.blog-comment) {
            :global(.blog-comment-poster-image) {
                @include profile-img;
                vertical-align: top;
                margin: 10px;
                margin-top: 30px;
                margin-left: 0;
            }

            :global(.blog-comment-container) {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 130px);
                padding: 5px;

                :global(.blog-comment-poster) {
                    :global(a) {
                        text-decoration: none;
                    }

                    :global(h4) {
                        font-size: 1rem;
                        line-height: 2rem;
                        font-weight: 700;
                        display: inline;
                        color: black;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    :global(span) {
                        font-size: 0.8rem;
                    }
                }

                :global(.blog-comment-text) {
                    font-size: 0.9rem;
                    white-space: break-spaces;
                }
            }

        }

        :global(.comment-field) {
            margin-top: 20px;
        }

        :global(.submit-button) {
            margin-top: 16px;
            margin-bottom: 100px;
        }
    }
</style>