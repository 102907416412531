<svelte:head>
    <title>About Us - Investomation</title>
</svelte:head>

<script>
    import {
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        CardActions,
        Button,
    } from 'svelte-materialify/src';
    import { fetchAvatar } from '../../utils';

    let profiles = [
        {
            avatar: '5893688888da553adbbae8ec12d62e4f',
            name: 'Alex',
            specialty: 'Technical Founder',
            contact: 'alex@investomation.com'
        },
        {
            avatar: '51d7f7a9eea2d53a040e6137eec38ec4',
            name: 'Maria',
            specialty: 'Analytics and Outreach',
            contact: 'maria@investomation.com'
        }
    ]
</script>

<div class="container">

    <h1>Our Mission</h1>

    <p>
        Our mission is to provide the best tools for real estate investors to
        succeed. We are building a platform that will allow investors to
        analyze properties, manage their portfolio, and find deals.
    </p>

    <p>
        Our definition of "best" is based on the concept of simplicity. We're
        tired of complex Excel spreadsheets with dozens of different ROI metrics
        and hundreds of fields to fill in. We want our software to be useful even
        if you're not sure what numbers to plug in.
    </p>

    <p>
        We are currently in beta, and are looking for user feedback to help
        us build the best platform possible.
    </p>

    <h1>Our Story</h1>

    <p>
        Alex started investing in real estate in 2016. Over and over he saw the same
        pattern, investors preferred to invest locally for no other reason than they
        were afraid of investing in unfamiliar markets. The only way to invest remotely
        at the time was turnkey operators, who often resold their clients unwanted
        stock in D-class markets and would make more money on each property in PM
        fees than the investors would themselves.
    </p>

    <p>
        Being a software developer, Alex wrote a few scripts to webscrape data from multiple
        sources to help him make more educated investing decisions. Being a nerd that he is,
        Alex then wrote more scripts to look for patterns in the data. It worked! within
        a few years, Alex amassed a portfolio of rental properties across multiple states
        that is now worth double its original price.
    </p>

    <p>
        Eventually, Alex met Maria who suggested Alex turn his scripts into a SaaS platform,
        and that's how Investomation was born. We now help other investors make better
        decisions and provide educational content to help them succeed.
    </p>

    <h1>Our Expertise</h1>

    <p>
        We have been in the real estate business since 2016, 
        with several million dollars of real estate in our portfolio. 
        We own rental properties in multiple states and are rapidly expanding.
    </p>

    <p>
        Our goal is to provide other investors with the same tools we built for
        ourselves to succeed. Real estate investing has potential to make you wealthy
        or broke, depending on how you approach it.
    </p>

    <h1>Our Team</h1>
    
    {#each profiles as profile}
        <Card class="profile">
            <img src="{fetchAvatar(profile.avatar, 250)}" alt="face" />
            <CardTitle>{profile.name}</CardTitle>
            <CardSubtitle>{profile.specialty}</CardSubtitle>
        </Card>
    {/each}
</div>

<style>
    .container {
        width: 600px;
        margin: auto;
        clear: both;
        max-width: 100%;
        padding: 8px;
    }

    :global(.profile) {
        margin: 10px;
        width: 250px;
        height: 340px;
        float: left;
    }
</style>