<script>
    import GreenWave from '../components/landing/GreenWave.svelte';

    export let reason = 'default';
    const reasons = {
        'signup': 'Thank you for signing up! Please check your email to confirm your account.',
        'forgot': 'Please check your email for further instructions.',
        'confirm': 'Thank you for confirming your email.',
        'upgrade': 'Thank you for upgrading your account.',
        'contact': 'Thank you for contacting us. We will get back to you as soon as possible.',
        'invest': 'Thank you for your interest in investing. We will get back to you as soon as possible.',
        'partner': 'Thank you for your interest in partnering. We will get back to you as soon as possible.',
        'support': 'Thank you for reaching out to support. We will get back to you as soon as possible.',
        'default': 'Thank you for your submission.'
    }
</script>

<div class="container">
    <h1>Thank You</h1>

    <p>{reasons[reason]}</p>
</div>
<div class="bottom">
    <GreenWave bottomWave={false} />
</div>

<style>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }
    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
</style>