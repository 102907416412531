<script>
    import {
        TextField,
        Textarea,
        Select,
        Checkbox,
        Button,
    } from 'svelte-materialify/src';

    let funds = [
        { name: '$10,000-$25,000', value: '$10,000-$25,000' },
        { name: '$25,000-$50,000', value: '$25,000-$50,000' },
        { name: '$50,000-$100,000', value: '$50,000-$100,000' },
        { name: '$100,000+', value: '$100,000+' },
    ]
    let timeframe = [
        { name: '1-2 years', value: '1-2 years' },
        { name: '2-5 years', value: '2-5 years' },
        { name: '5-10 years', value: '5-10 years' },
        { name: '10+ years', value: '10+ years' },
    ]
</script>

<div class="container">

    <h1>Invest With Us</h1>

    <p>
        Investing can be stressful and time-consuming. While the benefits can be amazing long-term,
        it's not for everyone. If you have a busy job, a family, or a business to run, you may be
        better off investing with someone else for a fixed return instead of allocating your
        savings yourself. Tell us a little bit about yourself and one of our representatives
        will contact you.
    </p>

    <form action="/invest" method="post">
        <TextField>Subject</TextField>
        <Select items={funds}>Available Funds</Select>
        <Select items={timeframe}>Investing Timeframe</Select>
        <Checkbox>I'm an Accredited Investor</Checkbox>
        <Textarea>Details</Textarea>
        <Button type="submit">Submit</Button>
    </form>
    <div class="padded">
        If you prefer to just send us an email, you can do so at <a href="mailto:invest@invesotmation.com">invest@investomation.com</a>.
    </div>
</div>

<style>
    h1 {
        text-transform: uppercase;
    }
    
    .container {
        width: 600px;
        margin: auto;
        clear: both;
    }

    :global(.profile) {
        margin: 10px;
        width: 250px;
        height: 340px;
        float: left;
    }

    .padded {
        margin-top: 40px;
        font-size: 0.9rem;
    }
</style>