<script>
    import { mdiFaceAgent } from '@mdi/js';
    import {
        TextField,
        Textarea,
        Select,
        Button,
        Icon,
        Snackbar
    } from 'svelte-materialify/src';
    import FormField from '@smui/form-field'
    import { navigate } from 'svelte-routing';
    import GreenWave from '../components/landing/GreenWave.svelte';

    export let data
    let username
    let password

    function login() {
        fetch('/account/signin', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password, redirect: data.redirect })
        }).then(response => {
            if (response.ok) {
                // globalThis.user = { username }
                // navigate(redirect || '/')
                window.location.href = data.redirect || '/'
            } else if (response.status === 401) {
                response.json().then(msg => {
                    errorMessage = msg.error
                })
            } else {
                response.json().then(msg => {
                    errorMessage = 'There was a problem logging in, please contact support.'
                })
            }
        })
    }

    let errorMessage = data.message.join('\n') || null
    console.log('errorMessage', errorMessage, data)
    
</script>

<div class="container">

    <h1>Login</h1>

    <TextField bind:value={username} >Username</TextField>
    <TextField bind:value={password} type="password">Password</TextField>
    
    <div class="submit-btn">
        <Button disabled={!username || !password} class="primary-color" on:click={login}>Log In</Button>
    </div>
</div>
<div class="bottom">
    <GreenWave bottomWave={false} />
</div>

<Snackbar class="flex-column snackbar-error" bind:active={errorMessage} bottom center timeout={10000}>
    {errorMessage}
</Snackbar>

<style type="text/scss">
    @import 'material-theme';

    h1 {
        text-transform: uppercase;
    }
    
    .container {
        padding-top: 100px;
        max-width: 400px;
        margin: auto;
        clear: both;

        strong {
            color: $primary-color;
        }

        #tier-group {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        :global(.submit-btn) {
            display: block;
            margin: 20px 0;
        }

        :global(.submit-btn > button.disabled) {
            background-color: #ccc !important;
        }
    }

    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    :global(.profile) {
        margin: 10px;
        width: 250px;
        height: 340px;
        float: left;
    }

    #bad-fields {
        color: $error-color;
        padding: 0 16px;
    }
</style>
