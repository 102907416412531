<svelte:head>
  <link rel="stylesheet" href="https://unpkg.com/@carbon/charts/styles.min.css" />
</svelte:head>

<script>
    import { Button, TextField, Select, Row } from "svelte-materialify/src";
    import { LineChart, AreaChart } from '@carbon/charts-svelte'
    import { categories } from '../../../common/constants'
    import { prettyName } from "../../utils";

    let metric = 'population';
    let year = '2015-2025';
    let region = 'Boston MA';
    let results;
    let headers;
    let format;
    let view = '';
    let aggregation = 'total'
    let normalize = (data) => format === 'normal' ? data.map(i => i.v) : data.map(i => i.r * i.t)
    let aggregators = {
        total: (data) => normalize(data).reduce((s, v) => s + v),
        median: (data) => {
            // this is a weighted median approximation, and likely to have some error
            let values = format === 'normal' ? data.map(i => i.v) : data.map(i => i.r * 100)
            let halfWeight = data.map(i => i.t).reduce((s, v) => s + v) / 2
            values.sort((a, b) => a - b)
            let currentWeight = 0
            for (let i = 0; i < values.length; i++) {
                if (currentWeight + data[i].t >= halfWeight) {
                    return values[i]
                }
                currentWeight += data[i].t
            }
        },
        mean: (data) => {
            // this will not be completely accurate when inputs are not mean themselves (i.e. median home price)
            let values = format === 'normal' ? data.map(i => i.v) : data.map(i => i.r * 100)
            let total = 0
            for (let i = 0; i < values.length; i++) {
                total += values[i] * data[i].t
            }
            let count = data.map(i => i.t).reduce((s, v) => s + v)
            return total / count
        }
    }
    let nextAggregation = () => {
        let all = Object.keys(aggregators)
        let current = all.indexOf(aggregation)
        aggregation = all[(current + 1) % all.length]
    }

    async function loadData() {
        let area
        if (/^(\d+,)*\d+$/.test(region) || /^\d+-\d+$/.test(region)) {
            area = `zip=${region}`
        } else if (/^[A-Z][A-Z]$/.test(region)) {
            area = `state=${region}`
        } else {
            area = `city=${region}`
        }
        const response = await fetch(`/api/predict/matrix/metric/${metric}?year=${year}&${area}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })

        if (response.ok) {
            let r = await response.json()
            format = r.format
            results = r.years
            headers = Object.keys(r.years)
            if (format === 'ratio') {
                view = '%'
            } else {
                view = '#'
            }
        }
    }

    let switchView = () => {
        if (format === 'ratio') {
            if (view === '%') {
                view = '#'
            } else {
                view = '%'
            }
        }
    }

    let availableColors = {}
    let nextColor = () => {
        for (const [color, available] of Object.entries(availableColors)) {
            if (available) {
                availableColors[color] = false
                return color
            }
        }
    }
    categories.slice(1).forEach(c => availableColors[c] = true)
    let colors = {}
    let selected = new Set()
    let selectRow = (event) => {
        let name = event.target.parentElement.querySelector('th').innerText
        if (selected.has(name)) {
            selected.delete(name)
            availableColors[colors[name]] = true
            delete colors[name]
            event.target.parentElement.style.background = ''
        } else {
            selected.add(name)
            colors[name] = nextColor()
            event.target.parentElement.style.background = colors[name]
        }
        selected = selected // a hack to trigger svelte update
        console.log(colors)
    }
    let selectedData;
    $: selectedData = Array.from(selected.values()).map(name => {
        let key = parseInt(name)
        return headers.map(y => {
            return {
                group: name,
                value: view === '%' ?
                    100 * results[y][key].r :
                    format === 'ratio' ?
                        results[y][key].r * results[y][key].t :
                        results[y][key].v,
                year: y
            }
        })
    }).flat()
</script>
{#if globalThis.user && globalThis.user.tier !== 'free'}
    <div class="matrix-container">
        <Row>
            <TextField bind:value={metric}>Metric</TextField>
            <TextField bind:value={year}>Years</TextField>
            <TextField bind:value={region}>Region</TextField>
        </Row>

        <Button class="primary-color fetch-btn" on:click={loadData}>Load</Button>

        <div class="table">
            {#if results}
                <div id="chart">
                    {#if selected.size}
                        <LineChart
                            options={{
                                axes: {
                                    bottom: {
                                        title: 'Year',
                                        mapsTo: 'year',
                                        scaleType: 'labels'
                                    },
                                    left: {
                                        title: prettyName(metric) + (view === '%' ? '(%)' : ''),
                                        mapsTo: 'value',
                                        scaleType: 'linear'
                                    }
                                },
                                height: '400px',
                                legend: {
                                    alignment: 'center'
                                },
                                color: {
                                    scale: colors
                                },
                                tooltip: {
                                    showTotal: view === '%' ? false : true
                                }
                            }}
                            data={selectedData}
                        />
                    {:else}
                        <div class="chart-msg">Select one or more rows to generate a chart.</div>
                    {/if}
                </div>
                <table class="results-table">
                    <tr>
                        <th class="results-header results-header-left switch-view" on:click={switchView}>
                            {view}
                        </th>
                        {#each headers as year}
                            <th class="results-header">{year}</th>
                        {/each}
                    </tr>
                    <tr class="results-aggregation">
                        <th class="aggregation-switch" on:click={nextAggregation}>{aggregation}</th>
                        {#each headers as year}
                            <td>
                                {Number(aggregators[aggregation](
                                    Object.keys(results[year]).map(z => results[year][z])
                                )).toLocaleString()}
                            </td>
                        {/each}
                    </tr>
                    {#each Object.keys(results[headers[0]]) as zip, i}
                        <tr on:click={selectRow}>
                            <th class= "results-header-left">{zip.padStart(5, '0')}</th>
                            {#each headers as year}
                                <td>
                                    {Number(
                                        format === 'normal' ?
                                            results[year][zip].v :
                                            view === '%' ?
                                                100 * results[year][zip].r :
                                                parseInt(results[year][zip].r * results[year][zip].t)
                                    ).toLocaleString()}
                                </td>
                            {/each}
                        </tr>
                    {/each}
                </table>
            {/if}
        </div>
    </div>
{:else}
    <p class="error-text">Detailed region analysis is a feature only available to Professional and Business members.</p>
{/if}

<AreaChart
	data={[
	{
		"group": "Dataset 1",
		"date": "2019-01-01T05:00:00.000Z",
		"value": 0
	},
	{
		"group": "Dataset 1",
		"date": "2019-01-06T05:00:00.000Z",
		"value": -37312
	},
	{
		"group": "Dataset 1",
		"date": "2019-01-08T05:00:00.000Z",
		"value": -22392
	},
	{
		"group": "Dataset 1",
		"date": "2019-01-15T05:00:00.000Z",
		"value": -52576
	},
	{
		"group": "Dataset 1",
		"date": "2019-01-19T05:00:00.000Z",
		"value": 20135
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-01T05:00:00.000Z",
		"value": 47263
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-05T05:00:00.000Z",
		"value": 14178
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-08T05:00:00.000Z",
		"value": 23094
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-13T05:00:00.000Z",
		"value": 45281
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-19T05:00:00.000Z",
		"value": -63954
	}
]}
	options={{
	"title": "Area (time series - natural curve)",
	"axes": {
		"bottom": {
			"title": "2019 Annual Sales Figures",
			"mapsTo": "date",
			"scaleType": "time"
		},
		"left": {
			"mapsTo": "value",
			"scaleType": "linear"
		}
	},
	"curve": "curveNatural",
	"height": "400px"
}}
	/>

<style type="text/scss">
    @import 'material-theme';

    .error-text {
        padding: 20px;
    }

    .chart-msg {
        text-align: center;
        font-weight: 700;
        font-size: 1.1rem;
    }

    .matrix-container {
        :global(.s-row) {
            margin: 0px;

            :global(.s-input) {
                margin: 10px;
            }
        }

        :global(.fetch-btn) {
            margin-left: 10px;
        }
    }
    .table {
        height: 70vh;
        margin-top: 10px;
        overflow: auto;
    }
    .results-table {
        width: 100%;
        padding: 10px;
        text-align: end;

        :global(tr:hover) {
            background: $accent-bg-color;
            cursor: pointer;
        }

        :global(th), :global(td) {
            padding-right: 3px;
        }

        :global(td) {
            color: white;
            mix-blend-mode: difference;
        }

        :global(.switch-view) {
            cursor: pointer;
            background: $primary-color !important;
            z-index: 1;
        }

        :global(.results-header) {
            position: sticky;
            top: 0;
            background: $secondary-bg-color;
        }

        :global(.results-header-left) {
            cursor: pointer;
            position: sticky;
            left: 0;
            background: $secondary-bg-color;
        }
        :global(.results-aggregation) {
            position: sticky;
            bottom: 0;
            background: $secondary-bg-color;

            :global(.aggregation-switch) {
                text-transform: capitalize;
                background: $primary-color;
                cursor: pointer;
            }
        }
    }
</style>
