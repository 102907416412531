const pubSub = require('../pubsub');
import { newId, deepEqual } from '../utils';

// Storage engine
class Storage {

    constructor(data) {
        window.ss = this
        // ensure the requested set of objects exists
        this._data = {};
        this._cache = {};
        Object.keys(data).forEach(key => {
            // forces a fetch
            this.get(key);
            this._cache[key] = [];
        });
    }

    // retrieve a list of elements
    get(key) {
        if (!this._data[key]) {
            return fetch(`/${key}`).then(r => r.json()).then(json => {
                let data = []
                if (json) {
                    if (json[key]) { // old system
                        data = json[key];
                    } else if (json.items) { // API
                        data = json.items;
                    }
                }
                return this._data[key] = data.concat(this._cache[key]);
            });
        } else {
            return Promise.resolve(this._data[key].concat(this._cache[key]));
        }
    }

    // save an element
    set(key, value) {
        this._data[key] = value;
        fetch(`/${key}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value)
        }).then(r => this._data[key].id = r.id);
    }

    // NEXT 2 ENDPOINTS ARE ONLY RELEVANT TO LOCALSTORAGE
    // delete an element
    del(key) {
        delete this._data[key];
        delete this._cache[key];
    }

    // wipe all storage
    clear() {
        this._data = {};
        this._cache = {};
    }

    //////////////////////
    // array operations //

    // if in local cache, returns index, else returns -1
    inCache(key, id) {
        return this._cache[key].findIndex(e => e.id === id);
    }

    // append an item to an array-element
    append(key, data, cache=false) {
        if (cache) {
            this._cache[key].push(data);
            return Promise.resolve(data.id = newId());
        } else {
            this._data[key].push(data);
            return fetch(`/${key}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then(r => r.json()).then(r => {
                if (r.error === "Not logged in") {
                    pubSub.publish('notify:error', "Please log in, otherwise your work won't be saved");
                    return data.id = newId();
                } else {
                    return data.id = r.id;
                }
            });
        }
    }

    // retrieve element by combination of fields (retrieves first element)
    find(key, fields) {
        return this.get(key).then(list => {
            return list.find(e => {
                return Object.keys(fields).every(f => deepEqual(e[f], fields[f]));
            });
        });
    }

    // like find, but retrieves all elements
    filter(key, fields) {
        return this.get(key).then(list => {
            return list.filter(e => {
                return Object.keys(fields).every(f => deepEqual(e[f], fields[f]));
            });
        });
    }

    // find element by combination of fields and edit it (returns non-zero code if ID changed)
    edit(key, fields, newFields, cache=false) {
        return this.find(key, fields).then(element => {
            Object.assign(element, newFields); // cache should automatically get overwritten by this statement
            if (!cache) {
                let index = this.inCache(key, element.id);
                if (index !== -1) {
                    // write to disk for the first time
                    this._cache[key].splice(index, 1);
                    return this.append(key, element);
                } else {
                    // update existing element on disk
                    fetch(`/${key}/${element.id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(newFields)
                    });
                    return element.id;
                }
            } else {
                // updated cached version
                return element.id;
            }
        });
    }

    // remove item from array-element that has a field matching one defined by data
    remove(key, id) { // key = api endpoint name (e.g. api/cashflow), id = db id of the object to remove
        let index = this.inCache(key, id);
        if (index !== -1) {
            this._cache[key].splice(index, 1);
        } else {
            index = this._data[key].findIndex(e => e.id === id);
            fetch(`/${key}/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            this._data[key].splice(index, 1);
        }
    }
}

export default Storage;
