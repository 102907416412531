<script>
    import {
        TextField,
        Textarea,
        Select,
        Button,
    } from 'svelte-materialify/src';

    let items = [
        { name: 'Partnership', value: 'Partnership' },
        { name: 'Press', value: 'Press' },
        { name: 'Other', value: 'Other' },
    ]
</script>

<div class="container">

    <h1>Partnership / Press</h1>

    <p>
        Please tell us more.
    </p>

    <form action="/support" method="post">
        <TextField>Subject</TextField>
        <Select {items}>Category</Select>
        <Textarea>Details</Textarea>
        <Button type="submit">Submit</Button>
    </form>
    <div class="padded">
        If you prefer to just send us an email, you can do so at <a href="mailto:info@invesotmation.com">info@investomation.com</a>.
    </div>
</div>

<style>
    h1 {
        text-transform: uppercase;
    }

    .container {
        width: 600px;
        margin: auto;
        clear: both;
    }

    :global(.profile) {
        margin: 10px;
        width: 250px;
        height: 340px;
        float: left;
    }

    .padded {
        margin-top: 40px;
        font-size: 0.9rem;
    }
</style>