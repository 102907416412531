<script>
    import { Link } from './routing'
    import IconButton, { Icon } from '@smui/icon-button';

    export let tier
    export let verticalOffset = '50%'
    export let message=`This is premium content that requires ${tier} subscription.`

    const tierRank = {
        'free': 1,
        'professional': 2,
        'business': 3
    }

    const showPaywall = () => {
        let user = globalThis.user
        if (tier) {
            if (user && user.tier && tierRank[user.tier] >= tierRank[tier]) {
                return false
            }
            return true
        }
        return false
    }
</script>

{#if showPaywall()}
    <Link to="/signup" class="paywall-container">
        {#if $$slots.teaser}
            <slot name="teaser" />
        {/if}
        <div class="paywall" style="--vertical-offset: {verticalOffset}">
            <Icon class="material-icons">lock</Icon>
            <div class="message">
                {message}
            </div>
        </div>
    </Link>
{:else}
    <slot name="main" />
{/if}

<style type="text/scss">
    :global(.paywall-container) {
        display: flex;
        height: 100%;
        position: relative;
    }

    .paywall {
        position: absolute;
        top: var(--vertical-offset);
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        margin: auto;
        width: 70%;
        height: 100px;
        text-align: center;

        :global(.material-icons) {
            color: #666;
            font-size: 3rem;
        }

        .message {
            font-size: 0.8rem;
            color: #333;
        }
    }
</style>