<script>
    import {
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        CardActions,
        Button,
    } from 'svelte-materialify/src';
    import CourseSummary from '../components/CourseSummary.svelte'

    let courses = [
        {
            image: 'crypto_crash_course',
            name: 'Crypto Crash Course',
            text: 'Once seen as a giant ponzi scheme, crypto currency has emerged as a new asset class. What is Bitcoin? ' +
                  'How does it work? Why have celebrities poured billions of dollars into it? What are smart contracts and how do they work? ' +
                  'Why are some governments hostile towards crypto and others embrace it? What investment strategies are available and what are their tax implications? ' +
                  'All these questions will be answered in the Crypto Crash Course.',
            price: 499
        },
        {
            image: 'real_estate_crash_course',
            name: 'Real Estate Crash Course',
            text: 'One of the oldest asset classes yet still one of the most popular. Real estate is one one of the most tax-advantaged ' +
                  'asset classes and favored by the wealthy across the world. How does it consistently outpace inflation? Why is it considered ' +
                  'a safe asset class despite all the leverage available to the investor? How can you make use of that leverage to grow your wealth tax-free? ' +
                  'All these questions will be answered in the Real Estate Crash Course.',
            price: 999
        }
    ]
    let enrollCrypto = false
    let enrollRealEstate = false
    let enrollWealth = false
    let enrollAI = false
</script>

<div class="container">

    <h1>Courses</h1>

    <p>
        The following courses are designed to turn you from a complete beginner into someone who has a solid understanding of 
        the field. We can't promise that they'll make you an expert, as there are nuances that only come with practice and there
        are a lot of niches to understand within each asset class.
    <p/><p>
        Each course will cover the basics, intermediate, and advanced strategies for investing in the asset class. For each asset
        class we will cover the more popular niches, their benefits and drawbacks, tax implications and ways to minimize them.
    </p>

    <CourseSummary name="Crypto Crash Course" image="crypto_crash_course" price=499>
        <span slot="description">
            Once seen as a giant ponzi scheme, crypto currency has emerged as a new asset class.
            What is Bitcoin? How does it work? Why have celebrities poured billions of dollars into it?
            What are smart contracts and how do they work? Why are some governments hostile towards
            crypto and others embrace it? What investment strategies are available and what are their tax
            implications? All these questions will be answered in the Crypto Crash Course. The lecture will
            be divided into sections explaining what cryptocurrencies are, why they make sense to invest in,
            and how to go about doing it. The course will dive deep into DeFi to explain
            how it differs from traditional finance and strategies for investing.
        </span>
        <div slot="toc" >
            <b>Course Content</b>
            <ul>
                <li>What is Bitcoin and how does it work?
                    <ul>
                        <li>Blockchain Technology</li>
                        <li>Political Significance</li>
                        <li>Ethereum and Smart Contracts</li>
                    </ul>
                </li>
                <li>Why cryptocurrencies are here to stay?
                    <ul>
                        <li>What problem do they solve?</li>
                        <li>Democratization</li>
                        <li>Money Legos</li>
                        <li>Anything as a Service</li>
                    </ul>
                </li>
                <li>How to invest?
                    <ul>
                        <li>Centralized Exchanges vs Private Wallets</li>
                        <li>Decentralized Finance
                            <ul>
                                <li>Basic Concepts</li>
                                <li>Web 3.0</li>
                                <li>Leading Chains Compared</li>
                                <li>Avoiding Shitcoins</li>
                            </ul>
                        </li>
                        <li>Doing Research
                            <ul>
                                <li>Reddit, Discord, Telegram</li>
                                <li>YouTube & Podcasts</li>
                                <li>Whitepapers</li>
                                <li>Emotions</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>Advanced Material
                    <ul>
                        <li>Technical Concepts to Understand
                            <ul>
                                <li>Blockchain Trilemma</li>
                                <li>Smart Contracts</li>
                                <li>NFTs</li>
                                <li>Oracles</li>
                                <li>Project Ecosystem</li>
                                <li>DAO</li>
                                <li>Layer 2s and Subnets</li>
                                <li>Bridges</li>
                                <li>DeFi 2.0</li>
                                <li>Definitions</li>
                            </ul>
                        </li>
                        <li>Financial Concepts to Understand
                            <ul>
                                <li>Tokenomics</li>
                                <li>Stablecoins & CBDCs</li>
                                <li>Project Trends</li>
                                <li>Market Cycles</li>
                                <li>Investor Psychology</li>
                                <li>Smart money, dumb money</li>
                                <li>Technical Analysis vs Fundamental Analysis</li>
                                <li>Exit Strategies</li>
                            </ul>
                        </li>
                        <li>Technology Literacy and Financial Literacy</li>
                        <li>Top 100 Tokens Explained</li>
                        <li>GameFi & Play to Earn</li>
                        <li>The Dark Side of Crypto</li>
                        <li>Chain-Specific Investment Strategies</li>
                        <li>Taxes and Fees</li>
                    </ul>
                </li>
            </ul>
        </div>
    </CourseSummary>
    <CourseSummary name="Real Estate Crash Course" image="real_estate_crash_course" price=999>
        <span slot="description">
            One of the oldest asset classes yet still one of the most popular. Real estate is
            the most tax-advantaged investment vehicle and favored by the wealthy across the world. How does it
            consistently outpace inflation? Why is it considered a safe asset class despite all the leverage
            available to the investor? How can you make use of that leverage to grow your wealth tax-free?
            The crash course will explain basic real estate concepts, how to get started, and dive into
            different investing strategies (single family homes, rehabs, apartment buildings, syndications, storage, land,
            liens, etc.).
        </span>
        <div slot="toc">
            <b>Course Content</b>
            <ul>
                <li>Why Invest In Real Estate?
                    <ul>
                        <li>Safest Asset class
                            <ul>
                                <li>Financial Risks</li>
                                <li>Problems with Financial System</li>
                                <li>Inflation</li>
                            </ul>
                        </li>
                        <li>Leverage and Liquidity</li>
                        <li>Taxes</li>
                        <li>Your Personal "Why"</li>
                    </ul>
                </li>
                <li>How to Get Started?
                    <ul>
                        <li>Human Psychology</li>
                        <li>Basics</li>
                        <li>Market Inefficiencies</li>
                        <li>Finding Deals</li>
                        <li>Strategies Compared</li>
                        <li>Resources
                            <ul>
                                <li>Communities</li>
                                <li>Podcasts</li>
                                <li>YouTube</li>
                            </ul>
                        </li>
                        <li>Avoiding Scams</li>
                    </ul>
                </li>
                <li>Advanced Topics
                    <ul>
                        <li>Inflation & Debt</li>
                        <li>Margin of Safety</li>
                        <li>Flipping Houses</li>
                        <li>Single Family Investing
                            <ul>
                                <li>Choosing a Market</li>
                                <li>Napkin Analysis & Rules of Thumb</li>
                                <li>Scaling</li>
                                <li>Property Management</li>
                                <li>Turnkey Properties</li>
                                <li>BRRR Strategy</li>
                            </ul>
                        </li>
                        <li>Contractors & Repairs</li>
                        <li>Partnerships</li>
                        <li>Tenants & Evictions</li>
                        <li>Gentrification</li>
                        <li>Apartment Building Investing
                            <ul>
                                <li>Building a Team</li>
                                <li>Underwriting a Deal</li>
                                <li>Syndications</li>
                                <li>Asset Management</li>
                                <li>Exit Strategies</li>
                            </ul>
                        </li>
                        <li>Other Investing Strategies
                            <ul>
                                <li>AirBNB</li>
                                <li>Rent to Own</li>
                                <li>Land</li>
                                <li>Storage</li>
                                <li>Commercial</li>
                                <li>Mortgage Notes</li>
                            </ul>
                        </li>
                        <li>COVID-19</li>
                        <li>Politics</li>
                        <li>Market Cycles</li>
                        <li>Tax Strategies</li>
                    </ul>
                </li>
            </ul>
        </div>
    </CourseSummary>
    {#if false}
    <CourseSummary name="Wealth Crash Course" image="wealth_crash_course" price=499>
        <span slot="description">
            As a typical first-generation immigrant raised by a single parent, I grew up poor. Frugality
            quickly became my strength, but wealth kept eluding me until a set of realizations in my late
            20s. These were in part due to the books I've read, in part due to my mentors, and in part due to
            my experiences. It was a paradigm shift in my thinking that put me on track to build wealth. I've
            realized that the fundamental thinking process between rich and poor is different, that value is
            relative, and most of us shackle ourselves with limiting beliefs and fear. In this course, I'll
            explain how small changes in your psychology and behavior will lead to huge changes in your
            net worth.
        </span>
        <div slot="toc">
            <b>Course Content</b>
            <ul>
                <li>Why people stay poor?
                    <ul>
                        <li>Wealth is a mindset</li>
                        <li>Technology</li>
                        <li>Goals vs Dreams</li>
                        <li>Fear</li>
                        <li>Risk Taking</li>
                    </ul>
                </li>
                <li>How to become wealthy?
                    <ul>
                        <li>Financial Education</li>
                        <li>Money Perception
                            <ul>
                                <li>Candy</li>
                                <li>Health</li>
                                <li>Leverage</li>
                                <li>Energy</li>
                            </ul>
                        </li>
                        <li>Money Management Strategies
                            <ul>
                                <li>Accounting (family as small corp</li>
                                <li>Wealth vs Cashflow</li>
                                <li>S-Corps and LLCs</li>
                            </ul>
                        </li>
                        <li>Mentors</li>
                        <li>Commitment</li>
                        <li>Bring Value</li>
                        <li>Scaling (OPM & OPT)</li>
                        <li>Resources
                            <ul>
                                <li>Books</li>
                                <li>Documentaries</li>
                                <li>Communities</li>
                                <li>Podcasts & Youtube</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>Advanced Topics
                    <ul>
                        <li>Economics</li>
                        <li>History</li>
                        <li>Politics</li>
                        <li>Mobility</li>
                        <li>Taxes</li>
                        <li>Long-term perspective</li>
                    </ul>
                </li>
            </ul>
        </div>
    </CourseSummary>
    {/if}
    <CourseSummary name="Generative AI Crash Course" image="ai_crash_course" price=499>
        <span slot="description">
            As GPT-based systems become more sophisticated, they start competing with humans in many niches.
            In this course, I'll explain how AI has evolved in the recent years, what are the current limitations,
            and which workflows you can automate without losing quality. I'll also explain how to build your own
            scripts, plugins and web apps that wrap around existing AI services.
        </span>
        <div slot="toc">
            <b>Course Content</b>
            <ul>
                <li>What is Generative AI?
                    <ul>
                        <li>History</li>
                        <li>Strong vs Weak AI</li>
                        <li>Generative AI</li>
                        <li>AGI</li>
                        <li>Should we be afraid?</li>
                    </ul>
                </li>
                <li>How to leverage this new tech?
                </li>
                <li>Advanced Topics
                    <ul>
                        <li>Ethics & Safety</li>
                        <li>Regulation</li>
                        <li>AGI</li>
                    </ul>
                </li>
            </ul>
        </div>
    </CourseSummary>
</div>

<style type="text/scss">
    .container {
        width: 900px;
        max-width: 100%;
        padding: 10px;
        margin: auto;
        clear: both;

        :global(.crypto_crash_course::before) {
            background-image: url(/static/crypto_crash_course.jpg);
        }

        :global(.real_estate_crash_course::before) {
            background-image: url(/static/real_estate_crash_course.jpg);
        }

        :global(.wealth_crash_course::before) {
            background-image: url(/static/wealth_crash_course.jpg);
        }

        :global(.ai_crash_course::before) {
            background-image: url(/static/ai_crash_course.jpeg);
        }

        dt {
            font-weight: 700;
        }
    }

    :global(.course) {
        margin: 10px;
        width: 850px;
    }
</style>