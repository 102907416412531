<svelte:head>
    <title>Predict real estate trends | Investomation</title>
    <meta name="title" content="Discover Real Estate Trends - Investomation">
    <meta name="description" content="Analyze real estate trends and explore educational resources geared at investors. Discover market insights, analyze neighborhoods, and elevate your real estate knowledge with us.">

    <!-- Set the meta keywords -->
    <meta name="keywords" content="real estate, research and analytics, real estate comps, turnkey real estate, passive investing, real estate dashboard" />

    <meta property="og:title" content="Predict Real Estate Trends - Investomation">
    <meta property="og:description" content="Real estate investing research and property management solution">
    <meta property="og:type" content="website">
    <meta property="og:url" content="https://www.investomation.com/">
    <meta property="og:image" content="https://investomation.com/static/landing_image_1.png">

    <meta property="twitter:card" content="summary_large_image">
    <meta property="twitter:url" content="https://www.investomation.com/">
    <meta property="twitter:title" content="Predict Real Estate Trends - Investomation">
    <meta property="twitter:description" content="Real estate investing research and property management solution">
    <meta property="twitter:image" content="https://investomation.com/static/landing_image_1.png">
    <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet">
    <script src="https://player.vimeo.com/api/player.js"></script>
</svelte:head>

<script>
    import {
        Avatar,
        Button,
        Card,
        CardActions,
        CardTitle,
        CardSubtitle,
        CardText,
        Chip,
        Icon,
        Col,
        Row,
        Tooltip
    } from 'svelte-materialify/src';
    import { mdiChartPie, mdiChartBox, mdiFacebook, mdiYoutube, mdiLinkedin, mdiTwitter } from '@mdi/js'
    import { navigate } from '../components/routing';
    import { prettyName } from '../../utils' // regular utils
    import { metricIcons } from '../utils' // svelte utils
    import { onMount, tick } from 'svelte';
    import GreenWave from '../components/landing/GreenWave.svelte';
    // import Subscribe from './components/Subscribe.svelte';
    import Pricing from '../components/landing/Pricing.svelte';

    let slides = [
        {
            title: 'Find Lucrative Real Estate Markets',
            image: '/static/landing_image_1.png',
            text: 'Profile real estate markets using logic specific to your own goals'
        },
        {
            title: 'Be the First to See Emerging Trends',
            image: '/static/landing_image_3.png',
            text: 'Use AI to predict local trends and get ahead of the competition'
        },
    ];
    let page = 0
    let hovering = false
    setInterval(() => {
        if (!hovering) {
            page = (page + 1) % slides.length
        }
    }, 8000)
    $: slide = slides[page]
    $: metrics = fetch("/api/predict/metrics").then(r => r.json())
    $: hasComplex = {}
    async function setComplex(category) {
        hasComplex[category] = true
        await tick()
        return ''
    }

    const icons = {
        categorical: mdiChartPie,
        'categorical rank': mdiChartPie,
        rank: mdiChartBox,
        algorithm: mdiChartBox
    }

    let expandedMetrics = false
    async function expand() {
        expandedMetrics = !expandedMetrics
        await tick()
    }
    const metricsLimit = 0
    const sourceList = ['census', 'irs', 'fbi', 'redfin', 'zillow', 'noaa']

    let y = 0
    $: maxScroll = 0
    $: scrollMultiplier = maxScroll < 100 ? 0 : maxScroll < 1000 ? 0.5 : 1
    let youtubeWidth = '853'
    let youtubeHeight = '505'
    const compute = () => {
        maxScroll = document.body.scrollHeight - document.body.offsetHeight
        console.log(maxScroll)
        youtubeWidth = globalThis.innerWidth > 853 ? '853' : globalThis.innerWidth > 640 ? '640' : '-'
        youtubeHeight = globalThis.innerWidth > 853 ? '505' : globalThis.innerWidth > 640 ? '390' : '250'
    }
    onMount(compute)
</script>

<svelte:window bind:scrollY={y} />

<div class='slide-container { y > 110 ? 'scroll-away-up': '' }'>
    <div class="slide-text-container" on:mouseover={() => hovering = true} on:mouseout={() => hovering = false}>
        <!-- <div class="slide-controls">
            {#each slides as _, i}
                <div class="slide-bullet {page === i ? 'active' : ''}" on:click={() => page = i} />
            {/each}
        </div> -->
        <div class="slide-text">
            <h1 id="title">{slide.title}</h1>
            <h2 id="subtitle">{slide.text}</h2>
        </div>
        <div class="call-to-action">
            <Button class="float-left pl-2 pr-2 demo-button" on:click={() => y = scrollMultiplier * 250}>
                Demo
            </Button>
            <Button class="primary-color float-left pl-2 pr-2 signup-button" on:click={() => navigate('/signup')}>
                Sign Up
            </Button>
        </div>
    </div>
    <img class="laptop" src="{slide.image}" alt="US Heatmap" />
</div>
<div class="scroll-for-more { y > 100 ? 'hide': '' }" on:click={() => y += scrollMultiplier * 250}>
    <Tooltip top>
        <img src="/static/scroll-for-more.png" alt="Scroll for More Info" on:click={() => y += scrollMultiplier * 250} />
        <span slot="tip">Scroll for more info</span>
    </Tooltip>
</div>
<GreenWave>
    <div class="video-container { (!y || y < scrollMultiplier * 110 || y > scrollMultiplier * 600 ) ? '' : 'show'}">
        <div class="video { (y < scrollMultiplier * 110 || y > scrollMultiplier * 500) ? 'minimize' : '' }">
            <iframe width={youtubeWidth} height={youtubeHeight} src="https://player.vimeo.com/video/879216174?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="How to Find Emerging Real Estate Markets"></iframe>
        </div>
    </div>
<!-- <div id="newsletter">
    <Subscribe surveyName="newsletter" omitName={true} buttonName="Sign me up! I want to stay ahead of my competition.">
        <div slot="thankyou">
            <h2>Thank you for subscribing!</h2>
            <p>We'll send you monthly updates on real estate trends.</p>
        </div>
        <div slot="description">
            <h2>Subscribe to our newsletter</h2>
            <p>
                Get the latest real estate trends directly in your inbox!
                See the best-performing and worst-performing real estate markets every month based on up-to-date 
                trends and our internal AI-powered analytics engine.
            </p>
        </div>
    </Subscribe>
</div> -->
<div class="sources">
    <h2>Our analytics are based on decades of data from reliable sources</h2>
    <div class="source-logo-container">
        {#each sourceList as source}
            <div class="source-logo">
                <img src="/static/{source}-logo.png" alt="${source} logo" />
            </div>
        {/each}
    </div>
</div>
<div class="available-metrics">
    <Card class="metrics-card">
        <CardTitle>
            Over 1000 Metrics Available
        </CardTitle>
        <CardText>
            <div class="metric-info">
                We track each metric through time by location, and build predictive models to extrapolate it into the future.
                Real-estate is hyper-local, so it helps to be on top of trends. We cross-reference thousands of correlations,
                and examine effects of current political and economic effects using deep learning. We overlay feeds from multiple
                sources to reduce error and gain insight into trends that have not yet been published by the government. Our model
                pays attention to trends, not just current snapshots. We don't just aggregate data from other sources, like our
                competitors, we build our own predictive models. Each metric is exposed via predictive API for enterprise consumption.
            </div>
            <div class="metric-cols">
                {#await metrics}
                    Loading...
                {:then list}
                    {#each Object.entries(list) as [category, section]}
                        <div class="metric-category">
                            <Tooltip top>
                                <Icon size="18px" path={metricIcons[category]} />
                                {prettyName(category)}
                                <span slot="tip" class="capitalize">{section.info}.</span>
                            </Tooltip>
                            {#if !expandedMetrics && Object.keys(section.children).length > metricsLimit}
                                <div class="metric expands" on:click={expand}>
                                    {Object.keys(section.children).length - metricsLimit}+ Metrics
                                </div>
                            {/if}
                        </div>
                        {#each Object.entries(section.children) as [name, data], i}
                            {#if data.type === 'complex'}
                                {setComplex(category) && ''}
                                <div class="metric-category">
                                    <Tooltip top>
                                        <Icon size="16px" path={metricIcons[name]} />
                                        {prettyName(name)}
                                        <span slot="tip" class="capitalize">{data.info}.</span>
                                    </Tooltip>
                                    {#if !expandedMetrics && Object.keys(data.children).length > metricsLimit}
                                        <div class="metric expands" on:click={expand}>
                                            {Object.keys(data.children).length - metricsLimit}+ Metrics
                                        </div>
                                    {/if}
                                </div>
                                {#each Object.entries(data.children) as [nestedName, nestedData], j}
                                    {#if expandedMetrics || j < metricsLimit}
                                        <div class="metric">
                                            <Tooltip top>
                                                <!-- <Icon size="18px" path={icons[data.type]} /> -->
                                                {prettyName(nestedName, 30)}
                                                <span slot="tip">{nestedData.info}{nestedData.unit ? ' (' + nestedData.unit + ')' : ''}.</span>
                                            </Tooltip>
                                        </div>
                                    {/if}
                                {/each}
                            {:else if expandedMetrics || i < metricsLimit}
                                <div class="metric">
                                    <Tooltip top>
                                        <!-- <Icon size="18px" path={icons[data.type]} /> -->
                                        {prettyName(name, 30)}
                                        <span slot="tip">{data.info}{data.unit ? ' (' + data.unit + ')' : ''}.</span>
                                    </Tooltip>
                                </div>
                            {/if}
                        {/each}
                    {/each}
                {/await}
            </div>
        </CardText>
    </Card>
</div>
</GreenWave>
<div class="cta">
    <Pricing />
    <Button class="primary-color signup-button" on:click={() => navigate('/signup')}>
        Take Me to the Sign Up Page
    </Button>
</div>
<GreenWave bottomWave={false}>
    <div class="landing-footer">
        <Row>
            <Col cols={12} md={3}>
                <h2>Products</h2>
                <div class="footer-links">
                    <a href="/products">Property Reports</a>
                    <a href="/map">Region Analysis</a>
                    <a href="/education">Courses</a>
                    <!-- <a href="/books">Books</a> -->
                </div>
            </Col>
            <Col cols={12} md={3}>
                <h2>Services</h2>
                <div class="footer-links">
                    <a href="/education/api">API</a>
                    <a href="/education/pm">Property Management</a>
                    <a href="/invest">Invest With Us</a>
                    <!-- <a href="/coaching">Coaching</a> -->
                </div>
            </Col>
            <Col cols={12} md={3}>
                <h2>Company</h2>
                <div class="footer-links">
                    <a href="/about">About Us</a>
                    <a href="/partner">Partnerships</a>
                    <a href="/support">Support</a>
                    <a href="/terms">Legal</a>
                </div>
            </Col>
            <Col cols={12} md={3}>
                <img class="footer-logo" src="/static/logo_long.png" alt="Investomation logo" />
                <p class="footer-about">
                    Investomation is a real estate analytics solution.
                    We help you find the best real estate markets, analyze neighborhoods, and manage your rental portfolio.
                </p>
                <div class="social-media">
                    <a href="https://www.facebook.com/investomation" target="_blank">
                        <Icon size="24px" path={mdiFacebook} />
                    </a>
                    <a href="https://www.youtube.com/investomation" target="_blank">
                        <Icon size="24px" path={mdiYoutube} />
                    </a>
                    <a href="https://www.linkedin.com/company/investomation" target="_blank">
                        <Icon size="24px" path={mdiLinkedin} />
                    </a>
                    <a href="https://www.twitter.com/investomation" target="_blank">
                        <Icon size="24px" path={mdiTwitter} />
                    </a>
                </div>
            </Col>
        </Row>
    </div>
</GreenWave>

<style type="text/scss">
    @import 'material-theme';

    :global(body) {
        padding-top: 60px;

        // h2 {
        //     font-size: 2rem !important;
        // }
    }

    :global(header) {
        position: fixed;
        background: rgba(255,255,255,0.7);
        width: 100%;
        z-index: 2;
        top: 0;
    }

    .slide-text-container {
        position: absolute;
        width: 40%;
        min-width: min(400px, 100%);
        // top: 15%;
        // height: 50%;
        display: table;
        z-index: 1;

        #title {
            text-transform: uppercase;
            font-size: 2.5rem !important;
            font-weight: 700;
        }

        #subtitle {
            font-size: 1.6rem !important;
            color: #666;
            font-weight: 400;
            line-height: 1.3;
            margin-top: 20px;
            margin-bottom: 35px;
        }

        .slide-controls, .slide-text {
            display: table-cell;
        }

        .slide-text {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    .call-to-action {
        position: absolute;
        padding-top: 20px;
        bottom: -40px;
        left: 0px;
        min-width: min(400px, 100%);

        :global(button) {
            padding: 0 20px !important;
            margin: 20px;
            margin-left: 0px;
            width: 200px;
        }
    }

    .cta {
        :global(.signup-button) {
            margin: 20px auto;
            display: block;
        }
    }

    @media only screen and (min-width: 701px) and (max-width: 1120px) {
        .slide-container {
            .laptop {
                padding: 4%;
            }
        }

        .call-to-action {
            :global(button) {
                width: 180px;
            }
        }
    }

    @media only screen and (max-width: 700px) {
        .slide-container {
            .slide-text-container {
                top: unset;
                padding: 10px;
                text-align: center;

                #title {
                    font-size: 1.4rem !important;
                    line-height: 2rem !important;
                }

                #subtitle {
                    font-size: 1.2rem !important;
                    line-height: 1.2rem !important;
                }
            }

            .laptop {
                // bottom: 200px;
                padding: 10px;
            }
        }

        .slide-icon {
            display: none;
        }

        .slide-controls {
            display: table-footer-group !important;

            :global(.slide-bullet) {
                display: inline-block;
                margin: 10px 5px !important;
            }
        }

        .call-to-action {
            bottom: -310px;
            padding: 10px;

            :global(button) {
                width: unset;
            }

            :global(.demo-button) {
                display: none;
            }

            :global(.signup-button) {
                width: 90%;
                margin: 5%;
            }
        }

        .video-container {
            .video {
                left: 0px !important;
                top: 30% !important;
                transform: none !important;
                width: 100%;

                iframe {
                    width: 100%;
                }
            }
        }

        .sources {
            height: 200px;
            padding: 20px;

            h2 {
                line-height: 1.8rem !important;
            }

            .source-logo {
                display: inline;

                img {
                    width: 90px !important;
                    padding: 0 10px !important;
                }
            }
        }

        .available-metrics {
            margin-top: 100px !important;

            .metric-cols {
                column-count: 1 !important;
                text-align: center;
            }
        }
        
        .cta {
            :global(h2) {
                line-height: 1.8rem;
            }
        }
    }

    @media only screen and (min-width: 700px) {
        .slide-container {
            margin-top: 10vw !important;

            .slide-text-container {
                height: 50%;
            }

            .slide-controls, .slide-text {
                vertical-align: middle;
            }
        }

        .video-container {
            .video {
                transform: translateX(-50%) scaleY(100%);

                &:global(.minimize) {
                    transform: translateX(-50%) scaleY(0%);
                }
            }
        }
    }

    :global(.capitalize) {
        text-transform: capitalize;
    }

    .slide-bullet {
        background: #ddd;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        margin: 20px;
        cursor: pointer;
    }

    :global(.slide-bullet.active) {
        background: $primary-color !important;
    }

    .slide-container {
        height: 500px;
        max-width: 1100px;
        position: relative;
        margin: auto;
        transition: visibility 1000ms ease-in, transform 1000ms ease-in;
    }

    .slide-text {
        :global(.signup-button) {
            padding: 0 48px !important;
        }
    }

    .laptop {
        position: absolute;
        right: 0px;
        max-width: 100%;
        max-height: 90%;
        bottom: 120px;
    }

    .scroll-for-more {
        width: 150px;
        display: block;
        position: absolute;
        bottom: 0px;
        left: calc(50% - 75px);
        animation: shake 2s;
        animation-iteration-count: infinite;
        animation-delay: 1s;
        opacity: 0;

        img {
            width: 150px;
            cursor: pointer;
        }
    }

    @keyframes shake {
        0% {
            transform: translateY(0px);
            opacity: 0;
        }
        50% {
            transform: translateY(20px);
            opacity: 1;
        }
        100% {
            transform: translateY(20px);
            opacity: 0;
        }
    }

    .video-container {
        // margin-top: 50px;
        width: 100%;
        height: 200px;
        // background-image: url("/static/real-estate-background-1.jpeg");
        // background-size: cover;
        // transition: opacity 1000ms ease-in-out;
        // transition: visibility 1000ms ease-in;
        // visibility: hidden;
        opacity: 0;

        &.show {
            // visibility: visible;
            opacity: 1;
        }

        .video {
            position: fixed;
            top: 25%;
            left: 50%;
            z-index: 10;
            transition: transform 1000ms ease-in-out;
            transform: scaleY(100%) translateX(-50%);
            // transform: translateX(-50%) scaleY(100%);
            padding: 5px 10px;
            // background: white;

            &:global(.minimize) {
                transform: scaleY(0%) translateX(-50%);
                pointer-events: none;
            }
        }
    }

    #newsletter {
        padding: 20px;
    }

    .sources {
        height: 200px;
        padding: 20px;
        max-width: 1200px;
        margin: auto;

        h2 {
            text-align: center;
            margin: 2rem 0;
            line-height: 1rem;
        }

        .source-logo-container {
            text-align: center;
        }

        .source-logo {
            display: inline;

            img {
                width: min(13vw, 190px);
                vertical-align: middle;
                padding: 0 20px;
            }

            div {
                font-size: 36px;
                max-width: min(15vw, 190px);
                width: 200px;
                font-family: Lato;
                vertical-align: middle;
                padding: 0 20px;
            }
        }
    }

    .available-metrics {
        // width: 80%;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 10px;
        width: 90%;
        max-width: 1200px;
        transition: visibility 1000ms ease-in, transform 1000ms ease-in;

        :global(.metrics-card) {
            background-color: rgba(255, 255, 255, 0.5);
        }

        :global(i) {
            vertical-align: text-top; /* aligns icons to text */
        }

        :global(.metrics-card::before) {
            background-position-x: center;
            background-position-y: center;
            background-size: 100%;
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.25;
            z-index: -1;
            // background-image: url(https://images.unsplash.com/photo-1495592822108-9e6261896da8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80);
        }

        .metric-info {
            margin-top: -20px;
            font-size: medium;
            padding-bottom: 20px;
        }
        
        .metric-cols {
            column-count: 4;
            column-gap: 20px;

            .metric-category {
                text-transform: capitalize;
                // color: black;
                padding-top: 20px;
                font-size: 16px;
                font-weight: 600;
                // color: rgb(76, 175, 80);
            }

            .metric.expands {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }

    :global(.hide) {
        visibility: hidden;
        // transform: translateY(100%);
    }

    :global(.scroll-away-up) {
        visibility: hidden;
        transform: translateY(-100%);
    }

    .landing-footer {
        // height: 300px;
        margin: 0 50px;

        a {
            color: #333;
            text-decoration: none;
            display: block;
            font-weight: 700;

            &:hover {
                color: white;
            }
        }

        .footer-logo {
            width: 200px;
        }

        .footer-about {
            font-size: 11pt;
        }

        .social-media a {
            display: inline;

            :global(.s-icon>svg:hover) {
                fill: white;
            }
        }
    }
</style>