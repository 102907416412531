<svelte:head>
	<script src="/static/marked.js" on:load={onMarkedLoad}></script>
</svelte:head>

<script>
    import { navigate, Link } from '../components/routing'
    import Aggregator from '../components/Aggregator.svelte'
    import { externalPromise, apiFetch } from '../../utils'
    import {
        Chip,
        Card,
        CardTitle,
        CardSubtitle,
        CardText,
        Divider,
        CardActions,
        Button,
    } from 'svelte-materialify/src';
    import Icon from 'svelte-materialify/src/components/Icon';
    import { mdiCalendar, mdiClose, mdiChevronLeft } from '@mdi/js';
    import Textarea from 'svelte-materialify/src/components/Textarea';

    let tags
    apiFetch(`/api/product_category`).then(r => r.json()).then(list => {
        tags = list.items
    })
    export let selectedItem;
    export let selectedTag;
    $: selectedTag
    let commentText;

    // async function getComments(id) {
    //     if (!id) return [];

    //     return fetch(`/api/blog/${id}/comment`).then((r) => r.json()).then(list => list.items)
    // }
    // $: blogComments = getComments(selectedItem)

    const toDate = (d) => new Date(d).toLocaleDateString(undefined, { timeZone: 'UTC' })

    let markedLoaded = externalPromise()
    function onMarkedLoad() {
        markedLoaded.resolve()
    }
</script>

<Aggregator
    name="Order Report"
    relatedName="Related Products"
    endpoint="{selectedTag ? `/api/product_category/${selectedTag}` : '/api/product'}"
    singleEndpoint="/api/product/:id"
    arrayName="{selectedTag ? 'product' : 'items'}"
    selectedItem={selectedItem}
>
    <div slot="filter" class="filter">
        {#if tags}
            <span class="filter-name">Tags</span>
            {#each tags as tag}
                <Chip class="{selectedTag === tag.id ? 'primary-color' : ''}" on:click={() => {
                    navigate(`/products/category/${tag.id}`)
                }}>{tag.name}</Chip>
            {/each}
            {#if selectedTag}
                <Button icon  on:click={() => {
                    navigate('/products')
                }}>
                    <Icon path={mdiClose} />
                </Button>
            {/if}
        {/if}
    </div>
    <div let:prop={item} slot="single-item" class="single-item">
        <Link to="/products/{item.id}">
            <Card flat tile>
                <div class="preview-image">
                    <img src="{item.media}" alt="{item.media}" />
                </div>
                <CardTitle class="product-title">{item.title}</CardTitle>
                <CardText>{item.summary}</CardText>
                <Divider />
                <CardActions>
                    {#if selectedTag}
                        <Button text>#{tags.find((t) => selectedTag === t.id).name}</Button>
                    {:else}
                        {#each item.product_category as tag}
                            <Button text on:click={(e) => {
                                navigate(`/products/category/${tag.id}`)
                            }}>#{tag.name}</Button>
                        {/each}
                    {/if}
                </CardActions>
            </Card>
        </Link>
    </div>

    <div slot="opened-item" class="opened-item" let:prop={postInfo}>
        <div class="product-content">
            <Link class="blog_back" to="/products"><Icon path={mdiChevronLeft} /> back to products page</Link>
            <div class="product-container">
                <div class="product-order">
                    <a href="{postInfo.example_url}">
                        <img src="{postInfo.media}" alt="{postInfo.media}" />
                        <Button class="product-sample-button" text on:click={() => {
                            window.open(postInfo.example_url, '_blank')
                        }}>{postInfo.example_text}</Button>
                    </a>
                    <!-- <img src="{postInfo.media}" alt="{postInfo.media}" /> -->
                    <div class="order-form">
                        <div class="price">${postInfo.price / 100}</div>
                        <Button id="purchase-btn">
                            Order Report
                        </Button>
                    </div>
                </div>
                <div class="product-description">
                    {#await markedLoaded}
                        Loading...
                    {:then}
                        {@html globalThis.marked(postInfo.content)}
                    {/await}
                </div>
            </div>
        </div>

        <Divider />
        {#each postInfo.product_category as tag}
            <Button class="blog-tag" text on:click={() => {
                navigate(`/products/category/${tag.id}`)
            }}>#{tag.name}</Button>
        {/each}
    </div>
</Aggregator>

<style type="text/scss">
    @import 'material-theme';

    .youtube {
        text-decoration: none;
        color: #4caf50;
        padding-left: 30px;
        padding-top: 20px;
        margin-bottom: -10px;
        display: block;
    }

    .filter {
        padding: 16px;

        :global(.filter-name) {
            padding-right: 24px;
            color: #999;
        }

        :global(.s-chip) {
            margin: 5px;
            text-transform: capitalize;
            cursor: pointer;

            &:hover {
                background: $primary-color;
            }
        }
    }

    .single-item {
        width: 300px;
        height: 450px;
        float: left;

        & :global(.product-title) {
            margin-top: 5px;
            margin-bottom: -5px;
        }

        & :global(.teaser) {
            filter: blur(2px);
        }

        & :global(.author) {
            font-weight: 700;
            &:hover {
                color: $primary-color;
            }
        }

        & :global(.s-card-subtitle) {
            padding: 0 16px;
            font-size: 0.7rem;
        }

        & :global(.s-card-title) {
            text-transform: uppercase;
            font-size: 1rem;
            padding: 0 16px;
            font-weight: 700;
            word-break: break-word;
            line-height: 1;
        }

        & :global(.s-card-text) {
            height: 150px;
            overflow: hidden;
            font-size: 0.8rem;
        }

        & :global(.s-btn) {
            font-size: 0.7rem;

            & :global(span) {
                font-weight: 700;
                text-transform: capitalize !important;
            }

            &:before {
                display: none;
            }
            &:hover {
                color: $primary-color;
                background: transparent;
            }
        }

        & :global(.s-divider) {
            margin: 0 16px;
            margin-top: 8px;
        }
    }
    .preview-image {
        margin: 16px;
        margin-bottom: 0;
        cursor: pointer;
        border: 1px solid $secondary-bg-color; /* subtle border for images w/ white bg */
        height: 170px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .opened-item {
        float: left;
        width: 70%;
        max-width: 800px;
        margin: 16px 64px;

        :global(.blog_back) {
            text-decoration: none;
            color: #777;
            text-transform: uppercase;
        }

        :global(.product-content) {
            :global(.product-container) {
                display: flex;

                :global(h1) {
                    text-transform: uppercase;
                    padding-bottom: 16px;
                }

                :global(.product-order) {
                    min-width: 300px;
                    padding: 20px 10px;

                    :global(a) {
                        text-decoration: none;
                        color: black;

                        :global(.product-sample-button) {
                            width: 100%;
                        }
                    }

                    :global(.order-form) {
                        display: flex;
                        padding: 10px;

                        :global(.price) {
                            padding: 0 20px;
                            font-weight: 700;
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }

        :global(.blog-tag) {
            font-size: 0.7rem;

            & :global(span) {
                font-weight: 700;
                text-transform: capitalize !important;
            }

            &:before {
                display: none;
            }
            &:hover {
                color: $primary-color;
                background: transparent;
            }
        }

        :global(.blog-post-timestamp) {
            float: right;
            padding: 5px;
        }

        :global(.blog-author) {
            background-color: $secondary-bg-color;
            border-radius: 10px;
            display: flex;
            margin: 20px 0;

            :global(a) {
                text-decoration: none;
                color: black;
            }

            :global(.blog-author-image) {
                @include profile-img;
                margin: 20px;
                height: 120px;
            }

            :global(.blog-author-bio) {
                width: calc(100% - 120px);
                padding: 20px;

                :global(h4) {
                    font-family: $body-font-family;
                    font-size: 1.15rem;
                    font-weight: 700;
                }

                :global(p) {
                    font-style: italic;
                    font-size: 0.9rem;
                }
            }
        }

        :global(.comments-title) {
            text-transform: uppercase;
        }

        :global(.blog-comment) {
            :global(.blog-comment-poster-image) {
                @include profile-img;
                vertical-align: top;
                margin: 10px;
                margin-top: 30px;
                margin-left: 0;
            }

            :global(.blog-comment-container) {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 130px);
                padding: 5px;

                :global(.blog-comment-poster) {
                    :global(a) {
                        text-decoration: none;
                    }

                    :global(h4) {
                        font-size: 1rem;
                        line-height: 2rem;
                        font-weight: 700;
                        display: inline;
                        color: black;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    :global(span) {
                        font-size: 0.8rem;
                    }
                }

                :global(.blog-comment-text) {
                    font-size: 0.9rem;
                    white-space: break-spaces;
                }
            }

        }

        :global(.comment-field) {
            margin-top: 20px;
        }

        :global(.submit-button) {
            margin-top: 16px;
            margin-bottom: 100px;
        }
    }
</style>