<script>
    import { TextField, Button } from 'svelte-materialify/src';
    
    let name = globalThis.user ? (globalThis.user.nickname || globalThis.user.username) : '';
    let email = globalThis.user ? globalThis.user.email : '';
    let subscribed = false;
    
    const subscribe = () => {
        fetch('/api/mailing_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, email, survey: 'newsletter' })
        })
        subscribed = true;
        console.log(`Subscribed with email: ${email}`);
    };
</script>

<main>
    {#if subscribed}
        <h1>Thank you for subscribing!</h1>
        <p>You will receive our newsletter shortly.</p>
    {:else}
        <h1>Subscribe to our Newsletter</h1>
        <p>
            Our newsletter provides a unique perspective on real estate investing, particularly through incorporation of technology into the process.
            Real estate has traditionally been a field mostly untouched by technology, but we're working to change that for ourselves and our readers.
            Our team provides insights and market analytics on the latest trends and real estate developments. We also explore task automation through
            the use of generative AI and virtual assistants, freeing up time and resources for investors. 
        </p>
        <p>Stay up-to-date with our latest news and updates by subscribing to our newsletter.</p>
    
        <form on:submit|preventDefault={subscribe}>
            <TextField label="Name" bind:value={name} >Name</TextField>
            <TextField label="Email" type="email" bind:value={email} >E-Mail</TextField>
            <Button type="submit" raised>Subscribe</Button>
        </form>
    {/if}
</main>

<style>
    h1 {
        text-transform: uppercase;
    }
    
    main {
        width: 600px;
        margin: auto;
        clear: both;
        padding-top: 50px;
    }
</style>